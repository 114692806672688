
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "melbournelight";
  src: url("../fonts/melbourne_light-webfont.woff2") format("woff2"),
    url("../fonts/melbourne_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "melbourneregular";
  src: url("../fonts/melbourne_reg-webfont.woff2") format("woff2"),
    url("../fonts/melbourne_reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("../fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gotham-Bold.otf") format("opentype"),
    url("../fonts/Gotham-Bold.woff") format("woff"),
    url("../fonts/Gotham-Bold.ttf") format("truetype"),
    url("../fonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  -webkit-text-size-adjust: none;
}

:focus {
  outline: 0;
}

ol,
ul {
  margin: 0;
  padding: 0px 0px 10px 40px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

embed,
iframe,
object {
  max-width: 100%;
}

body {
  color: #000;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background: #f7f6e9;
}

cite,
em,
i {
  font-style: italic;
}

input,
textarea {
  -webkit-border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
  padding: 12px 25px;
  border: 1px solid #e4e4e4;
  width: 100%;
  margin: 0 0 10px;
  background-color: #fff;
  border-radius: 0px;
  height: 48px;
  font-size: 15px;
  color: #555555;
  font-family: "Poppins", sans-serif;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
  color: #fff;
  display: inline-block;
  border-radius: 0px;
  margin: 15px 0px 10px 0px;
  padding: 12px 24px 12px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  background: #FF1B2C;
  border: 0px;
  font-size: 18px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: capitalize;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  /*    font-family: 'Poppins';*/
  font-family: "Poppins", sans-serif;
  -webkit-box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
  box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover {
  color: #fff;
  background: #000;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
  color: #FF1B2C;
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:active,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #000;
}

figure {
  margin: 0;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  font-weight: normal;
  line-height: 1.1;
  color: #000;
  /*    font-family: 'Poppins';*/
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.rel {
  position: relative;
}

.textcenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.clear,
.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  display: table;
  content: "";
}

strong,
b {
  font-weight: 700;
}

.required_star {
  color: #f00;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.container-full {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.product-menu-listing .container-full {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 1920px;
}
.product-menu-listing .container-full .product-filter {
  position: relative;
  height: 100%;
  display: inline-block;
  flex: 1 1 20%;
  min-width: 100px;
  max-width: 50%;
  padding: 15px;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-search
  .form-group {
  position: relative;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-search
  img {
  position: absolute;
  right: 10px;
  top: 15px;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-search
  .from-control {
  color: #000000;
  font-size: 14px;
  margin-bottom: 11px;
}
.product-menu-listing
  .container-full
  .product-filter.product-filter-sec
  .product-menu
  .menu-list-intvl
  a {
  text-align: left;
  padding: 10px 20px;
}
.product-menu-listing .container-full .product-filter .product-filter-select {
  position: relative;
  text-align: center;
  background: #fff;
  box-shadow: 1px 1px 11px #c9c9c991;
  margin-bottom: 20px;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group.rev-margin {
  padding: 0;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  .form-group-catg {
  width: 100%;
  position: relative;
  text-align: left;
  padding-left: 20px;
  overflow: hidden;
  z-index: 1;
}
.product-menu-listing .container-full .product-filter .product-filter-select .form-group .form-group-catg span {
  color: #464344;
  background: #fff;
  padding-right: 10px; margin: 0; z-index: 1;}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  .form-group-catg::after {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  top: -10px;
  background: #d4d0d4;
  z-index: -1;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #eaeae9;
}
.product-menu-listing
  .container-full
  .product-filter.product-filter-sec
  .product-filter-select
  .form-group {
  border-bottom: inherit;
}
.product-menu-listing .container-full .product-filter.product-fixed-header {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  height: 100%;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group:last-child {
  border-bottom: none;
}
.product-menu-listing  .container-full  .product-filter  .product-filter-select  .form-group  label {  color: #464344; font-weight: bold;  margin-bottom: 10px; display: block; text-align: left;}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/right-arrow.png") no-repeat right;
  background-position: 95% 20px;
  margin-bottom: 5px;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  select option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    background: #fff;
    line-height: 20px;
    padding: 20px 10px;
  }
  .product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  select option:checked  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;
    background: #000;
    line-height: 20px;
    padding: 20px 10px;
  }
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  select:focus option:hover  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;
    background: #000;
}
.product-menu-listing
  .container-full
  .product-filter
  .product-filter-select
  .form-group
  select option:focus  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;
    background: #000;
}
.profil-select-tag {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.profil-select-tag .product-inn-tag {
  display: table;
  border: 1px solid #000;
  padding: 2px 6px;
  margin: 2px 2px 2px 0;
}
.profil-select-tag .product-inn-tag span {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
  text-transform: lowercase;
}
.product-menu-listing .container-full .productlist-main-div {
  display: inline-block;
  flex: 1 1 60%;
  min-width: 200px;
  max-width: 80%;
  padding: 57px 10px;
}
.product-menu-listing
  .container-full
  .productlist-main-div.productlist-main-detail {
  padding: 18px 10px;
}
.category-product-list h3 {
  margin-bottom: 7px;
  margin-left: 15px;
  font-size: 25px;
}
.innerproduct h5 {
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: inherit;
}
.product-menu-listing .container-full .product-cart {
  flex: 1 1 20%;
  min-width: 100px;
  max-width: 50%;
  padding: 15px;
}
.product-menu-listing .container-full .product-cart .prodsel-cart, .product-cart-side {
  display: flex;
  flex-flow: row;
  margin: inherit;
  padding: 0;
}
.product-filter-selecct {
  position: relative;
  display: none;
}
.product-filter-selecct .product-cart-side {
  display: flex;
  flex-flow: row;
  margin: 0 0 15px;
  padding: 0;
}
.product-menu-listing .container-full .product-cart .btn-procart, .product-cart-side .btn-procart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 12px;
  background-color: #ffffff;
  color: #bfbfbf;
  text-transform: capitalize;
  box-shadow: 1px 3px 2px #0000001f;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.address p {
  font-family: "Poppins", sans-serif;
  font-style: italic;
}
.working-hr > strong {
  font-weight: inherit;
}
.product-menu-listing .container-full .product-cart .btn-procart:hover,
.product-menu-listing .container-full .product-cart .btn-procart.active,
.product-cart-side .btn-procart:hover, .product-cart-side .btn-procart.active {
  color: #ffffff;
  background-color: #000000;
}

.hmenu-login-act .cart_qty_list .product-details .row-replace .cart_right {
  align-items: flex-end;
}
.hmenu-login-act .cart_qty_list .outlet-gst span {
}
.hmenu-login-act .cart_qty_list .hcart_dropdown {
  position: fixed;
  max-width: 600px;
}
.hmenu-login-act .cart_qty_list .hcart_maiin_scrollarea {
  position: relative;
  height: 100%;
}
.hmenu-login-act .cart_qty_list .hcart_scrollarea {
  height: 100%;
  overflow-y: scroll;
}
.hmenu-login-act .cart_qty_list .hcart_maiin_scrollarea .outlet-gst {
  padding-bottom: 7px;
  border-bottom: 1px solid #ececec;
}
.hmenu-login-act .syd_merge .form-group222 .react-datepicker-wrapper {
  width: 100%;
}
.hmenu-login-act .syd_merge .form-group222 i {
  position: relative;
  right: -45%;
  top: -35px;
}
.hmenu-login-act .cart_qty_list .hcart_scrollarea .cart_table {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.hmenu-login-act .cart_qty_list .hcart_scrollarea .cart_body {
  padding: 20px 12px 100px;
}
.hmenu-login-act .cart_qty_list .hcart_scrollarea .cart_footer {
  position: relative;
  padding: 10px 0px 25px 0px;
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}
.hmenu-login-act .cart_qty_list .hcart_scrollarea .cart_footer .cart_footer_inner_item {
  width: 100%;
}
.hmenu-login-act .cart_qty_list .product-cart-side {
  display: flex;
  flex-flow: row;
  margin: inherit;
  padding: 0;
}
.hmenu-login-act .cart_qty_list .product-cart-side .btn-procart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 12px;
  background-color: #ffffff;
  color: #bfbfbf;
  text-transform: capitalize;
  box-shadow: 1px 3px 2px #7a7a7a1c;
  margin: 0px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.hmenu-login-act
  .cart_qty_list
  .product-cart-side
  .btn-procart.pro-cart-bike::before {
  display: block;
  content: "";
  background: url("../images/food-delivery-icon.png") no-repeat;
  width: 30px;
  height: 30px;
  -webkit-filter: grayscale(1) invert(0.4);
  filter: grayscale(1) invert(0.4);
}
.hmenu-login-act
  .cart_qty_list
  .product-cart-side
  .btn-procart.pro-cart-bag::before {
  display: block;
  content: "";
  background: url("../images/gray-bag-icon.png") no-repeat;
  width: 30px;
  height: 30px;
}
.hmenu-login-act .cart_qty_list .product-cart-side .btn-procart:hover,
.cart_qty_list .product-cart-side .btn-procart.active {
  color: #ffffff;
  background-color: #000000;
}
.hmenu-login-act .cart_qty_list .cart_action {
  padding: 0;
  position: fixed;
  margin-top: 0;
  width: 100%;
  background: #fff;
  bottom: 0;
}
.product-cart-item-list .product-cart-side {
  display: none;
}
.product-menu-listing .container-full .product-cart .product-cart-item-list {
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 400px;
  height: auto;
  box-shadow: 1px 1px 5px #0000001f;
}
.product-menu-listing
  .container-full
  .product-cart
  .product-cart-item-list
  img {
  margin-bottom: 5px;
}
.product-menu-listing
  .container-full
  .product-cart
  .product-cart-item-list
  .img-empty {
    text-align: center;
    display: block;
    margin: auto;
}
.product-menu-listing
  .container-full
  .product-cart
  .product-cart-item-list
  .h4-empty {
  color: #ececec;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
}
.product-menu-listing
  .container-full
  .product-cart
  .product-cart-item-list
  .hcart_dropdown.open
  .product-cart {
  display: none;
}

.slick-slider,
.slick-slider * {
  outline: none;
}

p {
  margin: 0 0 15px;
}

.title_sec {
  margin: 0 0 45px;
  color: #040507;
}

.title_sec h2 {
  font-size: 44px;
  line-height: 1;
  margin: 0 0 5px;
}

.title_sec small {
  font-size: 16px;
  display: block;
  font-weight: normal;
  margin: 0 0 13px;
}

.title1 {
  font-size: 32px;
  color: #040507;
  line-height: 1;
  margin: 0 0 24px;
}

.title3 {
  font-size: 20px;
  color: #040507;
  line-height: 1;
}

.title4 {
  font-size: 18px;
  color: #010101;
}

.icon-cap {
  background: url(../images/icon-cap.png) no-repeat;
  width: 34px;
  height: 31px;
  display: block;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: none;
  color: #fff;
}

.btn {
  color: #fff;
  border-radius: 0;
  padding: 12px 22px;
  height: 50px;
  font-size: 18px;
  font-weight: normal;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  text-transform: uppercase;
  margin-bottom: 4px;
  position: relative;
}

.btn:hover {
  color: #fff;
}

.btn_minwid {
  min-width: 170px;
  padding-left: 25px;
  padding-right: 25px;
}

.btn-sm {
  height: 42px;
  padding: 8px 15px;
  font-size: 16px;
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table_overflow {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
}

.table_overflow .table {
  margin-bottom: 0;
}

.dropdown-menu {
}

.dropdown-menu > li > a {
  padding: 7px 20px;
  border-top: 1px solid #e9ebf5;
}

.dropdown-menu > li:first-child > a {
  border-top: 0;
}

.form_grouptt {
  font-size: 20px;
  margin: 0 0 20px 18px;
  color: #000;
}

.form-group {
  margin: 0 0 8px;
}

label {
  font-weight: 500;
  font-size: 15px;
  color: #615f5f;
}

label span {
  color: #f00;
  margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
  content: "*";
  color: red;
}

.form-control {
  height: 48px;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding: 6px 18px;
  background: #fff;
  font-size: 15px;
  border-radius: 0;
  color: #615f5f;
  width: 100%;
  display: block;
}
select.form-control {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}
select.form-control:focus {
  position: relative;
  width: 100%;
}
.product-filter-select select.form-control:checked{
    background: #000;
    color: #fff;
}
textarea.form-control {
  height: 160px;
}

textarea.form-control.sm {
  height: 90px;
}

.form-control.error,
.Select-control.error {
  border-color: #ffbaba;
  color: #686a78;
}

.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #000000;
}

.focus-out {
  position: relative;
}

.focus-out label {
  position: absolute;
  left: 18px;
  top: 14px;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 3;
  height: 24px;
  overflow: hidden;
  color: #595656;
}

.focused label {
  padding: 0 6px;
  font-size: 11px;
  background: #fff;
  top: -7px;
  height: auto;
}

.clock_icon,
.ileft_ico {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
}

.clock_icon img,
.ileft_ico img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
}

.profile-info-div {
  margin: 25px 0;
}
.acc-inform .form-group .row .col-md-5,
.acc-inform .form-group .row .col-md-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.form-group .row .col-md-1 .txt-align {
  text-align: center;
  margin: 0;
  position: relative;
  padding-top: 50%;
}
.other-add {
  margin: 30px 0 0 0;
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
  display: table;
  clear: both;
  content: "";
}

.acc-inform .form-group .row .col-md-6 {
  width: 49%;
  float: left;
  margin: 0;
  padding-right: 10px;
  padding-left: 10px;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
  margin-right: 0;
}

.re_select input[type="text"] {
  margin: 0;
  height: 34px;
}

.acc-inform .form-group .row .button {
  width: 100%;
  display: block;
  margin: 0;
  height: 48px;
}

.re_select .Select-control {
  border: 1px solid #e2e2e2;
  border-radius: 0;
  height: 47px;
  outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
  padding: 0 22px;
  line-height: 45px;
  text-align: left;
  color: #72624f;
}

.re_select .Select-input {
  padding: 0 20px;
  height: 45px;
}

.re_select .Select-input > input {
  line-height: 27px;
  padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
  border-color: #d5d7e0;
}

.re_select .Select-arrow {
  background: url(../images/arrow-down.png) no-repeat center;
  border: 0;
  width: 18px;
  height: 10px;
}

.re_select .is-open .Select-arrow {
  background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
  z-index: 4;
  text-align: left;
}

.re_select .Select-menu-outer {
  background: #ecdfcc;
}

.re_select .Select-arrow-zone {
  padding: 0;
  width: 42px;
}

.re_select .Select--multi .Select-value {
  background-color: #ecdfcc;
  border-color: #dcd8d8;
  color: #181818;
  position: relative;
  padding-right: 19px;
  border-radius: 10px;
  overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
  border-color: #dcd8d8;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
  font-size: 0;
}

.re_select .Select--multi .Select-value-label {
  padding: 2px 5px 2px 9px;
}

.custom_select {
  position: relative;
}

.custom_select:after {
  content: "";
  background: #fff url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  pointer-events: none;
  height: 46px;
}

.react-datepicker {
  font-family: "Calibri" !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
  display: block;
}

.react_datepicker .react-datepicker {
  font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
  font-size: 1.2rem;
  font-weight: bold;
  
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-family: 'Poppins';
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled{
  font-family: 'Poppins';
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  font-family: 'Poppins';
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
  width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
  width: 120px;
}

.react_datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
}

.react_datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #FF1B2C !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #FF1B2C !important;
  color: #fff !important;
}

.react-datepicker__header {
  background-color: #eaeaea !important;
}

.react-datepicker__navigation {
  margin: 0;
  box-shadow: none;
}

.react-datepicker__navigation--previous {
  border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
  border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  background: none;
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
  height: 36px;
  padding: 5px 10px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 50% !important;
  background-color: #FF1B2C !important;
  color: #fff !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #FF1B2C !important;
}

.custom_checkbox {
  position: relative;
  display: inline-block;
  line-height: 25px;
}

.custom_checkbox input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.custom_checkbox input[type="checkbox"]:checked + span:before {
  content: "";
  text-rendering: auto;
  text-align: center;
  border-color: transparent;
  background: url(../images/tick-new.png) no-repeat;
}

.custom_checkbox .disableactive + span:before {
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #FF1B2C;
  text-align: center;
  text-align: center;
  border-color: #FF1B2C;
}

.custom_checkbox span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 2px;
  pointer-events: none;
  border: 1px solid #c2c2c2;
}

.custom_checkbox span {
  position: relative;
  padding: 0 0 0 30px;
  pointer-events: none;
  color: #3d3d3d;
  display: block;
}

.custom_radio {
  position: relative;
  display: inline-block;
}

.custom_radio input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.custom_radio input[type="radio"]:checked + span:before {
  background: url(../images/spirite_whole.png) no-repeat;
  background-position: -399px -125px;
  z-index: 1;
  border: 1px solid transparent;
}

.custom_radio span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid #d0cfcb;
  left: 0;
  top: 1px;
  pointer-events: none;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.custom_radio span {
  position: relative;
  padding: 0 0 0 35px;
  pointer-events: none;
  color: #202963;
  display: block;
}

.ckbox_custmze {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ckbox_custmze input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -12px;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
  background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
  background-color: #72624f;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
  display: block;
}

.ckbox_custmze .checkmark:after {
  left: 10px;
  top: 5px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error {
  color: #f00;
  font-weight: normal;
}

span.error {
  background: #ffbaba;
  color: #d8000c;
  padding: 5px 25px;
  font-size: 13px;
  position: relative;
  display: inline-block;
  font-weight: normal;
  z-index: 4;
}

span.error:before {
  content: "";
  border-bottom: 5px solid #ffbaba;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}

.alert {
  padding: 10px 15px;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  display: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.alert_fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  max-width: 1260px;
  margin: 0 auto;
  padding: 10px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.alert_success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert_danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert_fixed p {
  margin: 0;
}

.alert_fixed a {
  font-size: 24px;
  left: 15px;
  top: 50% !important;
  position: absolute !important;
  transform: translateY(-50%);
}

.display_inblock {
  display: inline-block;
}

.display_block {
  display: block;
}

.relative {
  position: relative;
}

.vtop {
  vertical-align: top;
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vmid_sec:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.vmid_in {
  display: inline-block;
  vertical-align: middle;
  width: 98%;
}

.tab_mobtrigger {
  display: none;
}

.main_tabsec > .nav-tabs {
  border: 0;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-radius: 50px;
  margin: 0 0 20px;
}

.main_tabsec > .nav-tabs > li {
  margin: 0;
  display: table-cell;
  float: none;
  text-align: center;
  width: 50%;
  vertical-align: middle;
  border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs > li:first-child {
  border-left: 0;
}

.main_tabsec > .nav-tabs > li > a {
  background: #b69a6b;
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 17.5px 15px;
}

.main_tabsec > .nav > li > a:focus {
  background: none;
}

.main_tabsec > .nav-tabs > li.active > a,
.main_tabsec .nav-tabs > li.active > a:focus,
.main_tabsec .nav-tabs > li > a:hover {
  border: 0;
  background: #0b0405;
  color: #fff;
}

.main_tabsec > .nav-tabs > li > a:after {
  content: "";
  border: 18px solid #0b0405;
  border-color: #0b0405 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -36px;
  margin-left: -18px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}
.main_tabin {
  border-radius: 4px;
}

.filter_tabsec .nav-tabs {
  margin: 0 0 30px;
}

.filter_tabsec .nav-tabs > li {
  width: 17%;
  margin: 0;
  display: inline-block;
  float: none;
  text-align: center;
  vertical-align: middle;
}

.filter_tabsec .nav-tabs > li:first-child {
  border-left: 0;
}

.filter_tabsec .nav-tabs > li > a {
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #272626;
  font-size: 18px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 0 0 5px;
  display: block;
  font-family: "Poppins", sans-serif;
}

.filter_tabsec .nav > li > a:focus {
  background: none;
}

.filter_tabsec .nav-tabs > li:hover > a,
.filter_tabsec .nav-tabs > li.active > a,
.filter_tabsec .nav-tabs > li.active > a:focus,
.filter_tabsec .nav-tabs > li.active > a:hover {
  border: 0;
  background: none;
  color: #060d15;
}

.filter_tabsec .nav-tabs > li > a:after {
  content: "";
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}

.filter_tabsec .nav-tabs > li:hover > a:after,
.filter_tabsec .nav-tabs > li.active > a:after {
  width: 100%;
}

.modal-dialog {
  margin: 0.5rem;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
  min-height: -webkit-calc(100% - (0.5rem * 2));
}

.modal-content {
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 14px;
  border: 0px solid #ff7e91;
}

.poup_wrap {
  width: 660px;
}

.popup_in {
  padding: 28px 60px 54px;
}

.close_popup {
  font-size: 38px;
  font-weight: normal;
  line-height: 32px;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 32px;
  width: 32px;
  color: #231f20;
  text-align: center;
}

.chosen-container .chosen-results .highlighted {
  background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
  background: none;
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 45px;
  height: 22px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 1px;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

ul.tg-list {
  float: left;
  padding-top: 7px;
  list-style: none;
  padding-left: 0;
}

.tgl-light + .tgl-btn {
  background: #f9f9f9;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #b4b4b4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 22px;
  height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
  background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
  display: none;
}

.mobile_hmenu_list .submenu-arow {
  display: block;
  width: 40px;
  background: #e6e6e6;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
}

.submenu-arow:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  top: 50%;
  line-height: 1;
  margin-top: -11px;
  font-size: 22px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  display: block !important;
}

.open-submenu .submenu-arow:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: block !important;
}

.hsearch_sec,
.hsearch_sec_mbl {
  width: 380px;
  position: absolute;
  right: -20px;
  top: 42px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 3;
  visibility: hidden;
}

.hsearch_sec.open,
.hsearch_sec_mbl.open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.hsearch_bx {
  position: relative;
}

.hsearch_bx .form-control {
  height: 48px;
  border: 0;
}

.hsearch_bx .btn {
  background: #ecdfcc url(../images/search.png) no-repeat center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 65px;
  border-radius: 0;
  margin: 0;
  height: auto;
  padding: 0;
}

.hsearch_bx .btn:hover {
  background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
  border: 0;
  border-radius: 8px;
  -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
  box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
  overflow: visible;
  height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
  opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
  line-height: 52px;
  padding: 0 26px;
  color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
  height: 54px;
  padding: 0 26px;
  float: left;
}

.hsearch_bx .re_select .Select-input > input {
  line-height: 34px;
}

.hsearch_trigger_img img {
  width: 26px;
}

.hsearch_trigger_img {
  margin: 5px 0 0 10px;
}

.hmenu_sec ul {
  padding: 0;
  list-style: none;
  text-align: initial;
}

.hmenu_list {
  margin: 0;
}

.hmenu_list > li {
  display: inline-block;
  position: relative;
}

.hmenu_list > li:hover > a,
.hmenu_list > li.active > a {
  color: #fff;
}

.hmenu_list > li > a > span {
  position: relative;
}

.hmenu_actions > li {
  list-style: none;
  position: relative;
}

.hordertype_sec a {
  display: block;
  padding: 10px 22px;
  color: #fff;
  background: #FF1B2C;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  position: relative;
  letter-spacing: 0.5px;
  margin: 0 0 0 13px;
  line-height: 1;
}

.hordertype_sec a:hover {
  color: #000;
  background: #fff;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
  margin: 0;
  padding: 0px;
  background: #e5dbc9;
  z-index: 99;
  width: 220px;
  position: absolute;
  left: 0px;
  top: 33px;
  display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover > ul {
  display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #1b0000;
  text-transform: uppercase;
  padding: 10px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.078);
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
  color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
  display: none;
  background: #f7f7f7;
}

.mobile_hmenu_list ul.submenu_list li a {
  display: block;
  padding: 10px 15px;
  font-family: "Poppins";
  font-size: 16px;
  color: #333;
  border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
  color: #FF1B2C;
}

.hsign_sec > a {
  display: inline-block;
  position: relative;
}

.hsign_sec > a:hover {
  color: #000;
}

.search_result,
.hsearch_sec .search_result,
.hsearch_sec_mbl .search_result_mbl {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
}

.search_result li,
.search_result_mbl li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.search_result li a,
.no-pro-found,
.search_result_mbl li a {
  color: #333;
  display: block;
  padding: 7px 15px;
}

.search_result li a:hover,
.search_result_mbl li a:hover {
  background: #FF1B2C;
  color: #fff;
}

.hsearch_sec.open .input-sec,
.hsearch_sec_mbl.open .input-sec {
  position: relative;
}

.hsearch_sec.open .input-sec input,
.hsearch_sec_mbl.open .input-sec input {
  padding-right: 40px;
  margin-bottom: 0;
  border-color: rgba(0, 0, 0, 0.3);
}
.search_text_clear {
  position: absolute;
  right: 10px;
  top: 11px;
  display: none;
}

.htico_cart {
  position: relative;
  list-style: none;
  width: 100%;
}

.htico_cart > a span {
  color: #101010;
  font-size: 17px;
  display: block;
  margin: 0 0 0 15px;
  font-family: "Poppins", sans-serif;
}
.htico_cart > a span sup {
  font-size: 60%;
}

.hcartdd_trigger.active:before {
  opacity: 1;
  visibility: visible;
}
.hmenu-login-act .syd_merge {
  justify-content: space-around;
  gap: 0;
}
.hmenu-login-act .syd_merge .syd_date {
  flex: inherit;
  max-width: inherit;
  min-width: inherit;
  width: 49%;
}
.hmenu-login-act .syd_merge .syd_time.delivery_submit_cls {
  flex: inherit;
  max-width: inherit;
  min-width: inherit;
  width: 49%;
}
.product-cart .product-cart-item-list .hcart_dropdown {
  display: block;
  background: #fff;
  right: 0;
  width: 100%;
  position: relative;
  height: auto;
  right: 0;
  top: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index: 11;
  text-align: left;
}
.product-cart .product-cart-item-list .hcart_dropdown.open {
  transform: translateX(0);
  display: block;
}
.product-cart .product-cart-item-list .hcart_dropdown .hcart_tt {
  display: none;
}
.hcartdd_trigger.active.open_sticky::after {
  bottom: -25px;
}
.hcartdd_trigger .hcart_dropdown.open_sticky {
  top: 67px;
}
.hmenu-login-act .hcart_dropdown {
  background: #fff;
  right: 0;
  width: 100%;
  position: relative;
  height: 100vh;
  right: 0;
  top: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index: 11;
  text-align: left;
}
.hmenu-login-act .hcart_dropdown.open {
  transform: translateX(0);
  display: block;
  /* height: auto; */
  z-index: 99999;
}
.hmenu-login-act .syd_merge .form-group222 {
  position: inherit;
}
body.cart-items-open {
  position: fixed;
  overflow: hidden;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: block;
}
body.cart-items-open .logo-main-section:before {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.68);
  z-index: 9;
}
.hcart_tt {
  background: #fff9df;
  padding: 10px 20px 10px 20px;
  color: #000;
  text-align: center;
  position: relative;
}

.hcart_tt h3 {
  margin: 0;
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.tnk-delivery .hcart_tt h3 {
  text-transform: capitalize;
  font-size: 20px;
}

.hcart_tt h3 a:hover {
  color: #fff;
}

.hclear_cart {
  color: #fff;
}

.hcart_scrollarea {
  max-height: calc((100% - 48px) - 70px);
  height: 100%;
}
.hcart_scrollarea .cart_price p {
  padding-right: 15px;
}
.hcart-form-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  gap: 10px;
}
.hcart-form-group .form-group .form-control {
  border-radius: 3px;
  padding: 10px;
}

.cart_row {
  padding: 0px;
  position: relative;
}

.cart_info {
  color: #3d3d3d;
}

.cart_info h4 {
  font-size: 15px;
  margin: 0 0 6px;
  color: #000;
  font-weight: 500;
}

.cart_left .help-block {
  font-style: italic;
  color: #a7a4a4;
  margin: 5px 0 0;
  font-size: 13px;
}

.cart_extrainfo {
  color: #000;
  padding-left: 10px;
}

.cart_extrainfo p {
  margin: 0;
  color: #333;
  font-size: 13px;
  font-style: italic;
}

.cart_price {
  position: relative;
  margin: 0 0 5px;
}

.cart_price p {
  margin: 0;
  color: #000000;
  font-size: 15px;
  font-family: "Poppins";
}

.cart_remove {
  background: #b0b0b0;
  position: absolute;
  top: 6px;
  right: 0px;
  width: 12px;
  height: 12px;
}
.cart_remove:after {
  background: url(../images/cross1.png) no-repeat center;
  background-size: contain;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cart_remove:hover {
  background-color: #000;
}

.qty_bx {
  width: 126px;
  height: 36px;
  position: relative;
  padding: 0 40px;
  display: inline-block;
}
.compo_acc_innerdiv .qty_bx span {
  width: 41px;
  border: 2px solid #e9e9e9;
}
.qty_bx span {
  position: absolute;
  width: 36px;
  height: 100%;
  display: block;
  top: 0%;
  bottom: 0;
  text-indent: 9999px;
  overflow: hidden;
  cursor: pointer;
  margin: auto;
  background: #fff;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  border: 2px solid #e9e9e9;
}

.qty_bx span.qty_minus {
  left: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.qty_bx span.qty_plus {
  right: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.qty_bx input {
  height: 100%;
  border: 2px solid #e9e9e9;
  color: #000000;
  font-size: 15px;
  text-align: center;
  margin: 0;
  padding: 2px;
  background: #fff;
  border-radius: 5px;
  font-weight: bold;
}

.qty_bx span:after {
  position: absolute;
  width: 15px;
  height: 15px;
  display: block;
  top: 50%;
  margin-top: -7px;
  content: "";
}

.qty_bx span.qty_minus:after {
  left: 9px;
  background: url(../images/minus.svg) no-repeat;
  height: 1px;
  margin-top: -1px;
}

.pkglist_pricebx .qty_bx span.qty_minus:after {
  height: 2px;
}

.qty_bx span.qty_plus:after {
  right: 8px;
  background: url(../images/plus.svg) no-repeat;
}

.cart_footer .cart_row {
  padding-top: 0;
  border: 0;
}
.cart_footer .text-right span {
  font-family: "Poppins";
}

.cart_footer span {
  font-size: 16px;
  font-weight: 700;
}

.discount_info {
  margin-left: 15px;
}

.discount_info p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #3d3d3d;
}

.promo_delico_parent {
  position: relative;
}

.promo_delico {
  position: absolute;
  top: 0;
  left: -21px;
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  color: #3d3d3d;
}

.promo_delico:hover {
  background-color: #b69a6b;
  color: #fff;
}

.cart_footer_totrow p {
  font-size: 25px;
}

.cart_footer_totrow p.help-block {
  font-size: 12px;
  font-size: 14px;
  font-weight: 300;
}

.cart_footer_totrow span {
  font-size: 25px;
}

.indication_progress {
  height: 12px;
  overflow: hidden;
  border-radius: 10px;
  background: #dcdcdc;
  position: relative;
}

.progress_bar {
  content: "";
  height: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  background: #00cfc9;
  border-radius: 10px;
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
}

.cdd-details-rhs .cart_footer .indication .help-block {
  color: #000;
  font-size: 16px;
  margin: 3px 0 0;
  text-align: center;
  font-weight: 700;
}

.delivery_info {
  background: #f5f5f5;
  padding: 24px 35px;
}
.free-delivery .progress_bar {
  background: #7fcfa8;
}
.minimum-order-amount .progress_bar {
  background: #f1d38c;
}

.delivery_address {
  margin: 0 0 20px;
}

.delivery_info h4 {
  color: #838181;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}

.delivery_info p {
  color: #3d3d3d;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.delivery_link {
  color: #3d3d3d;
  line-height: 1;
  border-bottom: 1px solid #b4b4b4;
  margin: 10px 0 0 0;
  display: inline-block;
  vertical-align: top;
}

.delivery_link:hover {
  border-color: #b69a6b;
}

.delivery_time {
  margin: 0 0 16px;
}

.delivery_handle {
  border-top: 1px solid #d3d1d1;
  padding: 20px 0 0 0;
}

.cart_action .btn_grey {
  margin: 0;
  border-color: #e6e3e3;
}

.download_btn i {
  margin-right: 9px;
  vertical-align: top;
  display: inline-block;
}

.ico_download {
  width: 24px;
  height: 22px;
  background: url(../images/download.png) no-repeat;
}

.download_btn:hover .ico_download {
  background-image: url(../images/download1.png);
}

.cartaction_price {
  font-size: 24px;
  width: 30%;
  cursor: default;
}

.cartaction_price sup {
  font-size: 50%;
  left: -4px;
  top: -7px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.cart_action {
  padding: 10px;
}
header .cart_action {
  padding: 20px 0px 0 0px;
}

.header_top {
  background: #181818;
  position: relative;
}

.htopinfo {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  padding: 10px 0;
}

.htopinfo p {
  margin: 0;
}

.pop-alert-icon {
  padding: 0 0 15px;
}

.header_bot {
  position: relative;
}

.sticky .alert_fixed {
  top: 95px;
}

.scrollToTop {
  background: rgba(0, 0, 0, 0.7);
  width: 40px;
  height: 40px;
  line-height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  font-size: 24px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
  background: rgba(0, 0, 0, 1);
  color: #fff;
}

.viewmore_poplink {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.hbslider_sec h2 {
  font-size: 24px;
  color: #363636;
}

.hbslider_sec .slick-dots {
  position: absolute;
  bottom: 15px;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-dots li,
.home-outlets-list .slick-slider .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.hbslider_sec .slick-dots li button,
.home-outlets-list .slick-slider .slick-dots li button {
  background: none;
  border: 1px solid #fff;
  padding: 0;
  border-radius: 50%;
  font-size: 0;
  width: 12px;
  height: 12px;
}

.hbslider_sec .slick-dots li.slick-active button {
  background: #000;
}
.home-outlets-list .slick-slider .slick-dots li button {
  background: #000;
  border: 1px solid #000;
}
.home-outlets-list .slick-dots li.slick-active button {
  background: #FF1B2C;
  border: 1px solid #FF1B2C;
}

.hbslider_sec .slick-arrow {
  z-index: 1;
}

.hbslider_sec .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hbslider_sec .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -188px -60px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-next:hover {
  background: #f5f5f5;
}

.hbslider_sec .slick-next:hover::after {
  background-position: -78px -60px;
}

.hbslider_sec .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hbslider_sec .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -136px -61px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hbslider_sec .slick-prev:hover {
  background: #f5f5f5;
}

.hbslider_sec .slick-prev:hover::after {
  background-position: -26px -61px;
}

.white-popup .hbslider_sec .hbslide {
  text-align: center;
}

.bakery_inner {
  padding-bottom: 15px;
}

.hme-product-inner {
  padding: 50px 50px;
  background: #fff;
  margin: 25px 0 0 0;
}

.hme-product-inner .filter_tabsec .nav-tabs {
  margin-bottom: 64px;
  border-bottom: 0;
}

.hme-product-inner .title_sec {
  margin-bottom: 25px;
}

.hme-product-inner .ttsplitter {
  margin-bottom: 25px;
}

.hme-prt-row .slick-slider {
  padding: 0 90px;
}

.hme-prt-column {
  position: relative;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.hme-product-inner .slick-slide {
  padding: 0 10px;
  outline: 0;
}

.hme-product-inner .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hme-product-inner .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -78px -60px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hme-product-inner .slick-next:hover {
  background: #f5f5f5;
}

.hme-product-inner .slick-next:hover::after {
  background-position: -188px -60px;
}

.hme-product-inner .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.hme-product-inner .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -26px -61px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.hme-product-inner .slick-prev:hover {
  background: #f5f5f5;
}

.hme-product-inner .slick-prev:hover::after {
  background-position: -136px -61px;
}

.hme-prt-column {
  position: relative;
}

.hme-prt-innerslide {
  position: relative;
}

.hme-prt-inner {
  position: relative;
}

.hme-prt-slidecontent {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.86);
  opacity: 0;
  padding: 15px;
}

.hme-prt-hei {
  text-align: center;
}

.hme-prt-hei img {
  max-height: 100%;
  width: auto;
  height: auto;
}

.hme-prt-column:hover .hme-prt-slidecontent {
  opacity: 1;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.hme-prt-slidecontent-inner {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: relative;
  text-align: center;
}

.hme-prt-slidecontent-inner h3 {
  font-size: 19px;
  margin-bottom: 3px;
  color: #556c11;
  height: 27px;
  overflow: hidden;
}

.hme-prt-slidecontent-inner span {
  font-size: 19px;
  color: #556c11;
}

.hme-prt-slidecontent-inner p {
  color: #556c11;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 23px;
  height: 40px;
  overflow: hidden;
  padding: 0 10px;
}

.outlet {
  position: relative;
}

.outlet_inner {
  padding: 63px 35px 76px 35px;
  background: #fff;
  margin: 25px 0 39px 0;
}

.bread_space {
  margin: 40px 0 0;
}

.breadcrumb {
  margin-bottom: 0;
  padding: 30px 0 0 0;
}

.breadcrumb_inner ul {
  list-style: none;
}

.breadcrumb_left {
  float: left;
  width: 50%;
}

.breadcrumb_left ul {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.breadcrumb_right {
  float: right;
  width: 50%;
  text-align: right;
  position: relative;
}

.breadcrumb_right .chosen_left {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: right;
  right: 170px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.breadcrumb_right .re_select {
  max-width: 170px;
  float: right;
  width: 100%;
}

.breadcrumb_right .re_select {
  max-width: 170px;
}

.breadcrumb_right .re_select .Select-clear-zone {
  display: none;
}

.breadcrumb_inner ul p {
  margin-bottom: 0;
  font-weight: 700;
}

.breadcrumb_inner ul p a {
  display: inline-block;
  color: #5b5b5b;
}

.breadcrumb_inner ul p a:hover {
  color: #000;
}

.breadcrumb_inner ul p i {
  padding: 0 10px;
  display: inline-block;
}

.breadcrumb_right .form-group {
  margin-bottom: 0;
}

.breadcrumb_right .chosen_left span {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  color: #5b5b5b;
  vertical-align: middle;
  font-weight: 700;
  padding-right: 15px;
}

.breadcrumb_right .chosen-container {
  display: inline-block;
  text-align: left;
}

.breadcrumb_right .chosen-container-single .chosen-single {
  width: 170px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ecdfcc;
}

.breadcrumb_right .chosen-container-single .chosen-single span {
  text-transform: capitalize;
}

.breadcrumb_right .chosen-container-single .chosen-single div b {
  background: url(../images/spirite_whole.png) no-repeat center;
  background-position: -205px -24px;
  width: 14px;
  height: 8px;
}

.breadcrumb_right
  .chosen-container-active.chosen-with-drop
  .chosen-single
  div
  b {
  background: url(../images/spirite_whole.png) no-repeat center;
  width: 14px;
  height: 8px;
  background-position: -236px -24px;
}

.breadcrumb_right .chosen-container-single .chosen-single div {
  top: 15px;
  right: -17px;
}

.breadcrumb_right .chosen-container .chosen-results .highlighted {
  background: #ecdfcc;
}

.innerproduct-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.addcart_row {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2px 5px;
  max-width: 320px;
  margin: 0 auto;
}

.addcart_row.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.addcart_row .qty_bx {
  width: 56%;
  vertical-align: middle;
}

.addcart_row .btn {
  margin: 0 0 0 2%;
  width: 42%;
  padding-left: 8px;
  padding-right: 8px;
}

.special_product {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  display: inline-block;
  width: 26px;
  height: 23px;
}

.special_product::after {
  position: absolute;
  content: "";
  top: 0;
  background: url(../images/spirite_whole.png) no-repeat;
  background-position: -458px -19px;
  z-index: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
}

.innerproduct-item {
  position: relative;
}

.innerproduct-item-image {
  background: #fff;
  text-align: center;
}

.innerproduct-item-image img {
  height: auto;
  width: auto;
}

.innerproduct-item-hei {
  height: 255px;
  line-height: 255px;
  text-align: center;
}

.innerproduct-item::after {
  content: "";
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.86);
  width: 100%;
  position: absolute;
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  opacity: 0;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
  opacity: 1;
}

.innerproduct-button {
  position: absolute;
  top: 0%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover .innerproduct-button,
.innerproduct-col.open .innerproduct-button {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.innerproduct-item-text {
  text-align: center;
}

.innerproduct-item-text h4 {
  font-size: 16px;
  color: #363636;
  padding: 10px 0 8px 0;
  margin-bottom: 0;
}

.innerproduct-item-text h4 a {
  color: #363636;
}

.innerproduct-item-text p {
  font-weight: 300;
}

.innerproduct-item-text p a {
  color: #5b5b5b;
}

.innerproduct-item-text h5 {
  font-size: 20px;
  color: #363636;
  margin-bottom: 0;
}

.innerproduct-item-text h5 a {
  color: #363636;
}

.price_none {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  color: #999;
  padding-right: 10px;
}

.price_offer .price_none {
  width: 50%;
  text-align: left;
}

.price_offer .price_nor {
  width: 50%;
  text-align: right;
  display: inline-block;
  margin-left: -4px;
}

.inn-product-popup {
  background: #fff;
}

.inn-product-popup .container {
  padding: 0;
}
.prodet_banleft {
  width: 100%;
}

.prodet_baninfo {
  width: 100%;
  padding: 15px 40px;
  position: relative;
}
.inn_product_hea_tag {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.inn_product_hea_tag span {
  display: block;
  border: 1px solid #000;
  color: #000;
  text-transform: capitalize;
  padding: 1px 7px;
  margin: 2px;
}

.prodet_baninfo .product-tags-list {
  position: relative;
  bottom: inherit;
  float: none;
  margin: 0 0 20px 0;
}

.prodet_baninfo .product-tags-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.prodet_baninfo .product-tags-list ul li {
  border: 1px solid #000;
  color: #000;
  text-transform: capitalize;
  padding: 1px 7px;
  margin: 2px;
}

.inn-product-popup-bg {
  position: relative;
}

.inn-product-popup-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 345px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.inn-product-popup-inner {
  position: relative;
  padding-top: 0px;
  line-height: 1;
}

.inner_product_slide {
  outline: 0;
}

.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
  display: none !important;
}

.inn-product-popup-inner .custom-dots {
  padding: 0;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
}

.inn-product-popup-inner .custom-dots li {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
  padding: 0;
  margin: 0;
}

.inn-product-popup-inner .custom-dots li button:before {
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
  background: #FF1B2C;
}

.inn-product-popup-inner .custom-dots li button {
  text-indent: -9999px;
  background: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}

.inner_product_slide {
  position: relative;
}

.pthumb_slide {
  margin: 5px;
  border: 1px solid #a2a6ac;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
  -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
  box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
  border-color: #e77600;
}

.popup_social {
  position: absolute;
  bottom: 5px;
  z-index: 1;
  right: 0;
  right: 5px;
}

.popup_social span {
  color: #373535;
  font-size: 12px;
  font-weight: 700;
}

.popup_social_icon {
  list-style: none;
  margin-bottom: 0;
  background: #fff;
  padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
  float: left;
}

.popup_social_icon li a {
  color: #2a2a2a;
  padding: 0 10px;
  display: inline-block;
}

.popup_social_icon li a:hover {
  color: #ccc;
}

.popup_social_inner {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup_social_left {
  padding-right: 10px;
}

.copied {
  position: absolute;
  right: 10px;
  top: -21px;
  background: #000;
  padding: 3px 7px;
  color: #fff;
  font-weight: normal;
  font-size: 11px;
  border-radius: 2px;
  pointer-events: none;
}

.copied:before {
  content: "";
  border: 7px solid #000;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -11px;
  margin-left: -7px;
}

.inn_product_row {
  margin: 0 auto 0 auto;
}

.inn_product_hea p {
  color: #5b5b5b;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 20px;
}

.inn_product_hea_left h3 {
  font-size: 25px;
  margin: 0 0 10px 0;
  color: #000;
}

.inn_product_hea_icon {
  padding-right: 45px;
  display: inline-block;
}

.inn_product_hea_icon img {
  margin: 0 2px;
}

.inn_product_hea_star .checked {
  color: #72624f;
}

.inn_product_hea_star .star {
  color: #d7cbb9;
}

.inn_product_review_text a {
  text-decoration: underline;
  color: #5b5b5b;
  font-weight: 700;
}

.inn_product_review_text {
  padding-left: 10px;
}

.inn_product_review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.prd_chosen_row {
  padding: 20px 40px 30px 40px;
}

.product_chosen_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product_chosen_col {
  width: 100%;
}

.product_chosen_col.product_chosen_col_right {
  margin-right: 0;
}

.product_chosen_col_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
  margin-bottom: 0;
}

.form-group-modifier {
  margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
  width: 100%;
  margin-bottom: 10px;
}

.product_chosen_item_left:nth-of-type(2n + 2) {
  margin-right: 0;
}

.product_chosen_item_left_second {
  float: left;
  width: 47%;
  margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n + 2) {
  margin-right: 0;
}

.select_full {
  width: 100%;
}

.product_chosen_hea h6 {
  font-size: 19px;
  color: #000000;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  padding-right: 10px;
  font-weight: 600;
}

.product_chosen_hea {
  position: relative;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
  width: 100%;
  height: 47px;
  line-height: 40px;
  border: 1px solid #e6e5e5;
  font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
  background: url(../images/spirite_whole.png) no-repeat center;
  background-position: -246px -62px;
  width: 28px;
  height: 28px;
}

.product_chosen_item_left
  .chosen-container-active.chosen-with-drop
  .chosen-single
  div
  b {
  background: url(../images/spirite_whole.png) no-repeat center;
  width: 28px;
  height: 28px;
  background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
  top: 10px;
  right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
  background: #ecdfcc;
}

.pmodifier_ddlist ul {
  padding: 0;
  margin: 7px 0;
  list-style: none;
}

.pmodifier_ddlist li {
  background: #ecdfcc;
  padding: 3px 35px 3px 17px;
  border: 1px solid #dcd8d8;
  border-radius: 20px;
  position: relative;
  font-weight: 700;
  color: #181818;
  margin: 0 0 4px;
}

.pmodifier_close {
  background: url(../images/close-modifier.png) no-repeat center;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 20px 20px 0;
  margin: auto 0;
}

.pmodifier_close:hover {
  background-image: url(../images/cross1.png);
  background-color: #1c1c1c;
}

.product_chosen_addons {
  width: 100%;
}

.chosen_adn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 10px 0;
}

.chosen_adn_right {
  line-height: 0;
}

.chosen_adn_left span {
  font-size: 16px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  z-index: 1;
  padding-right: 10px;
}

.radio.chosen_adn_right label {
  padding-left: 0;
}

.radio .qty_bx label {
  font-weight: 700;
  border-radius: 0px;
}

.product_chosen_col_right .custom_checkbox {
  width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
  font-size: 16px;
  font-weight: 700;
  color: #72624f;
  position: relative;
  display: block;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  padding-right: 30px;
  padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
  right: 0;
  left: auto;
  top: -5px;
}

.product_chosen_col_right .chosen_adn {
  margin-bottom: 16px;
}

.text-box {
  width: 100%;
}

.text-box em {
  text-transform: uppercase;
  font-size: 13px;
  color: #181818;
  text-align: right;
  float: right;
  font-weight: 700;
}

.text-box textarea {
  width: 100%;
  height: 170px;
  padding: 15px 15px;
}

textarea {
  resize: none;
}

.chosen_upload {
  float: right;
  text-align: right;
  width: 100%;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  outline: 0;
}

.inputfile + label {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 11.5px 15px;
  outline: 0;
  background: #ecdfcc;
  max-width: 160px;
  border: 1px solid #e6e5e5;
  text-overflow: ellipsis;
}

.inputfile + label:hover {
  background: #333;
}

.inputfile + label:hover span {
  color: #fff;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.chosen_upload_inner label span {
  color: #72624f;
}

.inputfile-4 + label {
  color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
  color: #722040;
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #d3394c;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
  background-color: #722040;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: 722040;
}

.prd_chosen_sub_row {
  text-align: center;
}

.prd_chosen_sub_row > p {
  font-size: 35px;
  color: #000;
  font-family: "Poppins";
  margin-bottom: 5px;
}
.prd_chosen_sub_row p #compoprice_final {
  margin-left: 5px;
}
.prd_chosen_sub_row p > sup {
  font-size: 20px;
}

.prd_chosen_sub_col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 576px;
  margin: 0 auto;
}
.popup_addcart_cls {
  max-width: 100%;
}

.prd_chosen_sub_col .alert {
  margin: 15px 0 0;
  width: 100%;
}

.prd_chosen_sub_item_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.prd_chosen_sub_item_right {
  /*width: 67.64705882352942%;*/
  width: 100%;
}

.prd_chosen_sub_item_right button {
  border: 0;
  padding: 15px 15px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  outline: 0;
  margin-bottom: 0;
  background: #ff211f;
}

.prd_chosen_sub_item_right button:hover {
  background: #000000;
}

.prd_chosen_sub_item_right.prd_chosen_item_full {
  width: 100%;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
  max-width: 100%;
  margin: 0;
  letter-spacing: 0.5px;
  font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
  background: #333;
}

.prd_chosen_sub_item_left .qty_bx {
  width: 126px !important;
}

.prd_chosen_sub_item_left .qty_bx .qty_minus {
  background: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 41px;
}

.prd_chosen_sub_item_left .qty_bx .qty_plus {
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 41px;
}

.prd_chosen_sub_item_left .qty_bx input {
  color: #000 !important;
  border-radius: 0px;
}

.skip {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  background: #dbd9d9;
  max-width: 89px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 7px 15px;
  color: #181818;
}

.skip:hover {
  color: #fff;
  background: #333;
}

.prd-sub-bg {
  background: #fff;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 10px 0 10px 10px;
  -webkit-border-radius: 10px 0 10px 10px;
  box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
  position: relative;
}

.prd-sub {
  max-width: 862px;
  position: relative;
  margin: 0 auto;
}

.prd-sub h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}

.prd-sub .slick-slide {
  padding: 0 5px;
}

.prd-sub .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: -69px;
  top: 23%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.prd-sub .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -27px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.prd-sub .slick-next:hover {
  background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
  background-position: -154px -123px;
}

.prd-sub .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: -69px;
  top: 23%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
}

.prd-sub .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -86px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.prd-sub .slick-prev:hover {
  background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
  background-position: -213px -123px;
}

.prd-sub-slide-content {
  text-align: center;
  margin-top: 10px;
}

.prd-sub-slide-content h5 {
  font-size: 14px;
  font-weight: 700;
  color: #363636;
  margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
  background: #f4b56b;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
  background: #e48f2c;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
  background: #333;
}

.prd-sub-slide-qty .qty_bx input {
  background: #fff;
  width: 62px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
  background: #ecdfcc;
  padding: 4px 0 0px 0;
  border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
  width: 146px;
  height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
  height: 40px;
}

.prd-sub-slide-submit {
  background: #181818;
}

.prd-sub-slide-submit a {
  font-size: 18px;
  padding: 13px 0;
  display: block;
  text-transform: uppercase;
  color: #fff;
  border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
  background: #e48f2c;
}

.slick-slide {
  outline: 0;
}

.prd-sub-slide-content .btn {
  width: 100%;
}

.checkout_hea {
  position: relative;
}

.checkout_hea::after,
.check_pg::after {
  display: block;
  content: "";
  clear: both;
}

.checkout_hea h3 {
  font-size: 27px;
  margin-bottom: 5px;
}

.checkout_hea p {
  margin-bottom: 25px;
  color: #5b5b5b;
}

.checkout_hea_inner {
  margin-top: 8px;
}

.check_pg {
  position: relative;
}

.check_pg_inner {
  background: #fff;
  padding: 75px 0px 29px 0px;
  margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
  padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
  width: auto;
  margin-right: 0;
}

.check_pg_inner .slick-slide img {
  text-align: center;
  margin: auto;
}

.check_pg_inner .slick-slide {
  padding: 0 7.5px;
}

.check_pg_inner .slick-next {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  right: 0;
  top: 32.5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
  z-index: 1;
}

.check_pg_inner .slick-next::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -27px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.check_pg_inner .slick-next:hover {
  background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
  background-position: -154px -123px;
}

.check_pg_inner .slick-prev {
  font-size: 0;
  border: 0;
  width: 62px;
  height: 77px;
  outline: 0;
  position: absolute;
  left: 0;
  top: 32.5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: inherit;
  z-index: 1;
}

.check_pg_inner .slick-prev::after {
  content: "";
  width: 39px;
  height: 39px;
  background: url(../images/spirite_whole.png) no-repeat;
  position: absolute;
  background-position: -86px -123px;
  left: 0px;
  top: 30%;
  margin: auto;
  right: 0;
  text-align: center;
}

.check_pg_inner .slick-prev:hover {
  background: #f5f5f5;
}

.check_pg_inner .slick-prev:hover::after {
  background-position: -213px -123px;
}

.checkoutpage_form_outer .form-group {
  margin-bottom: 15px;
}

.check_out_fourcommon {
  width: 100%;
  margin: 0 auto;
}

.checkoutpage_form_outer {
  width: 100%;
  margin: 0 auto;
}
.chk_only {
  display: none !important;
}
.cdd-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cdd-details .ttsplitter {
  margin-bottom: 0;
}
.checkout-total {
  width: 100%;
  margin: 25px auto 0px auto;
}
.checkout-total::after {
  display: block;
  content: "";
  clear: both;
}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {
  padding: 14px 20px;
  position: relative;
  background: #87311d;
  border-bottom: 0px;
  text-align: center;
  font-size: 24px;
  width: 100%;
  margin-bottom: 0;
}
.checkout-total .cart-header {
  padding: 0;
  background: inherit;
  border: 0;
  margin: 0;
}
.checkout-total .cdd-details-rhs .cart-header h4 {
  margin: 0;
  line-height: normal;
}
.checkout-total .cart-header:after {
  display: none;
}
.checkout-total .cdd-details-lhs {
  width: 49.5%;
  position: relative;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
}
.checkout-total .cdd-details-rhs {
  width: 49.5%;
  padding: 0;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.18);
}
.checkout-right-body-section {
  padding: 20px;
  margin-bottom: 250px;
}
.checkout-body-section {
  padding: 20px;
}
.checkout-total .cdd-details-lhs .chk_hea {
  margin: 0;
}
.checkout-total .cdd-details-lhs .txt_address {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.checkout-total .controls-single input[type="text"] {
  background: #f7f6f6;
  color: #6f6f6f;
  font-size: 16px;
}
.checkout-total .cdd-details-rhs .chekout_cart_bar {
  position: relative;
  width: 100%;
  height: 100%;
  height: 100%;
}
.checkout-total .cdd-details-rhs .chekout_cart_bar .cart_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 28px 20px;
}
.checkout-total
  .cdd-details-rhs
  .chekout_cart_bar
  .cart_footer
  .grant-total-cls {
  margin: 5px 0;
  border-top: 1px solid #f4f4f4;
}
.checkout-total .cdd-details-rhs .cart-outlet-name {
  background: transparent;
}
.checkout-total .cart_body .hcart_tt {
  background: #f5f5f5;
}
.checkout-total .cart_body .hcart_tt h3 {
  color: #000;
  text-transform: capitalize;
}
.checkout-total .cart_body .hclear_cart {
  color: #000;
  text-transform: capitalize;
  border-bottom: 1px solid #000;
  font-weight: 600;
}
.checkout-total .cart_body .hclear_cart:hover {
  color: #000;
}
h6.chk_hea {
  font-size: 17px;
  padding: 13px 20px;
  color: #272727;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px 0;
  line-height: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chk_hea {
  font-size: 17px;
  padding: 13px 20px;
  color: #272727;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px 0;
  line-height: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chk_hea > span {
  color: #393939;
  font-size: 14px;
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chk_hea .custom_checkbox span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
}
.chk_hea .custom_checkbox span:before {
  top: 0px;
  border-radius: 50%;
}
.chk_hea .custom_checkbox input[type="checkbox"]:checked + span:before {
  border: 0;
  top: 0;
}

.checkout-total .chosen-container .chosen-results .highlighted {
  background: #ecdfcc;
}
.checkout-total .checkout-control-group-top.pickup {
  text-align: center;
}
.checkout-total .checkout-control-group-top.pickup p {
  margin-bottom: 0;
}
.checkout-total .checkout-control-group-top a {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 1;
  border-bottom: 2px solid #000;
  margin: 5px 0 0 20px;
  display: inline-block;
  vertical-align: top;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
  color: #FF1B2C;
}

.checkout-total .checkout-control-group-top {
  padding: 5px 0 15px 0;
  text-align: right;
}
.checkout-total .syd_date {
  flex: 1 1 48%;
  width: 48%;
  max-width: 48%;
  min-width: 100px;
}
.checkout-total .syd_time.delivery_submit_cls {
  flex: 1 1 48%;
  width: 48%;
  max-width: 48%;
  min-width: 100px;
}
.checkoutpage_form_outer .syd_merge {
    gap: 0;
}
.checkoutpage_form_outer .checkout-total .syd_date {
    flex: 1 1 50%;
    width: 50%;
    max-width: 50%;
    min-width: 150px;
    padding: 2px;
}
.checkoutpage_form_outer .checkout-total .syd_time.delivery_submit_cls {
    flex: 1 1 50%;
    width: 50%;
    max-width: 50%;
    min-width: 150px;
    padding: 2px;
}
.checkout-control-group-topband {
  border-bottom: 1px solid #939292;
  padding: 0 0 35px 0;
}

.checkout-control-group-topband span {
  float: right;
  font-weight: normal;
  font-size: 15px;
}

.checkout-total .checkout-control-group-middle {
  margin: 0px 0 0 0;
  padding: 10px 0 0px 0;
  width: 100%;
}

.checkout-total .checkout-control-group-bottom {
  padding: 0;
}
.checkout-total .checkout-control-group-bottom .chk_cult {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout-total
  .checkout-control-group-bottom
  .chk_cult
  span
  .custom_radio
  span {
  padding: 5px 0 0 35px;
}
.checkout-total .checkout-control-group-bottom label {
  justify-content: flex-start;
}

.checkout-total .checkout-control-group-middle .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
  width: 48%;
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
  width: 49%;
}

.checkout-total .checkout-control-group-bottom textarea {
  padding: 20px 30px;
  height: 100px;
  font-size: 15px;
  width: 100%;
  outline: 0;
  height: 215px;
}

.checkout-total textarea::-webkit-input-placeholder {
  color: #595656;
}

.checkout-total textarea:-moz-placeholder {
  color: #595656;
}

.checkout-total textarea::-moz-placeholder {
  color: #595656;
}

.checkout-total .cart_body {
  padding: 0;
}

.checkout-total .form-group.controls-three,
.checkout-total .form-group.controls-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.checkout-total .form-group.controls-two > div {
  width: 48%;
}
.checkout-total .form-group.controls-three > div {
  width: 32%;
}

.checkout-total .form-group.controls-three > div input {
  margin-bottom: 0;
}

.order-detail-inner {
  padding: 82px 0 0 0;
}

.order-detail-inner-icon {
  position: relative;
  max-width: 604px;
}

.order-detail-inner-icon-left {
  position: absolute;
  left: 0;
  top: 2px;
}

.order-detail-scotter {
  position: relative;
  z-index: 2;
}

.order-detail-scotter::after {
  content: "";
  background: #fff url(../images/spirite_whole.png) no-repeat;
  background-position: -283px -126px;
  width: 41px;
  height: 24px;
  left: 0;
  top: -2px;
  position: absolute;
}

.order-detail-scotter::before {
  content: "";
  background: #fff url(../images/spirite_whole.png) no-repeat;
  background-position: -347px -126px;
  width: 22px;
  height: 22px;
  right: 0;
  top: -2px;
  position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
  position: absolute;
  right: 0;
  top: 3px;
  left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
  position: static;
  left: inherit;
  top: inherit;
  width: 100%;
  text-align: center;
  display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
  display: block;
  clear: both;
  content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  max-width: 497px;
  left: 0;
  right: 0;
  height: 10px;
  margin: auto;
  top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
  vertical-align: middle;
}

.delivery_total::after {
  display: block;
  content: "";
  clear: both;
}

.delivery_total {
  margin: 10px 0 0px 0;
}

.delivery_total_left {
  text-align: center;
  width: 100%;
}

.delivery_total_left h3 {
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 700;
  color: #1c1b1b;
  line-height: 22px;
  font-size: 17px;
}

.delivery_total_left span {
  display: block;
  color: #000;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}

.delivery_total_left.delivery_total_right {
  text-align: center;
}

.delivery_total.delivery_total_number {
  margin-bottom: 0;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.delivery_total.delivery_total_number .delivery_total_left h2 {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 500;
}
.delivery_total.delivery_total_number .delivery_total_left {
  text-align: left;
}
.delivery_total.delivery_total_number .delivery_total_right {
  text-align: right;
}
.tnk-delivery .delivery_total_left h4 {
  color: #060203;
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
  display: inline-block;
}
.tnk-delivery .delivery_total_left h4 span {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.tnk-delivery .hcart_tt {
  background: #f5f5f5;
}
.tnk-delivery .outlet-name {
  margin-bottom: 0;
  background: transparent;
  font-weight:normal
}
.delivery_total.delivery_total_number .delivery_total_left h4 {
  color: #060203;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: normal;
}
.tnk-delivery .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 30px;
}
.pickup-order-div:before {
  display: none;
}

.pickup-order-div .delivery_total_left {
  width: 100%;
  text-align: center;
}

.pickup-order-div .delivery_total_left img {
  width: 25px;
}

.cart-product-desc-inner {
  padding-left: 20px;
}

.cart-product-desc-inner span {
  display: block;
  color: #3d3d3d;
  line-height: 19px;
  font-size: 14px;
}

.chekout_cart_bar .cart_footer {
  padding: 28px 10px 10px 10px;
  margin: 0;
}

.chekout_cart_bar .cart_left {
  padding-left: 0;
}

.chk_hea .custom_checkbox {
  height: 20px;
  margin-left: 5px;
}
.checkout-total .cdd-details-lhs .billing-addres.chk_hea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  margin-bottom: 10px;
}
.chk-payment-btn-row {
  text-align: center;
}
.chk-payment-btn-row .button {
  display: inline-block;
  margin: 0;
  font-size: 21px;
  font-weight: 600;
  padding: 16px 10%;
  background: #ff211f;
}
.chk-payment-btn-row .button:hover {
  background: #231f20;
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
  width: 49%;
}

.dwn_quotion {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.dwn_quotion a {
  background: #e6e3e3;
  font-size: 16px;
  font-weight: 700;
  display: block;
  padding: 10px 0;
  color: #0d0d0d;
  text-transform: uppercase;
  margin-bottom: 0;
}

.dwn_quotion a:hover {
  background: #333;
  color: #fff;
}

.redeem {
  position: relative;
  margin-bottom: 20px;
}

.redeem::after {
  display: block;
  clear: both;
  content: "";
}

.redeem-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.redeem-col {
  width: 49.5%;
  background: #fff;
  padding: 30px 40px;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: none;
  box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%);
}
.redeem-col-full {
  width: 100%;
}
.redeem-col-full .redeem-item {
  max-width: 660px;
  margin: 0 auto;
}
.redeem-col.redeem-col-right {
  border-color: #f4e329;
}

.redeem-item-hea {
  padding-left: 10px;
  position: relative;
}

.redeem-item-hea h4 {
  font-size: 24px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 0;
}
.redeem-item-hea span {
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.redeem_apply {
  position: relative;
  margin-top: 5px;
}

.redeem_apply .btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  z-index: 11;
  margin: 0;
  background: #000;
  font-family: "Poppins";
  text-transform: capitalize;
}

.redeem_apply .btn:hover {
  background: #FF1B2C;
  color: #fff;
}

.redeem_apply .form-control {
  height: 50px;
}

.redeem-item-hea-inner {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
.redeem-item-hea-inner .redeem-tag:empty {
  display: none;
}
.redeem-item-hea-inner .redeem-tag {
  background: #231f1f;
  padding: 10px 20px;
}
.redeem-item-hea-inner .redeem-tag a {
  color: #fff;
  font-weight: 300;
}
.redeem-item-hea-inner .redeem-tag a span {
  color: #fff;
}
.chk-payment-footer {
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 6%);
  padding: 10px 0 10px;
}
.chk-payment {
  padding: 30px 10px 15px 10px;
  text-align: center;
}

.chk-payment-col-radio {
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.chk-payment-col-radio li {
  max-width: 275px;
  margin-right: 10px;
  border-radius: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chk-payment-col-radio li label {
  cursor: pointer;
  padding: 20px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 9%);
}
.chk-payment-col-radio li label.radio_checked {
  border: 2px solid #0000FF;
}
.chk-payment-col-radio li [type="radio"] {
  position: absolute;
  opacity: 0;
}
.chk-payment-col-radio li [type="radio"] + img {
  cursor: pointer;
}
.chk-payment .chk-payment-col-radio li .radio_con {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
  cursor: pointer;
  margin-bottom: 10px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #d0cfcb;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 22px;
  height: 22px;
  background: url(../images/spirite_whole.png) no-repeat;
  background-position: -399px -125px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
  width: 28px;
  height: 28px;
  background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
  width: 28px;
  height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
  width: auto;
  margin-right: 0;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  top: 0px;
}

.chosen_radio_inner {
  width: 100%;
}

.expand {
  background: #eaeaea;
  padding: 10px 15px;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.expand h3 {
  color: #000;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  width: 100%;
}

.expand::after {
  content: "\f107";
  right: 10px;
  display: block;
  top: 3px;
  font-family: "FontAwesome";
  position: absolute;
  color: #000;
  font-size: 26px;
}

.expand.active::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.expand_sub h1 {
  font-size: 16px;
  font-weight: 700;
  color: #72624f;
  position: relative;
  margin-bottom: 0;
  background: #fbfbfb;
  z-index: 1;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 10px;
}

.expand_sub h1 span {
  width: 80%;
}

.panel-heading.expand_hea {
  cursor: pointer;
}

.expand_sub h4.panel-title {
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.expand_sub_body {
  width: 100%;
}

.expand_sub h4::after {
  content: "\f107";
  right: 0;
  display: block;
  top: -7px;
  font-family: "FontAwesome";
  position: absolute;
  color: #000;
  font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.chk-payment-btn {
  margin-bottom: 10px;
}

.chk-payment-btn-row .btn {
  width: 100%;
  border: 0;
}

.chk-payment-btn-row .row {
  margin-right: 0;
  margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
  padding: 0;
}

.tnk-you {
  position: relative;
  padding: 110px 0;
}

.tnk-you::after {
  display: block;
  clear: both;
  content: "";
}

.tnk-header {
  max-width: 683px;
  margin: 0 auto;
  text-align: center;
}

.tnk-header .tick {
  padding: 0 15px;
}

.tick h2 {
  font-size: 64px;
  margin: 18px 0 0 0;
  text-transform: uppercase;
}

.tick span {
  font-size: 18px;
  color: #0b0405;
}

.tnk-detail {
  margin-top: 20px;
}

.tnk-detail h2 {
  padding: 13px 10px;
  color: #1c1c1c;
  text-transform: capitalize;
  background: #f5f5f5;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}

.tnk-order {
  background: #1c1c1c;
  padding: 40px 15px;
}

.tnk-order h3 {
  margin-bottom: 10px;
  font-size: 40px;
  text-transform: capitalize;
  color: #fff;
}

.tnk-order p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0;
}

.tnk-delivery .order-detail-inner {
  padding-top: 0px;
}

.tnk-delivery {
  padding: 35px 30px 0 30px;
  background: #fff;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
  display: none;
}

.tnk-delivery .order-detail-inner-icon {
  margin: 0 auto;
}

.tnk-delivery .delivery_total {
  margin: 6px 0 8px 0;
  display: flex;
  justify-content: space-between;
}
.tnk-delivery .delivery_total:first-child::before {
  content: "";
  width: 100%;
  position: absolute;
  background: #f5f5f5;
  height: 2px;
  top: 5px;
  z-index: 0;
}
.tnk-delivery .delivery_total .delivery_total_left {
  text-align: left;
  z-index: 10;
}
.tnk-delivery .delivery_total .delivery_total_left.delivery_total_right {
  text-align: right;
}
.tnk-delivery .delivery_total .delivery_total_left.delivery_total_center {
  text-align: center;
}
.tnk-delivery .cart_row {
  padding: 5px 0px 5px 0px;
}
.tnk-delivery .cart_row .row {
  margin-left: 0;
  margin-right: 0;
}

.tnk-delivery .cart_left .help-block {
  text-align: left;
}

.tnk-delivery .cart_info h4 {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.tnk-delivery .cart_extrainfo {
  text-align: left;
  padding-left: 15px;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
  padding: 20px 50px 30px 50px;
  margin-left: -30px;
  margin-right: -30px;
  background: transparent;
}

.tnk-delivery .tnk_cart_footer .cart_row {
  text-align: left;
  padding: 5px 0px 0px;
}
.tnk-delivery .cart_footer.tnk_cart_footer .grant-total-cls {
  border-top: 1px solid #dad9d9;
  margin-top: 10px;
}
.catering_receipt_popup .tnk-delivery .delivery_total:first-child::before {
  background: transparent;
}
.tnk-chk-order .btn {
  width: 100%;
  border: 0;
}

.delivery_datetime_div {
  padding: 10px 0 10px 0 !important;
  margin: 10px 0 0px 0 !important;
}

.orderitem_body_div {
  padding: 10px 0;
}

.orderitem_body_div .cart_row .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow:row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
}

.remark_notesec {
  margin: 20px 0 0;
  padding: 0 7px;
}

.remark_notesec h4 {
  margin: 0 0 3px;
  font-weight: 700;
  color: #3d3d3d;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.remark_notesec p {
  margin: 0;
}

.mfp-bg {
  z-index: 10042;
}

.mfp-wrap {
  z-index: 10043;
}

.white-popup {
  max-width: 780px;
  padding: 0;
  margin: 30px auto;
}
.order_popup {
  max-width: 780px;
}
.pouup_in {
  padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
  max-width: 450px;
}

.warning_popup .warning-popup-img {
  width: 75px;
  margin: 0 0 10px 0;
}

button.mfp-close,
button.mfp-arrow {
  margin: 0;
  padding: 0;
}

.alert_popup .mfp-close {
  display: none;
}

.alert_header {
  background: #181818;
  color: #fff;
  font-size: 20px;
  padding: 12px 14px;
  position: relative;
  text-align: center;
  font-family: "Poppins";
  text-transform: uppercase;
}

.alert_body {
  text-align: center;
  padding: 20px;
}

.alt_btns a {
  margin: 0 5px;
}

.alt_btns a.button-left {
  background: #282828;
}

.popup_equalrw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.popup_ttsec {
  width: 46%;
  min-height: 310px;
  padding: 20px 20px;
  min-height: 310px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pop_title {
  position: relative;
  color: #fff;
}

.pop_title h2 {
  font-size: 33px;
  margin: 0;
  line-height: 1;
  color: #383838;
}
.error_postal_popup .pop_title h2 {
  color: #fff;
  padding-top: 15px;
}
.error_postal_popup .pop_title small {
  color: #fff;
}

.pop_title small {
  font-size: 15px;
  font-weight: normal;
  color: #383838;
  font-weight: 700;
}

.popup_right {
  width: 54%;
  padding: 20px 40px 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.innervmid_in {
  width: 100%;
}

.delevery_popup .popup_ttsec {
  background: #f1f1f1;
}

.delevery_popup .popup_ttsec {
  padding-left: 38px;
  padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
  border: 1px solid #dadada;
  text-align: center;
  font-weight: 700;
  color: #000;
}

.two-button-row {
  display: flex;
  max-width: 476px;
  justify-content: space-between;
  margin: 0 auto;
}

.two-button-row > div .button {
  display: block;
  margin: 0;
  padding: 15px;
  width: 100%;
}

.two-button-row > div {
  width: 49%;
}
.go_second a {
  background: #383838;
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
  background: #383838;
}

.error_postal_popup .popup_right .button {
  margin: 0;
}
.delevery_popup_datetime .popup_right {
  text-align: center;
  padding: 40px 40px 40px 40px;
}

img.pop-scooter-img {
  max-width: 106px;
  margin-bottom: 15px;
}

.receipt_popup,
.receipt_cateringpopup {
  max-width: 683px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
  padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
  padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
  position: relative;
  margin-top: 0;
}
.receipt_popup .tnk-detail span {
  position: absolute;
    left: 10px;
    top: 10px;
    font-size: 18px;
    border-bottom: 1px solid #000;
    cursor: pointer;
}
.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
  margin: 30px 0;
}

.error_postal_popup .popup_ttsec {
  background: #FF1B2C;
}

.error_postal_popup h4 {
  color: #fff;
  font-size: 16px;
}

.poptt_icontop {
  padding-left: 0;
}

.poptt_icontop i {
  position: static;
  margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
  height: 309px;
}

.news_postal_popup .popup_ttsec {
  background: #b79a6c;
  width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
  background: url(../images/news_popupright.png) no-repeat;
  background-size: cover;
  width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
  font-size: 16px;
  margin: 6px 0 0 0;
}

.news_subscribe {
  position: relative;
  margin-top: 14px;
}

.news_subscribe .btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  z-index: 11;
  border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
  background: #54362c;
  color: #fff;
}

.news_subscribe .form-control {
  height: 51px;
  border: 1px solid #fff;
}

.news_subscribe p {
  font-size: 16px;
  color: #fff;
  margin-top: 12px;
  margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
  background: rgba(24, 24, 24, 0.5);
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 32px;
  line-height: 30px;
  opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 15px;
  height: 15px;
  background-position: -83px -190px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
  background: rgba(24, 24, 24, 0.9);
}

.order-body {
  text-align: center;
  padding: 64px 0;
  border: 1px solid #b79a6c;
}

.order-body h2 {
  color: #040507;
  font-size: 42px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.order-body p {
  color: #333;
  font-size: 16px;
  margin: 0 0 20px 0;
}

.order_delivery_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 10px 30px 0 30px;
}

.order_delivery_item li {
  width: 31.5%;
}

.order_delivery_item li a {
  display: block;
  position: relative;
  background: #e5e5e5;
  padding: 15px 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
.order_delivery_item li a.active,
.order_delivery_item li a:hover {
  background: #FF1B2C;
}
.header-ordernow-single-img {
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
.header-ordernow-single-img .order_type_imgwt {
  display: none;
}
.order_delivery_item li a.active .order_type_imgwt,
.order_delivery_item li a:hover .order_type_imgwt {
  display: block;
  margin: 0 auto;
}
.order_delivery_item li a.active .order_type_img,
.order_delivery_item li a:hover .order_type_img {
  display: none;
}
.order_delivery_item h3 {
  color: #000;
  font-size: 20px;
  margin: 15px 0 0 0;
  font-family: "melbourneregular";
  text-transform: uppercase;
}
.order_delivery_item li a:hover h3,
.order_delivery_item li a.active h3 {
  color: #fff;
}

.order_icon {
  display: block;
  text-align: center;
  margin: auto;
}

.order_delivery_item .tooltip {
  font-size: 16px;
}

.order_delivery_item .tooltip-inner {
  max-width: 260px;
}

.ordelivery_tooltip {
  position: relative;
}

.order_delivery_mobile_tooltip {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  left: -270px;
  top: 23px;
  background: #181818;
  padding: 10px;
  border-radius: 3px;
  width: 260px;
  margin: 0 auto 5px;
  z-index: 1;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
  opacity: 1;
  visibility: visible;
}

.order_delivery_mobile_tooltip:before {
  content: "";
  position: absolute;
  right: -16px;
  top: 50%;
  margin-top: -7px;
  border: 10px solid #181818;
  border-color: transparent transparent transparent #181818;
  border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
  left: auto;
  right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
  border-color: transparent #181818 transparent transparent;
  left: -16px;
  right: auto;
}

.order_delivery_item li.disable .ordericon_link {
  display: none;
}

.order_delivery_item li.disable:hover {
  background: #f9f9f9;
  border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
  display: none;
}

.orderpop_address {
  position: relative;
  height: 100%;
  padding-top: 35px;
}

.orderpop_address p {
  font-size: 12px;
  margin: 0;
}

.orderpop_continue_shop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.orderpop_continue_shop .btn {
  font-size: 11px;
  height: 30px;
  padding: 7px 15px;
  margin: 0;
}

.orderpop_change_address {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}

.orderpop_change_address:hover {
  color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
  height: 70px;
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
  background: #FF1B2C;
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  top: -15px;
  right: -15px;
  padding: 0;
  margin: 0;
  border: 1px solid #d83c53;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after,
.venue_popup .close-icon-but:after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 15px;
  height: 15px;
  background-position: -83px -190px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover {
  background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
  background: #d83c53;
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
  background: #1c1c1c;
}

.video_popup .order-body {
  padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
  width: 100%;
  background: #000000;
  padding: 30px 20px;
}

.awesome_popup .btn_sec {
  max-width: 500px;
  margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
  margin-bottom: 0;
  margin-top: 0;
  background: #fff;
  color: #000;
}

.awesome_popup .popup_ttsec .button:hover {
  background: #FF1B2C;
  color: #fff;
}

.awesome_del {
  margin-top: 13px;
  text-align: center;
}

.awesome_del h5 {
  font-size: 19px;
  margin-bottom: 10px;
  color: #fff;
}

.awesome_del h2 {
  color: #fff;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  line-height: 19px;
}

.awesome_right h4 {
  font-size: 16px;
}

.awesome_popup {
  max-width: 640px;
}

.datetime_selt_lbl,
.uper-light {
  font-weight: 700;
  padding-bottom: 10px;
  color: #a9a9a9;
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  font-family: "Poppins";
}
.uper-light {
  margin: 0;
}
.delevery_popup_datetime .btn_sec .button {
  display: block;
  margin: 0;
  width: 100%;
}

.white-popup.self_popup {
  max-width: 560px;
}

.self-back {
  position: absolute;
  top: 13px;
  font-size: 18px;
  color: #fff;
  z-index: 1;
  left: 40px;
  text-transform: uppercase;
}

.self-back:hover {
  color: #000;
}

.self_popup .order-body {
  padding: 0 0 0px 0;
}

.self_popup_hea_row {
  background: #FF1B2C;
  padding: 20px 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.self_popup_hea_col {
  text-align: left;
}

.order-body .self_popup_hea_row h2 {
  color: #fff;
}

.order-body .self_popup_hea_row p {
  margin-bottom: 0;
  font-size: 20px;
  color: #fff;
}

.self_popup_hea_col_left {
  padding-right: 15px;
}
.self_popup_hea_col_left img {
  width: 50px;
}

.self_pop_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
  width: 100%;
  margin: 0 auto;
}

.self_pop_col {
  width: 50%;
}

.self_pop_item_loc {
  position: relative;
}

.self_pop_item_map {
  box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
  border: 1px solid #bba075;
  height: 100%;
}

.selfpop_mapouter {
  height: 100% !important;
}

.or-seperator-row {
  position: absolute;
  right: -9px;
  z-index: 1;
  top: 6px;
}

.or-seperator-row .or-seperator {
  position: relative;
  padding: 25px 0px 7px 0;
  font-size: 16px;
  font-weight: 300;
}

.or-seperator-row .or-seperator span {
  padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
  height: 82px;
  width: 1px;
  background: #e5cda6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.self_pop_item_loc p {
  color: #595656;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 170px;
  margin: 0 auto;
  min-width: 288px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 23px;
  border-radius: 10px;
  height: 62px;
}

.loc_icon {
  width: 33px;
  height: 33px;
  background: url(../images/location_btn.png) no-repeat;
  margin-right: 10px;
}

.self_pop_col_right h4 {
  font-size: 16px;
  font-weight: 700;
  color: #595656;
  text-align: left;
  margin-left: 30px;
}

.self_outlet {
  margin-top: 15px;
  height: calc(100% - 105px);
}

.self_outlet h2 {
  color: #000000;
  font-size: 17px;
  text-align: left;
  margin: 0px 0 10px 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.self_outlet_inner {
  padding-left: 0;
  list-style: none;
  text-align: left;
  overflow-y: auto;
  margin: 0 0 14px;
  max-height: 190px;
}

.self_outlet_inner li {
  margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
  margin-bottom: 0;
}
.self_outlet_inner li a {
  background: #f8f8f8;
  padding: 10px 15px;
  display: block;
  font-size: 15px;
  color: #595656;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
  background: #1e1e20;
  color: #fff;
}

.self_outlet .btn.cnt_btn {
  width: 100%;
  background: #72624f;
  margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
  background: #333;
}

.self_pop_locbx .form-group .form-control {
  width: 100%;
}

.self_outlet .button {
  margin: 0;
  width: 100%;
  display: block;
  font-family: "Poppins";
}

.self_pop_locbx {
  margin: 0 0 20px 0;
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
  background: inherit;
  max-width: 820px;
}

.pop-whole-lhs-inner {
  text-align: center;
  max-width: 318px;
}

.pop-whole {
  position: relative;
}
.login-popup .pop-whole, .guest-popup .pop-whole {
  background: #fff;
  padding: 50px;
}
.guest-popup .pop-whole {
  padding: 50px 50px 35px;
}
.signup-popup .pop-whole {
  background: #fff;
  padding: 50px;
}

.login-popup .mfp-close,
.signup-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close,
.catering_receipt_popup.receipt_popup .mfp-close {
  width: 26px;
  height: 26px;
  line-height: 26px;
  right: 5px;
  top: 5px;
  font-size: 0;
  color: #fff;
  margin: 0;
  background: #000;
  opacity: 1;
  border-radius: 5px;
}

.login-popup .mfp-close:hover,
.signup-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover {
  background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after,
.signup-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after {
  content: "";
  background: url(../images/cross-symbol.png) no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.guest-popup .pop-whole-lhs p {
  margin: 5px 0 0;
}
.login-popup .resend_link {
  padding: 10px 0px;
  text-align: right;
}

.login-popup .resend_link a {
  color: #0dad85;
  font-weight: 600;
}

.popup-footer .btn {
  display: block;
  max-width: 271px;
  margin: 0 auto;
  background: #2d4486;
  text-transform: inherit;
  border-radius: 7px;
  border: 0;
}

.popup-footer .btn:hover {
  background: #333;
  color: #fff;
}

.popup-footer .btn i {
  padding-right: 15px;
}

.popup-footer span {
  color: #000;
  font-size: 16px;
  margin: 0 0 3px;
}

.popup-footer .guest_btn {
  background: #70afa7;
  color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
  background: #333333;
  color: #fff;
}

.pop-whole:after {
  display: table;
  clear: both;
  content: "";
}

.inside-popup-rhs {
  float: right;
  max-width: 390px;
  background: #fff;
  padding: 45px 35px 0px 35px;
  position: relative;
  z-index: 9;
  margin: 0 35px 0 0;
  width: 100%;
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
}

.signup-popup .inside-popup-rhs,
.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs {
  padding: 45px 30px 30px 30px;
}

.signup-corparatetwo-popup .inside-popup-rhs,
.guest-popup .inside-popup-rhs {
  padding: 60px 35px 40px 35spx;
}

.pop-whole-lhs {
  position: absolute;
  background: #ebebeb;
  padding: 30px 35px 30px 35px;
  left: 0;
  right: 0;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.forgot-password-popup .pop-whole-lhs {
  padding: 84px 50px 84px 60px;
}

.forgot-password-popup .pop-whole-lhs-inner {
  text-align: center;
  max-width: 100%;
}
.forgot-password-popup .pop-whol{
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  background: transparent;
}
.forgot-password-popup .pop-whole .pop-whole-lhs, .guest-popup .pop-whole .pop-whole-lhs {
  position: relative;
  padding: 40px;
  width: 100%;
  top: inherit;
  transform: inherit;
  left: inherit;
  right: inherit;
  max-width: 48%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.forgot-password-popup .pop-whole .inside-popup-rhs, .guest-popup .pop-whole .inside-popup-rhs {
  position: relative;
  float: inherit;
  padding: 40px;
  width: 100%;
  margin: inherit;
  max-width: 48%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #231f20;
}
.forgot-password-popup .pop-whole .inside-popup-rhs .popup-header {
  padding: 5px 0 10px 0;
}
.forgot-password-popup .pop-whole .inside-popup-rhs .popup-header h4 img {
  width: 200px;
  margin: 0 0 0 10px;
}
.forgot-password-popup .pop-whole .inside-popup-rhs .popup-body {
  width: 100%;
}
.forgot-password-popup .pop-whole .btn {
  margin-top: 5px;
}
.forgot-password-popup .pop-whole .two-links .open-popup-link {
  color: #000;
}
.signup-popup .pop-whole-lhs,
.guest-popup .pop-whole-lhs {
  padding: 104px 30px 104px 20px;
}
.signup-popup .pop-whole-lhs-inner,
.guest-popup .pop-whole-lhs-inner {
  max-width: 385px;
}

.pop-whole-lhs h3 {
  font-size: 36px;
  margin: 0;
  line-height: 1;
  color: #000000;
  text-transform: uppercase;
}

.pop-whole-lhs p {
  font-size: 14px;
  color: #000000;
  margin: 0;
  font-weight: 700;
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
  height: 20px;
  background-color: #000;
}

.loginsep-popup .mfp-close {
  right: 20px;
  top: -6px;
}

.popup-header {
  padding: 5px 0 5px 0;
}

.popup-header h4 {
  font-size: 19px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
  text-transform: uppercase;
}

.popup-header h4 img {
  width: 163px;
  margin: 0 0 0 10px;
}

.popup-header p {
  font-size: 13px;
  margin: 0px;
}

.popup-body input {
  margin: 0 0 8px;
  font-size: 16px;
  color: #595656;
}

.two-links {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  font-family: "Poppins";
  text-transform: capitalize;
  color: #000;
}
.space-line {
  padding-bottom: 25px;
}
.two-links .open-popup-link {
  color: #000;
  padding: 0 15px;
  font-family: "Poppins";
  font-weight: 500;
}
.two-links .btn-popup-active {
  background: #000;
  color: #fff;
  width: 50%;
  font-family: "Poppins";
  font-weight: 600;
  padding: 10px;
  box-shadow: 1px 1px 8px #0000001a;
}
.two-links .btn-popup-active:hover {
  color: #fff;
}
.two-links .btn-popup-inactive {
  width: 50%;
  background: #fff;
  color: #c3c3c3;
  font-family: "Poppins";
  font-weight: 600;
  padding: 10px;
  box-shadow: 1px 1px 8px #0000001a;
}
.two-links .btn-popup-inactive:hover {
  background: #000;
  color: #fff;
}

.two-links a {
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  color: #FF1B2C;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  font-family: "Poppins";
}

.two-links a:hover {
  color: #000;
}

.spilter {
  width: 2px;
  height: 15px;
  background: #FF1B2C;
  display: inline-block;
  content: "";
  vertical-align: top;
  margin: 1px 25px 0;
  position: relative;
  left: 0;
}

.or-seperator {
  position: relative;
  padding: 15px 0 7px 0;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
}

.or-seperator span {
  padding: 0 10px;
  background: #fff;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.or-seperator:after {
  height: 1px;
  width: 100%;
  content: "";
  display: block;
  background: #0e0e0e;
  position: absolute;
  top: 55%;
  left: 0;
}

.login_pop_sub .btn {
  width: 100%;
  background: #ff211f;
  font-family: "Poppins";
  font-weight: 600;
  text-transform: capitalize;
}
.login_pop_sub .login_submit_gray {
  background: #6a6a6a;
  text-transform: inherit;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-opt .span_line {
  background: #808080;
  width: 2px;
  height: 30px;
  margin-right: 15px;
}
.popup-opt .txt_uline a {
  display: inline-block;
  border-bottom: 1px solid #000;
  text-transform: inherit;
  margin: 0 0 11px 15px;
  font-size: 16px;
  color: #0e0e0e;
  font-family: "Poppins";
  font-weight: bold;
  text-decoration: none;
}
.login_pop_sub .login_submit_lign_gray {
  background: #808080;
  text-transform: inherit;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.login_pop_sub .login_submit_gray .fa-facebook {
  font-size: 24px;
  padding-right: 10px;
}

.guest_chk_out {
  margin-left: -55px;
  margin-right: -55px;
}

.guest_chk_out .btn {
  width: 100%;
  border: 0;
  margin-bottom: 0;
  color: #f1e0c6;
}

.guest_chk_out .btn b {
  color: #fff;
}

.guest_chk_out .btn:hover {
  color: #fff;
}

.popup-body h4, .popup-header h4 {
  text-transform: inherit;
  margin: 0 0 11px 15px;
  font-weight: 700;
  font-size: 16px;
  color: #0e0e0e;
  font-family: "Poppins";
  font-weight: bold;
}

.popup-body h4.your_lgn_spacing {
  margin-top: 19px;
}

.popup-body .custom_checkbox {
  width: 100%;
  margin: 7px 0 9px 0;
}

.popup-body .sign_reg {
  margin-left: 43px;
}

.popup-body .sign_reg a {
  width: 100%;
  color: #595656;
}

.popup-body .sign_reg a:hover {
  background: #333;
  color: #fff;
}

.popup-body .custom_checkbox input {
  border: 1px solid #e2e2e2;
  width: 27px;
}

.popup-body .custom_checkbox span:before {
  top: 11px;
  width: 27px;
  height: 27px;
  border: 2px solid #e7d8c1;
  border-radius: 2px;
}

.popup-body .custom_checkbox input[type="checkbox"]:checked + span:before {
  background-position: -30px -186px;
}

.popup-body .sign_reg p {
  margin-bottom: 9px;
  color: #0e0e0e;
  line-height: 18px;
  font-weight: 300;
}

.popup-body .custom_checkbox.custom_checkbox_content span:before {
  top: 0;
}

.popup-body .sign_reg a.sign_reg_uline {
  text-decoration: underline;
  color: #0e0e0e;
}

.popup-body .sign_reg a.sign_reg_uline:hover {
  color: #FF1B2C;
  background: inherit;
}

.row.pop-two-form {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.back_click {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.back_click a {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-left: 24px;
}

.back_click a:hover {
  color: #ff211f;
}
.back_click a::after {
  content: "";
  position: absolute;
  left: 0;
  width: 11px;
  height: 19px;
  background: url(../images/spirite_whole.png) no-repeat;
  background-position: -344px -67px;
  top: 2px;
}

.back_click a:hover::after {
  background-position: -375px -67px;
}

.changepw_popup {
  max-width: 768px;
}

.white-popup.processing {
  max-width: 768px;
}

.process_inner {
  position: relative;
}

.process_col {
  position: relative;
  margin-bottom: 15px;
}

.process_left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.process_right {
  padding-left: 60px;
}

.process_right h5 {
  color: #b0aeae;
  margin-bottom: 5px;
}

.process_right p {
  margin-bottom: 0;
  font-size: 18px;
}

.redeem_bansec {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.redeem_bansec .innerproduct-item-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
}

.redeem_popsec .pouup_in {
  padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
  font-size: 24px;
}

@-webkit-keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -25px);
    transform: translate(0px, -25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

.location-part-outer {
  padding: 0px !important;
  position: relative;
  overflow: hidden;
}

.location-part iframe {
  width: 100%;
  height: 680px;
}

.location-sidemenu {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 320px;
  height: 100%;
  z-index: 8;
  width: 100%;
}

.location-sidemenu .red-bar {
  background: #db4337;
  padding: 7px 40px;
}

.location-sidemenu .three-lines {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  width: 18px;
  height: 12px;
  background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px
    transparent;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 18px;
}

.location-sidemenu .three-lines:hover {
  background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
  position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
  margin: 0;
  height: 35px;
  border: none;
  padding: 7px 35px 7px 15px;
  background: transparent;
  color: #fff;
  outline: 0;
  font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
  color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
  color: #fff;
}

.loc-cont-part {
  position: absolute;
  width: 100%;
  left: 0;
  background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
  position: absolute;
  font-size: 0;
  right: 0;
  top: 0;
  margin: 0;
  width: 35px;
  height: 35px;
  padding: 5px;
  min-width: inherit;
  border: none;
  border-radius: 0;
  background: url(../images/search-icon.png) no-repeat scroll center center
    transparent;
}

.location-sidemenu .three-dots {
  width: 19px;
  height: 19px;
  background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px
    transparent;
  display: inline-block;
  position: absolute;
  right: 13px;
  top: 16px;
  cursor: pointer;
}
.outlet-gray {
  opacity: 0.4;
  filter: grayscale(1);
}
.outlet-list {
  max-height: 551px;
  overflow-y: auto;
}

.outlet-list > ul {
  padding: 20px 15px;
}

.outlet-list > ul > li {
  margin-bottom: 20px;
}

.outlet-list li {
  list-style: none;
  padding-left: 38px;
}

.outlet-list li span {
  display: block;
  font-weight: 700;
}

.outlet-list li .submenu {
  padding: 0;
}

.outlet-list li .submenu li {
  margin-top: 10px;
  list-style: none;
  padding: 0;
  line-height: normal;
  padding-left: 3px;
}

.outlet-list li .submenu li a {
  font-weight: 700;
  display: block;
  color: #272626;
  position: relative;
  padding-left: 14px;
  font-size: 11px;
}

.outlet-list li .submenu li a:hover {
  color: #ecdfcc;
}

.outlet-list li .submenu li a:before {
  position: absolute;
  left: 0;
  top: 0px;
  content: url(../images/location_small.png);
}

.outlet-list .custom_checkbox {
  position: absolute;
  left: 0;
  font-size: 0;
}

.outlet-list .custom_checkbox span:before {
  width: 16px;
  height: 17px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type="checkbox"]:checked + span:before {
  background-position: -216px -189px;
}

.mapinfo_trigger {
  background: red;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.mapinfo_canvas {
  background: #fff;
  width: 100%;
  max-width: 320px;
  position: absolute;
  left: -320px;
  top: -49px;
  bottom: 0;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 9;
}

.mapinfo_canvas.open {
  left: 0;
}

.mapcanvas_ttsec {
  background: #db4337;
  padding: 15.5px 20px 15.5px 49px;
  position: relative;
}

.mapcanvas_close {
  background: url(../images/left-arrow.png) no-repeat center;
  position: absolute;
  width: 49px;
  height: 49px;
  line-height: 49px;
  font-size: 24px;
  text-align: center;
  left: 0;
  top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
  position: absolute;
  padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
  display: none;
}

.mapcanvas_ttsec h3 {
  font-size: 14px;
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.mapcanvas_bx {
  padding: 12px 18px;
  color: #000;
}

.mapcanvas_bx h5 {
  color: #888;
  margin: 0;
  font-size: 13px;
}

.mainacc_menusec {
  margin: 0 0 40px;
}
.myacc-main-div {
  background: #f8f6e9;
}
.mainacc_menulist {
  padding: 0;
  list-style: none;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mainacc_menulist > li {
  width: 100%;
}

.mainacc_menulist > li > a {
  color: #181818;
  background: #e7e5d8;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 15px;
  display: block;
  position: relative;
  letter-spacing: 0.5px;
  text-align: left;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  z-index: 1;
}
.mainacc_menulist > li.active > a::after {
  content: "\f105";
  position: absolute;
  font-family: FontAwesome;
  font-size: 25px;
  right: 10px;
  line-height: 1;
}
.mainacc_menulist > li.active > a {
  background: #231f20;
  color: #edebec;
  cursor: pointer;
}
.mainacc_menulist > li.active > a:hover {
  background: #231f20;
  color: #edebec;
  cursor: pointer;
}
.mainacc_menulist > li > a:hover {
  background-color: #FF1B2C;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}
.mainacc_menulist > li > a:hover #masterCount {
  background: #FF1B2C;
}
.mainacc_menuout i.nav-btn {display: none;}
.re_select .custom-react-selector-box__menu {
  z-index: 99;
}
/* .mainacc_menulist > li >a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: -5px;
    background: #FF1B2C;
    height: 5px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
} */

.mainacc_menulist > li.active > a:before,
.mainacc_menulist > li > a:hover:before {
  left: 0;
  right: 0;
}

.mbacc_mslidersec {
  display: none;
}

.mainacc_moblink {
  display: none;
}

.myacc_hea {
  position: relative;
  margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
  display: block;
  content: "";
  clear: both;
}

.myacc_hea h3 {
  font-size: 27px;
  margin-bottom: 5px;
}

.myacc_hea p {
  margin-bottom: 25px;
  color: #5b5b5b;
}

.myacc_hea_inner {
  margin-top: 8px;
}

.box_in {
  background: #fff;
}

.account_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accmenu_sec {
  background: #e9eaec;
  width: 265px;
  display: inline-block;
  position: relative;
  color: #fff;
}

.accprofile {
  padding: 26px 10px 0px;
}

.accprofile_img {
  width: 140px;
  height: 140px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  line-height: 134px;
  border-radius: 50%;
  margin: 0 auto 0;
  overflow: hidden;
}

.accprofile_img img {
  vertical-align: middle;
}

.accprofile_info p {
  font-size: 15px;
  color: #000;
  font-weight: normal;
  margin: 0;
}

.accpoints_sec {
  padding: 15px 7px 0px;
}

.accpoint_tt span {
  background: #fff;
  padding: 0 4px;
  position: relative;
  display: inline-block;
}

.accearn_points {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px 15px 10px;
}

.accearn_pointtop {
  position: relative;
  padding-left: 52px;
}

.accearn_pointtop i {
  position: absolute;
  left: 0;
  top: 7px;
}

.accearn_pointtop h4 {
  font-size: 15px;
  margin: 0;
  color: #fff;
}

.accearn_pointtop span {
  font-size: 45px;
  display: inline-block;
  line-height: 0.8;
  color: #ffc800;
}

.accearn_pointtop sub {
  font-size: 60%;
  bottom: 0;
}

.accearn_points p {
  font-size: 13px;
  margin: 0;
}

.accpoint_ec {
  position: absolute;
  right: 13px;
  bottom: 8px;
}

.accsec_right {
  width: 100%;
  display: inline-block;
}

.accsec_right h4 {
  font-size: 16px;
  font-weight: 500;
  color: #040507;
  margin-bottom: 9px;
  margin-left: 0;
}
.accsec_right h4 span {
  display: block;
  font-size: 33px;
  font-weight: 600;
}
.accsec_right h4.form_grouptt {
  font-weight: 700;
}
.accsec_right p {
  color: #000000;
  font-size: 17px;
}

.acc-inform .form-group {
  margin-bottom: 11px;
}
.acc-inform input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
  border-radius: 0px;
}

.other-add-body {
  height: 175px;
  border: 1px solid #f1efef;
  border-radius: 7px;
  padding: 16px 14px 0px 14px;
  background: #fff;
}

.other-add-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

.other-add-row li {
  width: 32.1875%;
  margin-right: 1.6666666666666666%;
  position: relative;
  margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n + 3) {
  margin-right: 0;
}

.other-add-row li a {
  background: #f8f7ec;
  padding: 15px 15px;
  display: block;
  border: 1px solid #f1efef;
  color: #252525;
  font-size: 13px;
  font-weight: 600;
  border-radius: 7px;
  cursor: default;
}

.other-add-row span {
  position: absolute;
  top: 6px;
  right: 8px;
  background: url(../images/spirite_whole.png) no-repeat;
  width: 10px;
  height: 10px;
  background-position: -35px -315px;
  cursor: pointer;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.other-add-row span:hover {
  opacity: 0.5;
}

.setting_menu_list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.setting_menu_list > ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.setting_menu_list > ul li {
  position: relative;
}

.setting_menu_list > ul > li > a {
  padding: 12px 10px 12px 20px;
  color: #000;
  display: block;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  font-family: "Poppins";
  font-size: 16px;
}

.setting_menu_list > ul li a:hover {
  color: #FF1B2C;
}

.setting_menu_list > ul li:last-child a {
  border-bottom: 0;
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
  opacity: 1;
  visibility: visible;
}
.myacc_dropmenu {
  position: absolute;
  left: 105%;
  top: -20px;
  list-style: none;
  padding-left: 0;
  background: rgba(0, 0, 0, 0.77);
  padding: 30px 35px;
  z-index: 1;
  width: 284px;
  border-radius: 7px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
}

.myacc_dropmenu::after {
  position: absolute;
  content: "";
  border-right: 14px solid #3b3b3b;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -7px;
}

.myacc_dropmenu::before {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  background: #ccc;
  width: 20px;
  height: 100%;
  opacity: 0;
}

.myacc_dropmenu li a {
  color: #fff;
}

.myacc_dropmenu .tg-list {
  position: absolute;
  top: 0;
  right: -12px;
  padding-top: 0;
}

.main_tabsec_inner > .nav-tabs > li {
  width: 33.17%;
  margin-right: 0.24154589371980675%;
  position: relative;
}

.main_tabsec_inner > .nav-tabs > li:last-child {
  margin-right: 0;
}

.main_tabsec_inner > .nav-tabs {
  text-align: center;
}

.main_tabsec_inner > .nav-tabs > li > a {
  background: #bfbebe;
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner > .nav-tabs > li.active > a {
  border: 0;
  background: #0b0405;
  color: #fff;
}

.main_tabsec_inner > .nav-tabs > li > a > span > b {
  font-weight: normal;
}

.count_num_t {
  background: #a7a7a7;
  width: 70px;
  height: 100%;
  text-align: center;
  font-style: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  color: #0b0405;
  font-size: 20px;
  border-top-left-radius: 3.2em 5em;
  border-bottom-left-radius: 3.2em 5em;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn.spirite_image {
  height: 56px;
}

.spirite_image {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.spirite_image i {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

.filter_tabsec .nav-tabs {
  width: 100%;
  padding: 0;
}

.filter_tabsec .nav-tabs > li {
  width: 50%;
  float: left;
  border-bottom: 5px solid #fff;
  position: relative;
}

.filter_tabsec .nav-tabs > li > a span {
  display: inline;
  font-weight: 600;
}

.filter_tabsec .nav-tabs > li > a:after {
  height: 5px;
  bottom: -5px;
  background: #fd2322;
  max-width: 253px;
  margin: auto;
  width: 0%;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.ord-body::after {
  display: block;
  clear: both;
  content: "";
}

.ord-pagination-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0 0 10px 0;
}

.ord-pagination-col::after {
  display: block;
  content: "";
  clear: both;
}

.ord-pagination-col span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.ord-pagination-col p {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: bold;
}

.ord-pagination-col .form-group {
  margin-bottom: 0;
  padding-right: 5px;
}

.ord-pagination-col .form-group select {
  background: #d7d4d8;
  border: 0;
  font-weight: bold;
}

.cur-order-body {
  width: 100%;
  clear: both;
}

.cur-order-body::after {
  display: block;
  clear: both;
  content: "";
}

.cur-order {
  padding: 10px 0;
  border-bottom: 1px solid #efefee;
  position: relative;
}

.click_view {
  position: absolute;
  right: 21px;
  color: #474747;
  padding: 0;
  font-weight: 700;
  font-size: 12px;
}

.cur-order-del {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 450px;
  list-style: none;
  padding-left: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.cur-order-del li {
  font-weight: 700;
  font-size: 16px;
  position: relative;
  padding-left: 20px;
}

.cur-order-del li a.btn {
  min-width: 132px;
  height: 35px;
  border-radius: 10px;
  font-size: 16px;
  padding: 8px 5px;
  color: #0b0405;
  line-height: normal;
  margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
  color: #fff;
}

.cur-order-del li span {
  padding-left: 5px;
}

.cur-order-del-icon {
  background: url(../images/spirite_whole.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.calender-icon {
  background-position: -215px -258px;
}

.time-icon {
  background-position: -249px -257px;
}

.cur-ord-rec {
  list-style: none;
  float: right;
  max-width: 400px;
  padding-right: 19px;
  margin-bottom: 0;
  padding: 8px 19px 0 0;
}

.cur-ord-rec li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cur-ord-rec li a {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #2e2e2c;
}

.cur-ord-rec li a:hover {
  color: #909090;
}

.cur-ord-rec li span {
  display: inline-block;
  width: 1px;
  height: 100%;
  background: #2e2e2c;
  margin: 0 20px;
  position: relative;
}

.cur-ord-rec li span::after {
  content: "";
  background: #2e2e2c;
  height: 14px;
  width: 1px;
  display: block;
  position: absolute;
  top: 2px;
}

.order-cat-hea {
  background: #fff;
  padding: 8px 35px;
  max-width: 331px;
  margin-bottom: 10px;
}

.order-cat h2 {
  font-size: 20px;
  color: #0b0405;
  margin-bottom: 0;
}

.order-cat-table {
  padding: 0 35px;
}

.order-cat-table h4 {
  color: #474747;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 11px;
}

.order-cat-table table {
  border: 0;
  max-width: 364px;
  margin-bottom: 10px;
}

.order-cat-table table td {
  font-weight: 300;
  font-size: 16px;
  color: #3d3d3d;
  padding: 0 5px;
}

.order-cat-table table td.p_name {
  padding-left: 0;
}

.p_name {
  width: 247px;
}

.p_val {
  width: 80px;
}

.ord-amount {
  padding: 10px 0 0 35px;
  border-top: 1px solid #efefee;
}

.ord-amount .row {
  margin-right: -20px;
}

.ord-amount-item {
  max-width: 364px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ord-amount h2 {
  font-size: 27px;
  color: #202020;
}

.ord-rec-sec {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: right;
  float: right;
  width: 649px;
}

.ord-rec-sec li {
  color: #aba9a9;
  margin-bottom: 0;
  height: 39px;
  padding: 10px 15px;
  max-width: 184px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  width: 25%;
  line-height: 23px;
  overflow: hidden;
}

.ord-rec-sec li.completed {
  background: #ecdfcc;
  color: #3d3d3d;
}

.ord-number {
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  font-weight: 700;
  margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
  color: #fff;
  background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
  padding: 6px 72px 50px 72px;
}

.congrats::after {
  display: block;
  content: "";
  clear: both;
}

.congrats-inner {
  background: #F0F0F0;
  margin: 0 auto;
  text-align: center;
  padding: 40px 15px;
}

.congrats-inner h3 {
    font-size: 20px;
    color: #231f20;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

.congrats-inner h2 {
  font-size: 42px;
  color: #231f20;
  margin-bottom: 0;
}

.rewards-tab-section .ord-amount {
  border-top: inherit;
  padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
  margin-bottom: 7px;
}

.earned_points {
  max-width: 300px;
  padding-left: 0;
  list-style: none;
  float: right;
  padding-right: 24px;
}

.earned_points li span {
  color: #bfbebe;
  text-transform: uppercase;
  font-size: 18px;
  display: inline-block;
  margin-right: 5px;
}

.earned_points li h2 {
  color: #b69a6b;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.redeem_popsec {
  max-width: 620px;
  border-radius: 0;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
  margin: 50px auto;
}
.mfp-close-btn-in .receipt_popup.redeem_popsec .mfp-close {
  top: 10px;
  right: 10px;
  background: #000 !important;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
  width: 25%;
  height: 270px;
}
.promo-popup-image {
  border-top-left-radius: 10px;
  overflow: hidden;
  border-top-right-radius: 10px;
}
.promo-popup-image img {
  width: 100%;
  max-height: 250px;
}
.redeem_popsec .promo-earned-content {
  width: 100%;
  padding: 20px 20px 20px;
  text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
  font-size: 34px;
  max-height: inherit;
  margin: 0 0 5px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.redeem_popsec .promo-earned-content h2 {
  font-size: 42px;
  color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
  margin: 7px 0 0;
  font-size: 14px;
}

.redeem_popsec .abc_code {
  padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
  font-size: 26px;
}
.redeem_popsec .promo-earned-content span.promo-valid {
  padding: 0 0 5px 0;
}

.redeem_pop_image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.redeem_popsec .pouup_in {
  padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
  font-size: 16px;
  color: #000;
  line-height: 24px;
}

.redeem_popsec .btn {
  position: absolute;
  background: #282828;
  color: #fff;
  display: block;
  border-radius: 0;
  text-align: center;
  font-family: "Poppins";
  text-transform: capitalize;
  line-height: 1;
  padding: 13px 20px;
  height: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
}

.redeem_popsec .btn:hover {
  background: #FF1B2C;
  color: #fff;
}
.promocode-redmee {
  position: relative;
  margin-top: 20px;
}
.promocode-redmee .promocode-redmee-tag {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='2%2c 9' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e");
  display: inline-block;
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 34px;
  font-weight: 600;
}
.redeem_popsec .mfp-close {
  background: #d72222 !important ;
  border-radius: 7px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  top: 7px;
  right: 7px;
}

.redeem_popsec .mfp-close:after {
  background: url(../images/cross-close.png) no-repeat;
  background-size: contain;
  background-position: center;
}

.redeem_popsec .mfp-close:hover {
  background: #000;
}

.input_area {
  position: relative;
}

.input_area {
  position: relative;
}

.cal_icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 100%;
  width: 45px;
  pointer-events: none;
}

.cal_icon b {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  text-transform: inherit;
  border-radius: 0px;
  border: 0;
  width: 100%;
  background: #6A6A6A;
  padding: 13px 15px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.pop-whole .kep-login-facebook.metro i {
  font-size: 23px;
}
.pop-whole .kep-login-facebook.metro:hover {
  background: #333;
}

.combo-input-label {
  height: 100%;
  border: none;
  color: #000;
  font-size: 15px;
  text-align: center;
  margin: 0;
  border: 2px solid #e9e9e9;
  background: #fff;
  border-radius: 5px;
  font-weight: bold;
  padding: 11px 2px;
  line-height: 12px;
}

.compo_acc_innerdiv {
  padding: 10px 20px 0px 20px;
}

.gloading_img {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9;
}

.gloading_img:after {
  content: "";
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 1s linear infinite;
  animation: loader-rotate 1s linear infinite;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.load_warpper {
  position: relative;
}

.load_warpper #dvLoading {
  position: absolute;
}

#dvLoading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  margin: 0;
  background: #fff url(../images/loading.gif) no-repeat center;
  background-size: 60px;
  -webkit-background-size: 60px;
  bottom: 0;
  right: 0;
}

#inner_loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif)
    no-repeat center;
  bottom: 0;
  right: 0;
}

.popup_close {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #fff;
  opacity: 1;
  padding: 10px;
  border-radius: 5px;
  width: 40px;
  z-index: 1;
}

.back_arrow {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 43px;
  height: 49px;
  text-align: center;
  line-height: 49px;
  cursor: pointer;
  z-index: 4;
}

.back_arrow:after {
  content: "";
  background: url(../images/spirite_whole.png) no-repeat;
  width: 26px;
  height: 26px;
  background-position: -160px -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 15px;
  background: #523429;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  cursor: pointer;
  z-index: 111;
}

.scrollup:hover {
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
  margin: 0px 0 30px;
}

.cms-page .container {
  background: transparent;
  padding: 40px 20px 30px;
}

.cms-page h3 {
  font-size: 27px;
  color: #040507;
}

.contactus_wrapper {
  background: url('../images/contact-us-background.jpg') no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.contactus_wrapper .contactus_flex {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ff1b2c1f;
} 
.contactus_wrapper .contactus_share {
  position: relative;
  flex: 1 1 40%;
  max-width: 40%;
  min-width: 150px;
}
.contactus_wrapper .contactus_share .contatus_inner_share {
  position: relative;
  width: 100%;
}
.contactus_wrapper .contactus_share .contatus_inner_share h1 {
  font-size: 85px;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  color: #ffffe4;
}
.contactus_wrapper .contactus_form {
  position: relative;
  flex: 1 1 50%;
  max-width: 50%;
  min-width: 150px;
}
.contactus_wrapper .white_bgbx {
  background: #fff;
  padding: 35px 25px 35px 0;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  display: inline-flex;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.contactus_wrapper .contact_social .contact_social_row::before {
  content: '';
  width: 1px;
  position: absolute;
  display: block;
  height: 100%;
  left: 50%;
  border-left: 1px dashed #231f20;
  top: -80px;
}
.contactus_wrapper .contact_social .contact_social_row::after {
  content: '';
  width: 1px;
  position: absolute;
  display: block;
  height: 100%;
  left: 50%;
  border-left: 1px dashed #231f20;
  top: 80px;
}
.contactus_wrapper .contactus_form .contact_social {
  position: relative;
  flex: 1 1 10%;
  max-width: 10%;
  min-width: 100px;
  text-align: center;
  z-index: 99;
}
.contactus_wrapper .contactus_form .contact_social .contact_social_item {
  position: relative;
  display: block;
  width: 100%;
  z-index: 100;
}
.contactus_wrapper .contactus_form .contact_social .contact_social_item a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  background: #231f20;
  color: #fff2d8;
  margin: 15px 0;
  width: 35px;
  height: 35px;
  border-radius: 1px;
}
.contactus_wrapper .contactus_form .contact_social .contact_social_item a:hover {
  background: #000000;
}
.contact_form .form-control {
  border: none;
  border-bottom: 1px solid #231f20;
}
.contact_form .form-group {
  margin: 0 0 25px;
}
.contact_form .btn_sec {
  margin: 50px 0 5px;
  text-align: center;
}
.contactus_wrapper .contactus_form .contact_social .contact_social_item a i {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: normal;
}
.contactus_wrapper .contactus_form .contact_social .contact_social_item a img {padding: 7px;}
.contactus_wrapper .contactus_form .contact_form {
  position: relative;
  flex: 1 1 80%;
  max-width: 85%;
  min-width: 150px;
}
.contactus_wrapper .contactus_form .contact_form h3 {
  font-size: 30px;
}
.contact_left p a {
  color: inherit;
}

.contact_left p a:hover {
  color: #b69a6b;
}

.contact_left p.open-hours {
  border-top: 1px solid #eaeaea;
  padding-top: 25px;
  margin-top: 26px;
}

.contactus_pg .contact_form h3 {
  margin: 0 0 5px;
}

.contact_form .help-block {
  margin: 0 0 24px;
}

.product_tag {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 5px 0 10px;
}

.product_tag li {
  padding-right: 10px;
}

.product_tag li b {
  margin-right: 10px;
}

.product_tag li a {
  display: inline-block;
  color: #5b5b5b;
  font-weight: 700;
}

.chosen_file {
  position: relative;
  display: inline-block;
}

.chosen_file input[type="file"] {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.chosen_file label {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 11.5px 15px;
  outline: 0;
  background: #ecdfcc;
  max-width: 160px;
  border: 1px solid #e6e5e5;
  text-overflow: ellipsis;
  margin: 0;
  pointer-events: none;
}

.catering .ttsplitter span {
  background: #fff;
}

.catering .ttsplitter {
  margin: 0 auto 20px;
}

.order_nav {
  position: relative;
}

.order_nav > .nav-tabs {
  position: relative;
  border: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

.order_nav > .nav-tabs > li {
  margin: 0;
  text-align: center;
  border-left: 2px solid transparent;
}

.order_nav > .nav-tabs > li.active > a,
.order_nav > .nav-tabs > li.active > a:focus,
.order_nav > .nav-tabs > li.active > a:hover {
  border: 0;
  background: #FF1B2C;
  color: #fff;
}

.order_nav > .nav-tabs > li > a {
  background: #eeeeed;
  margin: 0px 1px;
  border-radius: 0;
  border: 0;
  color: #181818;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 23px;
  font-family: "melbourneregular";
  display: flex;
  align-items: center;
}

.nos {
  background: #473f3c;
  border-radius: 100%;
  padding: 2px 2px;
  margin-right: 7px;
  color: #fff;
  border: 3px solid #0b0405;
  width: 32px;
  height: 32px;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  font-weight: bold;
  line-height: 22px;
}

.order_nav .tb-padding {
  padding: 60px 0;
}

.container_720 {
  margin: 0 auto;
  max-width: 720px;
}

.choose_location {
  position: relative;
  border: 2px solid #dddddd;
  border-radius: 5px;
  padding: 50px 40px 30px;
  margin-bottom: 50px;
  background: #fff;
}

.chosen-alert-cnt {
  position: absolute;
  bottom: -27px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.chosen-alert-cnt span {
  background: #fb0a37;
  padding: 6px 15px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
  -webkit-box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
}

.choose_location h4 {
  color: #3d3d3d;
  font-size: 19px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.choose_location .custom_radio span {
  color: #3d3d3d;
  font-size: 18px;
  line-height: 35px;
  padding: 0 0 0 42px;
  font-weight: bold;
}

.choose_location .custom_radio span:before {
  width: 32px;
  height: 32px;
}

.choose_location [type="radio"]:checked,
[type="radio"]:not(:checked) {
  left: auto;
  opacity: 0;
}

.choose_location .custom_radio input[type="radio"]:checked + span:before {
  background: #72624f;
  background-position: -399px -125px;
  z-index: 1;
  border: 1px solid #120b0c;
}

.divide {
  width: 90%;
  background: #c7c3c3;
  height: 2px;
  margin: 10px 0px 10px 40px;
}

.date_list {
  margin-bottom: 30px;
  position: relative;
  padding: 40px 120px 30px;
  border: 2px solid #dddddd;
  background: #fff;
  border-radius: 6px;
}

.date_list:after {
  content: "";
  display: table;
  clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
  text-align: center;
  font-size: 26px;
  margin: 0 0 20px 0;
  font-family: "melbourneregular";
}

.ordering-more {
  text-align: center;
}

.ordering-more .button.no {
  background: #000;
}

.ordering-more .button.yes {
  background: #FF1B2C;
}
.more-time-slots {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 476px;
  margin: 0 auto;
}
.more-time-slots a {
  width: 49%;
  margin: 0;
  padding: 17px 5px 16px;
  border-radius: 5px;
  font-size: 19px;
}

#nobtn_id {
  margin-right: 5px;
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
  background: #333;
}

.ordering-more h3 {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
  font-family: "Poppins", sans-serif;
}

.date_list h4 {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
  font-family: "Poppins", sans-serif;
}

.venue-details-inner h3 {
  display: block;
  font-size: 14px;
  color: #3c3a3a;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-left: 5px;
}

.catering_days_ul:after {
  content: "";
  display: table;
  clear: both;
}

.catering_days_ul {
  list-style: none;
  margin: 0 -2px;
  padding-left: 0;
  padding-bottom: 0;
}

.catering_days_ul li {
  padding: 0 2px;
  width: 20%;
  float: left;
  margin-bottom: 5px;
  height: 70px;
}

.catering_days_ul li dl {
  padding: 12px 5px;
  background: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
  height: 70px;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.catering_days_ul li dl.active {
  background: #98684a;
  border-color: #000000;
}

.catering_days_ul li dl.active dt {
  color: #fff;
}

.catering_days_ul li dl.active dd {
  color: #fff;
}

.catering_days_ul li dl:hover {
  background: #98684a;
}

.catering_days_ul li dl:hover dt {
  color: #fff;
}

.catering_days_ul li dl:hover dd {
  color: #fff;
}

.catering_days_ul li dl.cl-next-days:hover span {
  color: #000;
}

.catering_days_ul li dl.cl-next-days {
  background: #a1a1a1;
}

.catering_days_ul li dl.cl-next-days span {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Poppins";
  text-transform: uppercase;
  display: block;
}

.catering_days_ul li dl dt {
  font-size: 14px;
  text-transform: uppercase;
  color: #9d9d9d;
  line-height: 14px;
  font-family: "Poppins";
  margin: 0 0 5px 0;
}

.catering_days_ul li dl dd {
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  color: #3c3a3a;
  line-height: 1;
  font-family: "Poppins";
}

.catering_days_ul .tag {
  color: #fff;
  background: #060606;
  padding: 5px 5px;
  position: absolute;
  left: -10px;
  top: 20px;
  transform: rotate(-18deg);
  -webkit-transform: rotate(-18deg);
  width: 120%;
  line-height: normal;
  text-align: center;
  height: 27px;
  font-size: 12px;
  font-family: "Poppins";
  text-transform: uppercase;
}

.catering_days_ul li dl.time_inner dd {
  font-size: 18px;
}

.res-datetime-picker p {
  margin: 15px 0px 40px;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
}

.res-datetime-picker .tag span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  text-transform: uppercase;
}

.time_slot {
  margin-bottom: 10px;
  position: relative;
  padding: 30px 120px 30px;
  border: 2px solid #ecdfcc;
  background: #ffffff;
  text-align: center;
  border-radius: 6px;
}

.time_slot .order-btn {
  padding: 20px 0px 30px;
}

.time_slot .order-btn .no {
  background: #626161;
  height: 55px;
  font-size: 22px;
  padding: 10px 80px;
  border-radius: 4px;
}

.time_slot .order-btn .yes {
  background: #72624f;
  height: 55px;
  font-size: 22px;
  padding: 10px 80px;
  border-radius: 4px;
}

.time_slot h4 {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 17px;
  margin: 0px;
}

.time_slot .add_time .label {
  color: #3d3d3d;
  font-size: 18px;
  margin: 0px;
  padding: 0px 0px;
  width: 240px;
  float: left;
  text-align: right;
  line-height: 38px;
  height: 38px;
}

.add_time {
  margin: 10px 30px 45px 0px;
}

.time_slot .time {
  padding: 0px 0px 20px 0px;
}

.time_slot .time:after {
  content: "";
  display: table;
  clear: both;
}

.time_slot .time label {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 17px;
  padding: 0px 0px 0px;
  text-transform: uppercase;
  line-height: 40px;
  margin: 0px;
}

.time_slot .time .form-control,
.order-select-brktime ul .time-cls .breaktime_listcls {
  height: 38px;
  padding: 6px 0px;
  border: 0;
}

.order-select-brktime ul .custom-select-inner {
  padding: 0 10px 0 40px;
  background: #fff;
  width: 180px;
  height: 38px;
}

.order-select-brktime ul .time-cls .form-group:after {
  height: 38px;
  top: 0;
  right: 0;
}

.order-break {
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.order-break .label {
  font-size: 17px;
  font-weight: bold;
  margin: 0 10px 0 0;
}

.catering_hall_list {
  padding: 30px 0 10px;
}

.catering_hall_list p {
  font-size: 17px;
  margin: 0px;
}

.catering_hall_list .form-group {
  margin: 25px 0 25px;
}

.function-rooms ul {
  margin: 0;
  padding: 20px 0 0 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.function-rooms ul li {
  width: 32%;
  margin: 0 2% 20px 0;
  text-align: center;
}

.function-rooms ul li:nth-child(3n) {
  margin-right: 0;
}

.view-rooms h4 {
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
}

.function-rooms ul li a:hover h4,
.function-rooms ul li.active h4 {
  color: #fff;
}

.view-rooms span {
  color: #4e4e4e;
  display: block;
  clear: both;
  position: relative;
}

.function-rooms ul li a:hover span,
.function-rooms ul li.active span {
  color: #fff;
}

.function-rooms ul li a {
  height: 100%;
  padding: 5px 5px 15px;
  background: #e0e0e0;
  display: block;
}

.function-rooms ul li a:hover,
.function-rooms ul li.active a {
  background: #FF1B2C;
}

a.view-rooms img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 0 15px 0;
}

.show {
  display: block;
}

.cedele-location label {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 16px;
  line-height: 35px;
}

.location-list {
  height: 270px;
  margin: 0px -15px 0px 0px;
}

.location-list ul {
  list-style: none;
  margin: 0px 15px 0px 0px;
  padding: 0px;
}

.location-list ul li {
  font-weight: bold;
  color: #595656;
  font-size: 16px;
  background: #f8f8f8;
  border-radius: 7px;
  margin-bottom: 5px;
  height: 50px;
  padding: 0px 20px;
}

.location-list ul li:hover {
  color: #ffffff;
  background: #72624f;
}

.location-list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #FF1B2C;
}

.no-padding {
  padding: 0px;
}

.continue a {
  width: 100%;
  display: block;
  margin: 0;
  font-size: 20px;
}

.ownvenue-inner-div {
  max-width: 350px;
  margin: 40px auto 0;
}

.errorInfo-div {
  display: none;
  background: #ef1616;
  padding: 5px;
  color: #fff;
}

.event-type-maindiv.two-button-row a {
  border-radius: 4px;
  font-size: 19px;
  padding: 17px 10px 16px;
  background: #FF1B2C;
}
.event-type-maindiv.two-button-row .active a {
  background: #000;
}

.hall-select-popup {
  background: #000;
  padding: 60px 50px 50px;
  width: auto;
  margin: 20px auto;
  border: 1px solid #FF1B2C;
  -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.23);
  text-align: center;
  color: #fff;
  max-width: 580px;
  position: relative;
}

.hall-select-popup .pop-title {
  font-size: 26px;
  text-transform: uppercase;
  line-height: 1;
  margin: 5px 0 10px;
  color: #fff;
}

.hall-select-popup p {
  margin: 0;
  font-weight: bold;
}

.hall-select-popup .button {
  width: 100%;
  display: block;
  margin: 20px 0 0 0;
  background: #FF1B2C;
  font-family: "Poppins";
}
.hall-select-popup .button:hover {
  background: #fff;
  color: #000;
}

.hall-description {
  padding: 10px 0;
}

.hall-select-popup .mfp-close-btn-in .mfp-close {
  color: #fff;
  opacity: 1;
}

h3.event-datetime-string {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 0 0;
}

.ccategories_tabsec {
  padding: 26px 0 330px;
}

.number-break {
  padding: 28px 28px 28px 94px;
  margin: 0px 0px 40px;
  position: relative;
  background: #f9f7f8;
}

.number-break .back_bx {
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.back_bx {
  background: #181818;
  width: 68px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  position: relative;
  border-radius: 5px;
  padding: 10px 10px 12px;
  margin: 0;
  cursor: pointer;
  font-family: "Poppins";
}

.back_bx:before {
  font-family: FontAwesome;
  font-size: 40px;
  content: "\f104";
  width: 100%;
  line-height: 1;
  display: block;
}

.back_bx:hover {
  background: #FF1B2C;
  color: #fff;
}

.number-break h2 a {
  margin: 0;
  position: absolute;
  bottom: 5px;
  content: "";
  left: 0;
  right: 0;
  color: #FF1B2C;
}

.break-time {
  width: 31%;
  padding: 5px;
  border-right: 1px solid #cfbfaa;
  text-align: center;
}

.number-break {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.break-time-removed {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 19px;
}

.break-time .title4,
.making-catering .title4 {
  margin-bottom: 10px;
}

.making-catering {
  width: 36%;
  float: left;
  padding: 5px 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.17);
}

.making-catering p {
  margin: 0;
}

.making-place {
  font-weight: bold;
  font-size: 16px;
}

.making-place.making-place-padding {
  display: block;
}

.change-location a {
  text-decoration: underline;
  font-size: 17px;
  color: #000;
}

.change-location a:hover {
  color: #FF1B2C;
}

.event-time {
  width: 33%;
  padding: 5px 0px 5px 20px;
}

.event-time .title4 {
  margin-bottom: 3px;
}

.event-time .row {
  margin: 0 -4px;
}

.event-time [class*="col-"] {
  padding: 0 4px;
}

.event-time .form-group {
  margin: 0;
}

.event-time .form-control {
  height: 57px;
}

.order-select-brktime ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.order-select-brktime ul .time-cls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
  margin: 0;
  position: relative;
  border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
  width: calc(100% - 200px);
  font-size: 18px;
  font-weight: bold;
  color: #020202;
  text-transform: uppercase;
}

.viewmenu-top-div {
  margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
  padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
  font-size: 30px;
  top: 5px;
}

.break-time-removed .event-time .title4,
.break-time-removed .making-catering .title4,
.break-time-removed h3.event-datetime-string {
  color: #000;
  font-size: 20px;
}

.break-time-removed .making-catering {
  width: 50%;
}

.break-time-removed .event-time {
  width: 50%;
}

.row-pgk-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row-pgk-list > li {
  width: 33.33333333%;
  border: 7px solid #fff;
  position: relative;
  overflow: hidden;
}

.pkselect_tabsec > .nav-tabs {
  border-bottom: 4px solid #000000;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pkselect_tabsec > .nav-tabs > li {
  margin: 0;
}

.pkselect_tabsec > .nav-tabs > li > a {
  background: #d2d0d1;
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #000000;
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 13px 38px;
  display: block;
}

.pkselect_tabsec > .nav-tabs > li.active > a {
  border-radius: 5px 5px 0 0;
}

.pkselect_tabsec > .nav > li > a:focus {
  background: none;
}

.pkselect_tabsec > .nav-tabs > li.active > a,
.pkselect_tabsec .nav-tabs > li.active > a:focus,
.pkselect_tabsec .nav-tabs > li.active > a:hover {
  border: 0;
  background: #000;
  color: #fff;
}

.pkselect_tabin {
  position: relative;
  padding: 20px 0 0;
}

.pkselected_filtersec {
  margin: 0 0 15px;
}

.pkselected_filtersec label {
  display: inline-block;
  font-size: 18px;
  color: #010101;
  padding-right: 16px;
  vertical-align: middle;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.pkselected_filtersec .form-group {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.pkselected_filtersec .form-control {
  height: 48px;
  margin-bottom: 0;
}

.pkselected_package label {
  position: relative;
  padding: 15.5px 26px 15.5px 0px;
}

.pkselected_package label i {
  font-size: 45px;
  position: absolute;
  left: 20px;
  top: 11px;
  line-height: 0.83;
}

.pkselected_package .form-group {
  min-width: 360px;
}

.pkselected_package .chosen-container-single .chosen-single {
  height: 56px;
  line-height: 56px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 0 0 25px;
}

.pkselected_package .chosen-container-single .chosen-single span {
  margin-right: 64px;
}

.pkselected_package .chosen-container-single .chosen-single div {
  width: 64px;
}

.pkselected_break .form-group {
  min-width: 245px;
}

.pkg_gimg,
.pkg_gitem {
  position: relative;
  height: 100%;
}

.pkg_gimg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.pkg_ginfo {
  background: rgba(0, 0, 0, 0.25);
  padding: 45px 15px 45px;
  color: #fff;
  line-height: 1.4;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.pkg_ginfo.catbox-bottom-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}

.row-pgk-list > li:hover .pkg_ginfo,
.pkg_gitem.category-active .pkg_ginfo {
  visibility: visible;
  opacity: 1;
}

.pkg_ginfo h4 {
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 9px;
  color: #fff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.row-pgk-list > li:hover .pkg_ginfo h4,
.pkg_gitem.category-active .pkg_ginfo h4 {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.pkg_ginfo p {
  margin: 0;
}

.row-pgk-list > li:hover .pkg_ginfo p,
.pkg_gitem.category-active .pkg_ginfo p {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.pkselected_catgrytext {
  text-align: center;
}

.pkselected_catgrytext .title4 {
  font-size: 14px;
  text-transform: inherit;
}

.pkgselect_dd {
  text-align: left;
  display: none;
}

.pkgselect_dd .chosen-container {
  font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single {
  background: #ecdfcc;
  border-color: #d0cfcb;
  border-radius: 2px;
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single span {
  font-weight: 300;
  color: #41403e;
}

.pkgselect_dd .chosen-container-single .chosen-drop {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #f1ece6;
  background: #f6f4f4;
}

.pkgselect_dd .chosen-container .chosen-results li {
  padding: 15px 18px;
  border-top: 1px solid #f1ece6;
}

.pkgselect_dd .chosen-container .chosen-results li:first-child {
  border-top: 0;
}

.pkgselect_dd .chosen-container .chosen-results .highlighted {
  color: #41403e;
}

.pkg_gbtsec .button {
  font-size: 17px;
  width: 100%;
  margin: 0;
  padding: 14px 5px;
  background: #98684a;
  font-family: "Poppins";
}

.pkg_gbtsec .button:hover {
  background: #FF1B2C;
}

.pkg_gitem.highlighted .pkg_ginfo {
  background: rgba(11, 4, 5, 0.82);
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
  display: none;
}

.pkg_gitem.highlighted .pkg_ginfo .pkgselect_dd {
  display: block;
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
  opacity: 0;
  pointer-events: none;
}

.catbox-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.row-pgk-list > li:hover .catbox-bottom,
.pkg_gitem.category-active .catbox-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.row-pgk-list > li.category-active .pkg_ginfo {
  background: rgba(0, 0, 0, 0.65);
}

.pkg-detail-top-row,
.goto-chkpage-cls,
.viewmenu-btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pkg_gcol {
  width: 25%;
  border: 4px solid #fff;
}

.pkg_gridsec .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.pkg_gbtsec.text-center {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.pkg_gcol .choose-single-btn {
  background: #FF1B2C;
  display: block;
  padding: 11px 10px;
  color: #fff;
  font-family: "Poppins";
  text-transform: uppercase;
}

.pkg-intuil-list .pkg_ginfo {
  opacity: 1;
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  color: #fff;
}

.package-overlay-header h3 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 10px 0;
}

.package-overlay-header span {
  font-weight: bold;
}

.package-overlay-body {
  font-weight: bold;
  font-size: 22px;
}

.package-overlay-body sup {
  font-size: 15px;
  text-transform: uppercase;
  top: -6px;
  position: relative;
}

.pkg_gcol .related-tags {
  z-index: 5;
  position: static;
}

.pkg-intuil-list .pkg_ginfo p {
  -webkit-transform: none;
  transform: none;
  margin: 10px 0;
}

.package-overlay-header {
  margin: 0 0;
}
ul li .cat-product-info {
  background: rgba(0, 0, 0, 0.6);
  padding: 45px 15px 45px;
  color: #fff;
  line-height: 1.4;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.35s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
ul li .cat-product-info h4 {
  color: #ffffff;
  margin: 0 0 5px 0;
}
.cat-product-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.cat-product-button a {
  margin: 0;
  display: block;
  height: 45px;
  padding: 15px 10px;
}
.pkg_gridsec > .row-replace > li {
  width: 32%;
  margin: 0 2% 20px 0;
}

.pkg_gridsec > .row-replace > li:nth-child(3n) {
  margin-right: 0;
}
.pkg_gridsec > .row-replace > li .innerproduct-itemimage-info img {
  max-height: 370px;
  min-height: 370px;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

#our-signature-dish span {
  position: relative;
  display: inline-block;
  margin: 0 0 0 15px;
  top: -2px;
  cursor: pointer;
  background: #000;
  padding: 6px 13px;
  border-radius: 5px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  line-height: 1;
}

#our-signature-dish span:hover {
  background: #FF1B2C;
}

#our-signature-dish span img {
  height: 17px;
  vertical-align: middle;
}

.upkg_tabsec {
  padding: 26px 0 8px;
}

.pkselect_tabin .bx_out {
  margin: 0 0 5px;
}

.bx_in {
  background: #fff;
  padding: 50px 70px 40px;
  border: 2px solid #d2d0d0;
  border-radius: 6px;
}

.pkselect_tabin .pkg_listsec {
  margin: 0 0 8px;
}

.pkg_listsec .bx_in {
  padding: 10px 0 10px 17px;
}

.pkglist_item {
  color: #989898;
}

.pkglist_itemtable {
  display: table;
  width: 100%;
}

.pkglist_itemtable > div {
  display: table-cell;
  vertical-align: top;
}

.pkglist_img {
  width: 33%;
  padding: 13px 0;
}

.pkglist_info {
  width: 50%;
  padding: 20px 22px 0;
}

.pkglist_infott h3 {
  margin: 0 0 7px;
  color: #010101;
  font-size: 18px;
  height: 22px;
  overflow: hidden;
}

.pkglist_infott p {
  font-size: 16px;
}

.pkglist_pricebx {
  width: 17%;
  border-left: 1px solid #e4e4e4;
  padding: 0 10px;
}

.pkglist_itemtable .pkglist_pricebx {
  vertical-align: middle;
}

.pkglist_pricebx > span {
  font-size: 18px;
}

.pkglist_pricebx > b {
  font-size: 22px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0 4px;
}

.pkglist_pricebx > div > span {
  font-size: 18px;
}

.pkglist_pricebx > div > b {
  font-size: 22px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0 4px;
}

.package_checkbx .bx_in {
}

.package_formtt {
  font-size: 18px;
  color: #020202;
  margin: 0 0 20px 0px;
}

.package_formtt1 {
  margin: 0 0 15px;
  position: relative;
}

.package_formtt span {
  display: block;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.package_formtt small {
  display: block;
  color: #333;
  font-size: 13px;
  font-style: italic;
}

.package_checkrow {
  margin-bottom: 40px;
}

.package_checkitem {
  border: 2px solid #f5f5f5;
  padding: 32px 38px;
}

.package_checkitem .package_formtt {
  border-bottom: 1px solid #f5f5f5;
  padding: 0 0 10px;
}

.package_formchecklist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.package_formchecklist li {
  margin: 0 0 11px;
}

.package_formchecklist li.modfr_valuecls span {
  text-transform: capitalize;
}

.package_formcheck_inline {
  padding-left: 29px;
}

.package_formcheck_inline:after {
  content: "";
  display: block;
  clear: both;
}

.package_formcheck_inline li {
  width: 50%;
  float: left;
  padding: 1px 15px 0 0;
  margin: 0 0 1px;
  height: 40px;
  overflow: hidden;
}

.package_formbx .form-group.last {
  border: 0;
}

.package_formchecklist .custom_checkbox span {
  font-size: 18px;
  font-weight: 700;
  color: #363636;
  padding: 5px 0 0 43px;
}

.package_addonbx .custom_checkbox span b {
  font-weight: 700 !important;
  height: 22px;
  overflow: hidden;
}

.package_formchecklist .custom_checkbox span:before {
  border-width: 2px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: #b1b1b1;
}

.package_formchecklist
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  text-align: center;
  background: #333333;
  border-color: #77905d;
}

.package_formchecklist .custom_checkbox span b {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  display: block;
}

.pkgerror_out {
  position: relative;
  border: 2px solid #ff1313;
}

.pkgerror_out .pkgerror_txt {
  background: #ff1313;
  padding: 5px 10px;
  position: absolute;
  bottom: -27px;
  left: 0;
  right: 0;
  font-size: 13px;
  color: #fff;
  max-width: 220px;
  text-align: center;
  margin: 0 auto;
  line-height: normal;
  z-index: 11;
}

.pkgerror_out.up .pkgerror_txt {
  top: -30px;
  bottom: auto;
}

.pkgaddon_checklist .pkgerror_out.custom_checkbox {
  border-color: #ecdfcc;
}

.pkgaddon_checklist {
  margin: 0 -3.5px;
}

.pkgaddon_checklist li {
  width: 25%;
  float: left;
  margin: 0 0 7px;
  padding: 0 3.5px;
}

.pkgaddon_checklist .custom_checkbox {
  border: 3px solid #f5f5f5;
  display: block;
  border-radius: 6px;
}

.pkgaddon_checklist li.active .custom_checkbox {
  background: #98684a;
  border-color: #ecdfcc;
}

.package_addonbx .custom_checkbox input {
  height: calc(100% + 6px);
  height: -webkit-calc(100% + 6px);
  width: calc(100% + 6px);
  width: -webkit-calc(100% + 6px);
}

.pkgaddon_checklist .custom_checkbox span {
  padding: 18.5px 15px 18.5px 72px;
}

.pkgaddon_checklist .custom_checkbox span:before {
  border-color: #98684a;
  color: #98684a;
  font-size: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 21px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  content: "\f067";
  font-family: FontAwesome;
  font-weight: 100;
}

.pkgaddon_checklist .custom_checkbox input[type="checkbox"]:checked + span {
  color: #fff;
}

.pkgaddon_checklist
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  content: "\f00c";
  background: #334845;
  border-color: #bdbfbf;
  font-size: 17px;
}

.pkgsetup_listrw {
  padding: 0 0 12px;
  margin-bottom: 11px;
  border-bottom: 1px solid #dfdddd;
}

.pkgsetup_listrw:last-child {
  border: 0;
  margin: 0;
  padding: 0;
}

.package_controltt .custom_radio span {
  font-size: 18px;
  font-weight: 700;
  color: #363636;
  padding: 5px 0 0 43px;
}

.package_controltt .custom_radio span:before {
  width: 30px;
  height: 30px;
  border-width: 2px;
}

.package_controltt .custom_radio input[type="radio"]:checked + span:before {
  border-color: #637550;
  border-width: 2px;
  background: #333333;
}

.pkgsetup_list .package_formchecklist {
  padding-left: 41px;
  margin: 14px 0 0;
}

.pkgsetup_list .package_formchecklist li {
  margin: 0 0 16px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span {
  padding: 0 0 0 30px;
  font-size: 16px;
  font-weight: 300;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span:before {
  width: 23px;
  height: 23px;
}

.pkgsetup_list
  .package_formchecklist
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  line-height: 20px;
}

.package_form_helptext {
  font-style: italic;
  font-size: 16px;
}

.package_descbx textarea {
  height: 115px;
  padding-top: 14px;
}

.upkg_tabsec .chk-payment-btn {
  margin: 10px 0 0;
}

.btn_download {
  font-size: 18px;
  color: #fff;
  padding: 8px 20px 7px;
  height: 38px;
  border: 0;
  border-radius: 30px;
  background: #FF1B2C;
  -webkit-box-shadow: 0 2px 0px 0px #0b0405;
  box-shadow: 0 2px 0px 0px #0b0405;
  margin: 6px 0px 12px;
}

.btn_download:hover {
  background: #ecdfcc;
  color: #0b0405;
  -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
  box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product_modifiers_listinner .modifiers_main_list {
  width: 48%;
  margin: 0 0 20px 0;
}

.package_checkitem {
  border-radius: 8px;
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a {
  width: 49%;
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:first-of-type,
#PackageEditMdl .chk-payment-btn .col-md-cls:first-of-type a,
.goto-chkpage-cls a:first-of-type,
.viewmenu-btn-row a:first-child {
  background: #000000;
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:hover,
#PackageEditMdl .chk-payment-btn .chk-payment-btn-row a:hover,
.goto-chkpage-cls a:first-of-type:hover,
.viewmenu-btn-row a:first-child:hover {
  background: #333333;
}

.pkg_total_box .pkg_total_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 20px;
}

.pkg_total_box .pkg_total_row.grand_total {
  font-size: 28px;
}

.pkg_total_box .cc-addon-subtotal-lhs {
  width: 140px;
}

.pkg_total_box .cc-addon-subtotal-rhs {
  width: 180px;
}

.pkselected_break .form-group .custom-select-inner select {
  border: 0;
  height: 46px;
  position: relative;
  top: 1px;
  right: 1px;
}

.pkselected_break .form-group .custom-select-inner {
  background: #fff;
  padding-left: 40px;
}

.checkout_time .custom-select-inner {
  background: #fff;
  padding-left: 40px;
}

.checkout_time .custom-select-inner select {
  padding: 6px 0;
  border: 0;
  height: 46px;
  position: relative;
  top: 1px;
  right: 1px;
}

.goto-chkpage-cls a,
.viewmenu-btn-row a {
  width: 49%;
  margin: 0;
}

.goto-chkpage-cls,
.viewmenu-btn-row {
  margin: 10px 0 0 0;
}

.addon_popup {
  max-width: 846px;
  border: 1px solid #000000;
}

.addon_popup .pouup_in {
  padding: 45px 9px 30px;
}

.addon_poptop {
  padding: 0 49px;
  margin: 0 0 30px;
}

.addon_listrw {
  width: 100%;
  margin: 0 0 15px;
  padding: 0 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.addon_linfo {
  width: 55%;
  color: #020202;
}

.addon_linfo h4 {
  font-size: 15.5px;
  color: #060606;
  margin: 0 0 5px;
}

.addon_linfo p {
  font-size: 15.5px;
  line-height: 1.2;
  margin: 0;
}

.addon_lright {
  width: 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cross_multi {
  font-size: 16px;
  color: #fff;
  margin: 0 9px 0 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 10px 3px;
  width: 42px;
  z-index: 1;
  text-align: center;
}

.cross_multi:before {
  content: "";
  background: #000000;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 3px;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
  -webkit-transform: -webkit-skewX(-10deg);
  transform: -webkit-skewX(-10deg);
}

.addon_price {
  font-size: 19px;
  color: #000000;
  margin: 0 20px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.addon_lright .btn {
  border-radius: 4px;
  font-size: 17px;
  margin-bottom: 0;
  vertical-align: middle;
}

.addon_lright .qty_bx {
  width: 109px;
  height: 33px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.addon_lright .qty_bx span {
  background: #fff;
  width: 35px;
  height: 33px;
  line-height: 31px;
  letter-spacing: -1px;
  font-size: 20px;
}

.addon_lright .qty_bx input {
  font-size: 16px;
}

.addon_lright .btn_grey {
  background: #4c4c4c;
  color: #fff;
}

.addon_lright .btn_grey:hover {
  background: #1c1c1c;
}

.addon_popbot .btn {
  min-width: 140px;
  font-size: 17px;
  margin: 0;
}

.addon_popbot .btn_grey {
  background: #5a5a5a;
  color: #fff;
}

.addon_popbot .btn_grey:hover {
  background: #1c1c1c;
}

.addon_lright .button {
  margin: 0;
  font-size: 15px;
  padding: 14px 20px 13px;
}

.addon_poptop_header {
  border-bottom: 2px solid #efefef;
  margin: 0 0 40px 0;
}

.addon_poptop_header h3 {
  margin: 0 0 15px 0;
  font-size: 24px;
}

.addon_poptop_header h3 small {
  display: block;
  color: #333;
  font-size: 12px;
  font-style: italic;
  padding-top: 10px;
}

.addon_popbot .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.addon_popbot .row-replace .button {
  margin: 0 0 0 10px;
}

.addon_popbot {
  border-top: 1px solid #e4e4e4;
  padding: 30px 0 0 0;
  margin: 0 49px;
}

.addon_popbot .cal-md-cls:first-of-type .common-mfd-closefun {
  background: #000000;
}

.addon_popbot .row-replace .button:hover {
  background: #333333;
}

.catering_checout .checkout-total {
  width: 100%;
}

.catering_checout .checkout-total .fc-half {
  width: 45%;
}

.catering_checout .checkout-total .controls-single input[type="text"] {
  background: #ffffff;
}

.catering_checout .checkout-control-group-bottom {
  margin: 30px 0 0 0;
}

.catering_checout .checkout-control-group-middle .col-md-cls {
  padding: 0;
}

.catering_checout .checkout-control-group-middle .form-group .form-control {
  margin: 0;
}

.order_details .panel-group .panel + .panel {
  margin-top: 0px;
}

.order_details .panel-group {
  padding: 30px 0px 0px;
  margin-bottom: 0px;
}

.order_details .panel-default {
  border-color: transparent;
}

.order_details .panel {
  background-color: #f6f6f6;
  border-radius: 0px;
}

.order_details .panel-default > .panel-heading {
  color: #fff;
  background-color: #FF1B2C;
  border-color: #FF1B2C;
  padding: 12px 15px 12px 15px;
  border-radius: 0px;
}

.order_details .panel-title > a {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

.order_details .panel-title > a .clck {
  text-align: right;
  font-weight: 300;
  font-style: italic;
  float: right;
  font-size: 14px;
  margin-right: 30px;
  margin-top: 2px;
}

.order_details .panel-title > a:hover {
  color: #fff;
}

.order_details .panel-title {
  position: relative;
}

.order_details .panel-title:after {
  content: "";
  position: absolute;
  right: -9px;
  background: #fff;
  height: 2px;
  top: 0;
  width: 18px;
  -webkit-transform: rotate(90deg) translate(10px, 10px);
  transform: rotate(90deg) translate(10px, 10px);
}

.order_details .panel-title:before {
  content: "";
  position: absolute;
  right: 7px;
  background: #fff;
  height: 2px;
  top: 0;
  width: 18px;
  -webkit-transform: rotate(0deg) translate(6px, 10px);
  transform: rotate(0deg) translate(6px, 10px);
}

.order_details .act .panel-title:after {
  opacity: 0;
}

.order_details .panel-collapse {
  height: 670px;
}

.checkout_time {
  padding: 0px 0px 20px;
}

.checkout_time label {
  color: #000000;
  line-height: 40px;
  padding: 4px 0px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
}

.order_details .edit {
  background: #98684a;
  border-radius: 3px;
  margin-right: 5px;
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  position: absolute;
}

.order_details .edit .fa-pencil-square-o {
  font-size: 18px;
  color: #fff;
}

.package {
  padding: 0px 0px 5px 0px;
}

.package .title {
  font-weight: bold;
  color: #000000;
  font-size: 20px;
  padding-left: 30px;
}

.package a {
  line-height: 1.6;
}

.package .catqty_bx .qty_minus {
  background: #fff;
}

.package .catqty_bx .qty_plus {
  background: #fff;
}

.package .package_cnt {
  padding: 0px 0px 25px;
}

.package .package-list {
  padding: 0px 0px 15px 40px;
  color: #000;
}

.package .package-list .one {
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
}

.package .package-list .two {
  font-weight: 300;
  font-size: 16px;
  margin: 0px;
}

.addon {
  padding: 20px 0px 15px 40px;
}

.addon h4 {
  margin: 0px 0px 10px 0px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.addon_list h5 {
  margin: 10px 0px 10px 0px;
  line-height: 1.6;
}

.addon_list p {
  margin: 0px 0px 5px 40px;
  font-size: 16px;
  color: #000;
}

.addon_list h5 .title {
  color: #000;
  font-size: 17px;
  padding-left: 35px;
  display: block;
  font-weight: bold;
}

.amt {
  text-align: right;
  margin-top: 10px;
}

.amt .remove {
  background: #333;
  border-radius: 3px;
  margin-left: 10px;
  width: 21px;
  height: 21px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.amt span {
  font-weight: bold;
  color: #000;
}

.buffet_setup {
  padding: 20px 0px 30px;
}

.buffet_setup h5 {
  margin: 10px 0px 5px 0px;
  line-height: 1.6;
}

.buffet_setup h5 .title {
  color: #000000;
  font-size: 20px;
  padding-left: 35px;
  font-weight: bold;
}

.buffet_setup p {
  margin: 0px 0px 7px 40px;
  font-size: 16px;
}

.total_amt {
  background: #1c1c1c;
  padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.total_amt .total {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.total_amt .rt {
  text-align: right;
}

.brline {
  background: #f5f5f5;
  height: 2px;
  margin-left: 40px;
}

.download-quote {
  padding: 20px 0px 0px;
  text-align: center;
}

.download-quote .btn {
  font-size: 18px;
  color: #fff;
  padding: 7px 20px;
  border: 0;
  background: #FF1B2C;
}

.catering_thak_you .tnk-header {
  text-align: unset;
}

.catering_thak_you .tnk-detail {
  text-align: center;
}

.catering_thak_you .tnk-header .tick {
  text-align: center;
}

.catering_thak_you .order_details .qtybxs {
  font-weight: 700;
  color: #000000;
  font-size: 16px;
  line-height: 38px;
  text-align: right;
}

.catering_thak_you .package .package-list {
  padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
  border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
  margin-left: 0px;
}

.promotion_popup {
  font-size: 16px;
  max-width: 650px;
}

.promo_poptt {
  margin: 0 0 30px;
}

.promo_poptt .title1 {
  margin-bottom: 8px;
}

.popoff_bar {
  padding: 10px 0 15px;
  border: 1px solid #ddd;
  border-right: 0;
  border-left: 0;
  margin: 0 0 35px;
}

.popoff_bar span {
  display: block;
  font-size: 36px;
  font-weight: 700;
  color: #000;
}

.popoff_bar b {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
}

.popwel_formsec {
}

.popwel_formsec h4 {
  margin: 0 0 10px;
}

.popwel_inputbx {
  position: relative;
}

.popwel_inputbx .form-control {
  height: 51px;
  font-size: 16px;
}

.popwel_inputbx .btn {
  position: absolute;
  right: 0;
  top: 0;
  border-color: #ecdfcc;
}

.choose_location.pkgerror_out .pkgerror_txt {
  max-width: 290px;
}

.sub_tab_mobtrigger {
  display: none;
}

.rating-option {
  position: relative;
  display: inline-block;
}

.rating-option input {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

.rating-option span {
  padding: 5px 0 0 40px;
}

.rating-option span:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #aba8a3;
  left: 0;
  top: 3px;
  pointer-events: none;
  line-height: 21px;
  border-radius: 50%;
}

.rating-option input[type="radio"]:checked + span:before {
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  text-align: center;
  background: #72624f;
  border-color: #ecdfcc;
}

.contact_left p {
  margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

#pay-conf-popup .process_inner {
  text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
  display: inherit;
  width: auto;
}

.loader-main-cls {
  position: relative;
  pointer-events: none;
}

.loader-sub-div {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}

.loader-sub-div:after {
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 0.3s linear infinite;
  animation: loader-rotate 0.3s linear infinite;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header_middle .container {
  position: relative;
}

.htico_search > a,
.htico_search_mbl > a {
  position: relative;
  display: block;
}

.header_bot {
  display: inline-block;
}

.home-banner-full {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.home-banner .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header-top {
  background: #000;
  text-align: center;
  clear: both;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-top p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0px;
}
.header-top-cls {
  padding: 30px 0 30px;
  position: relative;
  transition: 0.3s linear all;
}
.small_sticky .header-top-cls {
  padding: 20px 0;
}

.logo-main-section {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  align-items: center;
  flex-wrap: wrap;
}

.mbl_search_liblk {
  display: none;
}

.slick-slide {
  position: relative;
}

.wppc-checked {
  color: red;
}

.wppc-rating-full {
  background-image: url("../images/star-bg.png");
  background-repeat: no-repeat;
  padding: 5px 10px;
  margin-top: 0px;
  margin-left: 44px;
}

footer {
  position: relative;
  padding: 50px 0 0 0;
  background: #fafafa;
}

.footer-bottom {
  padding-top: 20px;
  padding-bottom: 15px;
}

.footer-bottom .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 150px;
  position: relative;
}
.footer-bottom .container:before,
.footer-bottom .container:after {
  display: none;
}

.footer-top {
  text-align: center;
  padding: 0 0 10px 0;
}

.footer-contact a,
.footer-bottom-right h3 {
  font-size: 15px;
  text-decoration: none;
  color: #000;
}

.footer-bottom-middle ul,
.footer-bottom-right ul {
  list-style: none;
  padding: 0;
}

.footer-bottom-middle ul li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  text-transform: uppercase;
  font-family: "melbourneregular";
  position: relative;
  padding: 1px 0;
}
.footer-bottom-middle ul li a::after,
.footer-bottom-middle ul li a::before {
  position: absolute;
  content: "";
  opacity: 1;
  width: 0;
  height: 1px;
  background-color: #FF1B2C;
  -webkit-transition: width 0.4s 0.1s;
  transition: width 0.4s 0.1s;
}
.footer-bottom-middle ul li a::before {
  top: 0;
  right: 0;
}
.footer-bottom-middle ul li a::after {
  top: 94%;
  left: 0;
}
.footer-bottom-middle ul li a:hover::after,
.footer-bottom-middle ul li a:hover::before {
  width: 100%;
}
.footer-bottom-middle ul li a:hover {
  color: #FF1B2C;
}

.footer-bottom-middle ul li {
  display: inline-block;
  margin: 0 5px;
}

.footer-bottom-right ul li a {
  color: #000;
  font-size: 22px;
}
.footer-bottom-right ul li a:hover {
  color: #FF1B2C;
}

.copyright-section p {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}

.footer-bottom-right {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}
.footer-bottom-right ul,
.footer-bottom-right h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-bottom-right h3 {
  margin-bottom: 0;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.footer-bottom-right ul li {
  padding-left: 5px;
  vertical-align: middle;
}

.featured-products-full {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
  display: none !important;
}

.slick-track .slick-slide {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}
.slick-track .slick-slide div div {
  height: 100%;
}

.slick-track .slick-slide .featured-products-single {
  height: 100%;
  background-color: #fff;
}

.featured-products-ordernow > .button {
  margin: 0 auto;
}

.featured-products-image {
  position: relative;
  overflow: hidden;
}

.featured-products-ordernow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  visibility: hidden;
  z-index: 2;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.featured-products-single:hover .featured-products-ordernow {
  visibility: visible;
  opacity: 1;
}

.featured-products-single .featured-products-image:after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  content: "";
  z-index: 1;
  width: 0%;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.featured-products-single:hover .featured-products-image:after {
  width: 100%;
}

.featured-products-single:hover .product-title-discription {
  background: #17191d;
}

.slick-track .slick-slide .featured-products-single:hover {
  background: #17191d;
}

.featured-products-single:hover .product-title h3,
.featured-products-single:hover .product-price h3 {
  color: #fff;
}

.featured-products-single:hover .product-title-price::after {
  border-color: rgba(255, 255, 255, 0.3);
}

.addcart_row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
  max-width: 320px;
  margin: 0 auto;
}

.addcart_row .qty_bx {
  width: 56%;
  vertical-align: middle;
  float: left;
}

.addcart_row .btn {
  margin: 0 0 0 2%;
  width: 42%;
  padding-left: 8px;
  padding-right: 8px;
  float: left;
  font-size: 17px;
  color: #fff;
  height: 38px;
  line-height: 38px;
  padding: 0px;
  border: none;
  cursor: pointer;
  background: #ff211f;
}

.addcart_row .btn:hover,
.addcart_row .btn:focus {
  background: #000;
}

.innerproduct-item::after {
  content: "";
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: absolute;
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  opacity: 0;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
  opacity: 1;
}

.innerproduct-item-text h4 {
  font-size: 20px;
  font-family: "Poppins";
  color: #0a0b10;
  padding: 10px 0 8px 0;
  margin-bottom: 0;
}

.bakery-review .fa-star {
  color: #000;
}

.bakery-review .wppc-checked {
  color: #ed8a19;
}

.innerproduct-item-text p {
  line-height: 20px;
  font-size: 14px;
  color: #949292;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  margin-top: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.innerproduct-item-text h5 {
  font-size: 20px;
  font-family: "Poppins";
  color: #000;
  margin-bottom: 0;
}

.innerproduct-item-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.menu-section_full {
  overflow: hidden;
}

.singup-fb-icon i {
  font-size: 22px;
  vertical-align: middle;
  padding-right: 7px !important;
}

.signup-popup .popup-footer .btn {
  font-weight: bold;
  font-size: 14px;
  max-width: 225px;
  height: 45px;
  line-height: 45px;
  padding: 0px;
  border-radius: 0px;
}
.inside-popup-rhs .signup-popup-head .guest_btn {
  display: block;
  max-width: 271px;
  margin: 0 auto;
  border-radius: 7px;
  padding: 16px 22px;
  height: 51px;
  font-size: 18px;
  line-height: 1;
}

.signup-popup .popup-body h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 15px;
}

.signup-popup .focus-out label,
.signup-popup .popup-body input {
  font-size: 14px;
  color: #c3c3c3;
}

.signup-popup .popup-body .sign_reg p {
  font-size: 12px;
  color: #0e0e0e;
  line-height: 20px;
}

.signup-popup .popup-body .custom_checkbox span:before {
  width: 20px;
  height: 20px;
  border: 2px solid #b1b1b1;
  margin-top: 5px;
}

.signup-popup
  .popup-body
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  background: none;
  content: "\f00c";
  font-family: FontAwesome;
  text-rendering: auto;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  background: #000000;
  border: 2px solid #000000;
}

.signup-popup .popup-body .sign_reg {
  margin-left: 25px;
}
.signup-popup .popup-body .custom_checkbox {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.signup-popup .popup-body .custom_checkbox .custom_label {
  position: relative;
}
.signup-popup .popup-body .custom-pdpa-div,
.signup-popup .popup-body .custom-pdpa-div .custom_checkbox {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.signup-popup .popup-body .custom-pdpa-div,
.signup-popup .popup-body .custom-pdpa-div .custom_checkbox span {
  padding: 0;
}
.signup-popup .popup-body .custom-pdpa-div .sign_reg p {
  margin-top: 5px;
}
.custom_checkbox .sign_reg.sign_spc_remove {
  margin-left: inherit;
}
.signup-popup .form-group .btn_minwid {
  text-transform: capitalize;
}

.checkout-heading {
  background: #231f20;
  color: #fff;
  font-size: 20px;
  padding: 10px;
  position: relative;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: auto;
  background: #231f20;
}

.home-main-div header {
  position: fixed;
  left: 0;
  right: 0;
  background: none;
}
header.small_sticky {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
}
.home-main-div header.small_sticky {
  background: #fff;
}

.logo a {
  position: relative;
}

.header-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background: #000;
  display: none;
}
.header-bottom .header-bottom-innersec {
  position: relative;
}

ul.hmenu_list li a.active {
  color: #fff;
}

ul.hmenu_list > li > a {
  font-family: "melbourneregular";
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  padding: 14px 15px 14px;
  display: block;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
ul.hmenu_list > li > a:hover,
.hmenu_list > li.active > a {
  background: #FF1B2C;
}

.hsign_sec > span {
  font-size: 17px;
  vertical-align: text-bottom;
  position: relative;
  top: 0px;
  color: #000;
  margin: 0 5px;
}

.hsign_sec a {
  font-family: "Poppins";
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
}

.hsign_sec a:hover {
  color: #FF1B2C;
}

.haction_sec {
  text-align: center;
  position: relative;
}

.hmenu_actions {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.htico_search > a > i,
.htico_search_mbl > a > i {
  background: url(../images/search.png) no-repeat center;
  width: 24px;
  height: 24px;
  display: block;
}

.htico_cart > a > i {
  background: url(../images/buy-cart.svg) no-repeat center;
  width: 26px;
  height: 26px;
  display: block;
}

.header_in {
  padding-top: 45px;
  position: relative;
}

.home-banner .slick-track .slick-slide > div {
  line-height: 0;
  width: 100%;
}

.featured-products-full .slick-dots {
  display: none !important;
}

.checkout-terms-and-condition {
  height: 136px;
  overflow-y: auto;
  margin: 0 0 15px 0;
  font-size: 16px;
  border: 1px solid #d0cfcb;
  padding: 15px 10px;
}

a.change-edt {
  color: #666;
  border-bottom: 1px solid #999;
  margin: 5px 0 0 0;
  display: inline-block;
  font-size: 16px;
}

a.change-edt:hover {
  color: #FF1B2C;
  border-color: #FF1B2C;
}

.page404-main-div {
  text-align: center;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.page404-main-div .gohome {
  display: inline-block;
  line-height: 1;
  font-weight: bold;
  background: #000;
  padding: 15px 50px;
  border-radius: 4px;
  text-align: center;
  background: #000;
  margin: 10px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  color: #fff;
}
.page404-main-div h1 {
  margin-bottom: 10px;
  margin-top: 40px;
}
.copyright-section {
  background: #1a1a1a;
  padding: 15px 10px;
}

.innerproduct-button .disabled {
  background: #d5d1d2 !important;
  color: #FF1B2C;
}

.innerproducts-list-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.innerproduct-button .btn_minwid {
  background: #98684a;
}

.prodetailinner-main-div {
  min-height: 105px;
}

.product-detail-empty {
  padding: 52px 0px 35px 0px;
  text-align: center;
  color: #cb4d36;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.custom-select-bxcls {
  position: relative;
}

.custom-select-bxcls:after {
  content: "";
  background: #fff url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  height: 48px;
  pointer-events: none;
}

.custom-select-bxcls:after {
  height: 48px;
}

.custom-select-bxcls select {
  margin: 0 !important;
  width: 100%;
  height: 50px;
  font-size: 17px;
  padding-left: 10px;
}

.disable-addtocart {
  pointer-events: none;
  cursor: default;
}

.disable-addtocart:after {
  background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  content: "";
}

.modfr-alert-danger {
  display: none;
  background: #ffdfdf;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
  color: #000;
}

.mdf_common_rows {
  position: relative;
}

.compo_pro_acc {
  background: #f9f9f9;
}

.prd_chosen_row.compo_top_div {
  background: #ffffff;
}

.compoMdfr_item_left {
  width: 100%;
  padding: 0;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
  margin: 0px;
}

.compoMdfr_item_left .form-group {
  width: 70%;
  float: left;
  margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
  font-size: 14px;
}

.compopro_acc_head {
  padding: 12px 20px 12px 20px;
  cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
  background: #5e5e5e;
  content: "";
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
    -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 20px;
  z-index: 2;
}

.compopro_acc_head::before {
  content: "";
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize{
  text-transform: capitalize;
}

.hcart_dropdown .qty_bx {
  width: 96px;
  float: right;
  padding: 0 26px;
  height: 30px;
}
.hcart_dropdown .qty_bx input {
  font-size: 13px;
}
.hcart_dropdown .qty_bx span {
  width: 32px;
}
.hcart_dropdown .qty_bx span.qty_minus:after {
  left: 5px;
}
.hcart_dropdown .qty_bx span.qty_plus:after {
  right: 4px;
}

.cart_footer p,
.cart_footer span {
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.cart_footer .help-block {
    font-weight: 700;
    text-align: center;
    margin-top: 2px;
}
.bot-border {
  border-bottom: 1px solid #ececec;
  margin-bottom: 10px;
}
.cart_footer {
  padding: 10px 0px 10px 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
  font-family: "Poppins";
  font-size: 27px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
}
.checkoutpage_form_outer .checkout-total .cdd-details-lhs .chk_hea {
  display: flex !important;
}
.cart_row.grant-total-cls sup {
  font-size: 60%;
  position: relative;
  top: -6px;
}

.cart_footer .cart_row .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-header-first h4 {
  font-weight: bold;
  color: #1c1b1b;
  font-size: 17px;
  text-transform: capitalize;
  margin: 0 0 5px 0;
  font-family: "Poppins", sans-serif;
}

.cart-header-first p {
  color: #000000;
  margin: 0;
}

.cart-header-second h5 {
  font-weight: bold;
  color: #1c1b1b;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.cart-header-second p {
  font-weight: bold;
  color: #1c1b1b;
  font-size: 28px;
}

.cart_footer .cart_remove {
}

.cart_footer .cart_remove:hover {
  background-color: #FF1B2C;
}

.product-details {
  padding: 0px 0 8px 5px;
  border-bottom: none;
  margin-bottom: 8px;
}
.product-details:last-child {
  border-bottom: 1px solid #ebebeb;
}

.cdd-details-rhs .product-details {
  border: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 20px;
}
.cdd-details-rhs .product-details .cart_info h4 {
  font-size: 16px;
}
.cdd-details-rhs .product-details .row-replace .cart_right {
  flex-flow: column;
  align-items: flex-end;
}
.cdd-details-rhs .product-details .row-replace .cart_right .qty_bx {
  padding: 0 34px;
}
.cdd-details-rhs .product-details .row-replace .cart_left {
}
.cdd-details-rhs .product-details .row-replace .cart_right .qty_bx {
  order: 2;
}
.cdd-details-rhs .product-details .row-replace .cart_right .cart_price {
  order: 1;
  margin: 0 0 10px 0;
  line-height: 1;
  padding-right: 25px;
}
.cdd-details-rhs .product-details .row-replace .cart_right .cart_price p {
  font-size: 17px;
}
.cdd-details-rhs .cart-outlet-name {
  margin-left: 0;
  margin-right: 0;
}
.cdd-details-rhs .cart_outlet_row:first-child .cart-outlet-name {
  margin-top: 20px;
}
.cart_outlet_row {
  /*border-bottom: 1px solid #ECECEC;*/
  padding-bottom: 10px;
}
.cdd-details-rhs .cart_remove {
  top: 1px;
  width: 16px;
  height: 16px;
}
.cdd-details-rhs .cart_footer .cart_remove {
  top: 6px;
}
.cdd-details-rhs .cart_footer p {
  font-size: 17px;
  line-height: 1.6;
  font-weight: 500;
}
.cdd-details-rhs .cart_row.grant-total-cls p,
.cdd-details-rhs .cart_row.grant-total-cls span {
  font-size: 26px;
  font-weight: 600;
}
.promo-remove-padd {
  padding-right: 25px !important;
}
.cart_body {
  padding: 10px 0px 100px;
}

.cartaction_bottom .btn {
  margin: auto;
  width: 100%;
  text-align: center;
  display: block;
  background: #ff211f;
  font-size: 20px;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartaction_bottom .btn:hover {
  background: #000;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #FF1B2C !important;
  border-radius: 50% !important;
  width: 1.7rem !important;
  font-size: 12.8px;
}
.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
  width: 1.7rem !important;
  font-size: 12.8px;
  color: #000;
}
.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
  font-size: 1rem;
}
.cart-header-first .row-replace,
.cart-header-second .row-replace,
.hcart_tt .row-replace,
.product-details .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}
.hcart_tt .row-replace {
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: center;
}
.hcart_dropdown.open .hcart_tt .row-replace {
  justify-content: space-between;
}
.hcart_tt .cart-hdr-top {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
#cart-close-span {
  display: block;
  position: absolute;
  order: 2;
  width: 16px;
  height: 16px;
  cursor: pointer;
  right: 10px;
  top: 15px;
}

#cart-close-span:before,
#cart-close-span:after {
  position: absolute;
  left: 7px;
  content: " ";
  height: 14px;
  width: 2px;
  background-color: #000;
}
#cart-close-span:before {
  transform: rotate(45deg);
}
#cart-close-span:after {
  transform: rotate(-45deg);
}
.hcart_tt .row-replace h3 {
  font-size: 21px;
}
.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
  width: 50%;
}

.cart_left {
  padding: 0 5px 0 0;
}

.cart_right {
  padding: 0 0 0 5px;
}
.cart-outlet-name,
.outlet-name {
  background: #fff9df;
  padding: 10px 20px 10px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.cart-outlet-name span {
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
  cursor: pointer;
}
.cart-outlet-name a {
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  color: #000;
}
.outlet-name {
  margin-bottom: 20px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 19px;
}
.cart_body .hcart_tt .row-replace {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart_body .hcart_tt {
  padding: 8px 10px 8px 10px;
  background: #060203;
}

.cart_body .hcart_tt h3 {
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
}

.cart_body .hclear_cart {
  font-size: 18px;
}

.cart_body .hclear_cart:hover {
  color: #e65d71;
}

.product-details .row-replace .cart_right {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: end;
  -ms-flex-align: end;
  flex-direction: column;
  align-items: flex-end;
}

.product-details .cart_info h4 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.product-details .row-replace .cart_left {
  width: 60%;
}

.compo_minmax_maindiv .combo-input-label {
  display: block;
}

.compo_minmax_maindiv .max-min-bar {
  padding-bottom: 10px;
  font-size: 16px;
}

.compo_minmax_maindiv .error_combo_div {
  color: red;
  padding-bottom: 10px;
  display: none;
}

.compo_minmax_maindiv .combo_pro_price {
  color: #FF1B2C;
}

.alert-success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6;
  position: absolute;
  top: 100px;
  z-index: 999;
  right: 10px;
}
p.jquery-success-msg {
  margin: 0 10px 15px;
}

.alert-success:before,
.single-danger:before {
  top: 10px;
  left: 8px;
}

.jquery-success-msg {
  margin-left: 20px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  position: fixed;
  z-index: 9999;
  top: 80px;
  padding: 10px 10px;
  border-radius: 10px;
  line-height: 1;
  font-weight: 500;
  transition: all 0.1s linear;
}
.alert-danger a {
  position: relative;
  top: 2px;
}
.alert-danger p {
  margin: 0;
  width: calc(100% - 15px);
  float: right;
  line-height: 16px;
  font-size: 13px;
}
.alert-danger.alert_danger_sticky {
  height: auto;
  top: 69px;
  transition: all 0.1s linear;
}
.alert-danger.alert-avali{
  position: absolute;
  border-radius: 0px;
  top: 15px !important;
  z-index: 10;
}
.sec-alter-danger {
  position: absolute;
  border-radius: 0px;
  top: inherit !important;
  z-index: 10;
  left: 0;
  right: 0;
}
.hmenu-login-act .cart_qty_list.hmenu_failed::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid #f2dede;
  left: 0;
  bottom: -28px;
}
.menu_icon {
  display: none;
}
.btn-medium {
  min-width: 190px;
}
.hcart_close {
  position: fixed;
  width: 25px;
  height: 25px;
  background: #000;
  color: #fff;
  box-shadow: none;
  border-radius: 4px;
  right: 5px;
  margin-top: 25px;
  padding: 10px;
  text-indent: -9999px;
  z-index: 999;
  display: none;
}
.hcart_close::after {
  background: url(../images/plus-white.svg) no-repeat;
  width: 15px;
  height: 15px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cart_row.cart-header-second {
  margin: 15px 0 0 0;
}

.cart-direction {
  margin: 0 0 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.tnk-delivery .delivery-cart-div .cart-direction:after {
  border-bottom: 2px solid #e4e4e4;
  content: "";
  position: absolute;
  left: 50px;
  display: block;
  height: 2px;
  right: 30px;
  margin: auto;
}

.innersection_wrap {
  padding: 40px 0 0 0;
  background: #f8f6e9;
}

.tnk-chk-order .button {
  display: block;
  margin: 0;
  width: 100%;
  padding: 20px 20px 20px;
  font-size: 22px;
  text-transform: capitalize;
  background: #000;
}

.tnk-chk-order .button:hover {
  background: #FF1B2C;
}

.thank-order-detaildiv {
  max-width: 780px;
  margin: 0 auto 40px auto;
  -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}
.tnk-chk-order {
  margin-left: -30px;
  margin-right: -30px;
}
.mainacc_toptext {
  text-align: center;
  padding: 0 0 40px 0;
}

.mainacc_toptext h2 {
  margin: 20px 0 5px 0;
}

.mainacc_toptext p {
  font-size: 20px;
  margin: 0;
  font-family: "melbourneregular";
  color: #000;
}
.accout-flex {
  position: relative;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
}
.accout-flex .mainacc_menuout {
  position: relative;
  flex: 1 20%;
  -webkit-flex: 1 20%;
  -ms-flex: 1 20%;
  min-width: 100px;
  max-width: 50%;
  z-index: 10;
}
.accout-flex .mainacc_menucontent {
  flex: 1 80%;
  min-width: 150px;
  max-width: 79%;
  box-shadow: 1px 6px 8px #0000002e;
  background: #fff;
}
.accout-flex .mainacc_menucontent.redmee_content .main_tabsec {
  padding: 0;
}
.accout-flex .mainacc_menucontent .main_tabsec {
  padding: 55px;
}
.redmee_content .main_tabsec_inner {
  padding: 40px 55px;
}
#CartListMdl .total_amt {
  overflow: hidden;
  padding: 25px;
}

#CartListMdl .total_amt .subtotal {
  font-size: 17px;
}

#CartListMdl .order_details .panel-group {
  margin: 0;
  padding: 0;
}

#CartListMdl .package .title {
  padding-left: 0px;
}

#CartListMdl .package .package_cnt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px 0px 5px;
  border-bottom: 1px solid #cecece;
  margin: 0 0 15px 0;
}

#CartListMdl .package .package_cnt .col-sm-cls {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls {
  font-size: 19px;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#CartListMdl .addon {
  padding: 10px 0px 0px 10px;
}

#CartListMdl .package .package-list {
  padding: 0px 0px 5px 10px;
  color: #000;
}

#CartListMdl .buffet_setup {
  padding: 5px 0px 5px;
}

#CartListMdl .buffet_setup .row-replace,
#CartListMdl .addon .row-replace.addon_list,
#CartListMdl .total_amt .subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#CartListMdl .buffet_setup h5 .title {
  padding-left: 10px;
}

#CartListMdl .buffet_setup p {
  margin: 0px 0px 7px 25px;
}

#CartListMdl .brline {
  display: none;
}

#CartListMdl .addon_list p {
  margin: 0px 0px 5px 20px;
}

#CartListMdl .addon_list h5 .title {
  padding-left: 20px;
}

#CartListMdl .order_details .panel-collapse {
  height: 600px;
}

#CartListMdl .Cart-list-mdlheader h2,
#PackageEditMdl .Cart-list-mdlheader h2 {
  text-align: center;
  font-size: 32px;
  margin: 5px 0 20px 0;
}

#CartListMdl button.close,
#PackageEditMdl button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
}

#CartListMdl button.close:before,
#CartListMdl button.close:after,
#PackageEditMdl button.close:before,
#PackageEditMdl button.close:after {
  position: absolute;
  left: 9px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #000;
  top: 0px;
}

#CartListMdl button.close:before,
#PackageEditMdl button.close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#CartListMdl button.close:after,
#PackageEditMdl button.close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#PackageEditMdl .bx_in {
  padding: 20px 20px;
}

#PackageEditMdl .package_checkitem {
  padding: 20px;
}

#PackageEditMdl .chk-payment-btn {
  margin-bottom: 0;
}

#PackageEditMdl .pkselected_filtersec .row-replace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#PackageEditMdl .pkselect_tabin {
  padding: 0;
}

#PackageEditMdl .pkselected_package label {
  position: relative;
  padding: 5px 0 0 0px;
}

.myacc_order_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.myacc_order_details .current_order {
  width: 48.5%;
  margin-bottom: 20px;
  padding: 0 0 120px 0;
  position: relative;
  background: #fff;
  box-shadow: 1px 10px 9px #0000002b;
}

.myacc_head_sec {
  background: #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.myacc_head_sec .head_left {
  padding: 15px 9px;
}
.myacc_head_sec .head_right {
  background: #4EB25A;
  padding: 15px 9px;
}
.myacc_head_sec .cancelled.head_right {
  background: #ff1b2c;
}
.myacc_head_sec .cancelled.head_right h4 {color: #fff;}
.head_right .head-group {
  z-index: 2;
  position: relative;
}

.head_left h4 {
  color: #000;
  margin: 0;
  font-size: 18px;
  text-transform: capitalize;
}

.head_right h4 {
  color: #000;
  margin: 0;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
}

/* .head_right::before {
    content: "";
    display: block;
    background: #f6f6f6;
    width: 35%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    z-index: 1;
} */

.order_details_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.order_details_footer .order_amt {
  background: #f8f6e9;
}
.order_btns,
.order_amt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
}

.order_btns .button {
  margin: 10px 0;
  width: 47%;
  display: block;
  padding: 16px 10px 16px;
  background: #282828;
  color: #dedede;
  box-shadow: none;
  text-transform: capitalize;
}
.button.print_invoice {
  border-right: 2px solid #fff;
}
.button.view_recipt {
  border-left: 2px solid #fff;
}

.order_btns .view_recipt.button_full_view {
  width: 100%;
  border: 0;
}
.order_btns .button:hover {
  background: #dedede;
  color: #282828;
}

.order_amt {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #f6f6f6;
  padding: 16px 15px;
}

.order_amt h3 {
  margin: 0;
  color: #000;
  font-size: 21px;
  font-weight: 700;
  text-transform: capitalize;
}

.order_amt-right sup {
  font-size: 75%;
  position: relative;
  top: -5px;
  margin: 0 2px 0 0;
}

.order_details_body {
  padding: 15px;
  background: #fff;
}

.order_no_deatails {
  margin: 0 0 20px 0;
  text-align: center;
  font-weight: 600;
}

.order_no_deatails span {
  display: block;
}

.delivery_total_left h3 {
  font-weight: bold;
}

.delivery_total_left img {
  margin: 0 0 10px 0;
}

.delivery_total {
  position: relative;
}

.web_order_typecls {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 50px 0;
}

.web_order_typecls > li {
  width: 50%;
  border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 17px 10px;
  background: #e4e4e4;
  color: #000;
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
  background: #FF1B2C;
}

.web_order_typecls li a h5 {
  margin: 0;
  color: #000;
  text-transform: uppercase;
}
.web_order_typecls li.active a h5,
.web_order_typecls li a:hover h5 {
  color: #fff;
}

.sprite_lst_icon {
  margin: 0 10px 0 0;
}

.sprite_lst_icon.order_t {
  max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
  width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
  width: 22px;
}

.myacc_order_details .reserv-orderlist {
  padding: 0;
  border-bottom: 2px solid #000000;
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
  margin: 15px 0px 0;
  border-top: 1px solid #e4e4e4;
  padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
  margin: 0;
  color: #000;
  font-size: 19px;
  text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
  margin: 0;
  color: #98684a;
  font-size: 21px;
  font-weight: bold;
}

.reserv-loader-div {
  display: inline-block;
}

.reserv-orderlist
  .delivery_total.delivery_total_number
  .delivery_total_left
  h4 {
  font-size: 19px;
}

.reserv-orderlist
  .delivery_total.delivery_total_number
  .delivery_total_left
  h2 {
  margin-bottom: 5px;
}

.reserv-orderlist .delivery_total.delivery_total_number {
  margin: 0;
  padding: 0;
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
  background: #ffd7b8;
}

.ord-pagination-col select {
  width: auto;
  height: auto;
  padding: 1px 0 1px 1px;
  border: 0;
  margin: 0 5px;
  background: none;
}

.delivery_submit_div .button {
  width: 100%;
}

.mainacc_menulist #masterCount {
  background: #FF1B2C;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  font-size: 12px;
  line-height: 21px;
  vertical-align: top;
  margin-top: 1px;
  padding: 0 4px;
  min-width: 20px;
}

.reward-list-body .delivery_total.delivery_total_number {
  padding: 0;
  margin: 0;
}

.reward-list-body .delivery_total_left {
  display: inline-block;
  width: auto;
  float: none;
  margin: 0 15px 0 0;
}

.reward-list-body
  .delivery_total.delivery_total_number
  .delivery_total_left
  h4 {
  font-size: 17px;
}

.reward-list-body .order_amt_div h3 {
  margin: 0;
  font-size: 19px;
  padding: 10px 0 0 0;
  font-family: "Poppins";
}

.reward-list-body .order_amt_div h3 sup {
  font-size: 75%;
}

.reward-list-body .order_amt-right h3 {
  color: #000;
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
  position: relative;
  padding: 0 0 0 22px;
  color: #474747 !important;
}

.reward-list-body .checkoutDate:before {
  background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
  background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  content: "";
}

.reward-list-body .myacc_order_details .current_order {
  padding: 0 0 110px 0;
}

.promo-form {
  text-align: center;
  padding: 40px 15px;
  margin: 0;
  background: #f0f0f0;
}

.promo_wrap .both-order-tab {
  padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
  position: relative;
  max-width: 640px;
  margin: 0 auto;
}

.promo-form input[type="text"] {
  margin: 0;
  height: 47px;
  padding: 5px 150px 5px 20px;
  background: #fff;
  font-size: 14px;
  color: #615f5f;
}

.promo-form .applyBtn {
  margin: 0;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  background: #000000;
  padding: 13px 20px;
  height: 47px;
  min-width: 146px;
}
.promo-form .applyBtn:hover {
  background: #FF1B2C;
}

.promo-form h3 {
  color: #000;
  font-size: 36px;
  margin: 0 0 15px;
  text-transform: capitalize;
  line-height: 1.3;
}

.promo-form h3 span {
  font-size: 19px;
  display: block;
}

.promo-earned-col-image {
  position: relative;
  width: 50%;
}
.promo-earned-col-image img {
  width: 100%;
  height: 100%;
}
.promo-earned-col-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
  background: #fff;
}
.promo-earned-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 10px;
  width: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 1;
}
.promo-earned-code {
  font-size: 18px;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.promo-earned-code span {
  display: block;
  font-size: 30px;
}
span.promo-valid {
  color: #231f20;
  font-size: 14px;
  padding: 0 0 15px 0;
  display: block;
  font-weight: 600;
}
.promation_btn {
  line-height: 0;
}
.promation_btn .button {
  margin: 0;
  background: #49a8d4;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #49a8d4;
  border-radius: 4px;
  font-size: 15px;
  padding: 12px 18px 12px;
}
.promo-earned-info p {
  font-size: 20px;
  color: #4a4646;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.promation_btn .promation_btn-one {
  background: #231f20;
  color: #fff;
  border-radius: 0px;
  border: none;
  text-transform: capitalize;
  font-family: "Poppins";
  padding: 17px 15px;
}
.promation_btn .button.promation_btn-one:hover {
  background: #fd2322;
}
.promation_btn .button:hover {
  background: #000;
  color: #fff;
  border-color: #000;
}

.promo-bot-left span {
  display: block;
}

.promo-bot-left p {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}

.promo-bot-left p span {
  display: block;
  font-size: 14px;
}

.promation_btn {
  line-height: 0;
}

.promo-earned-col .tag {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #000000;
  margin: 0;
  padding: 10px 12px 5px;
  position: absolute;
  left: -43px;
  top: 30px;
  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  width: 220px;
  line-height: normal;
  text-align: center;
}

.promo-earned-col .tag span {
  display: inline-block;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}
.mypromo-main-div .myacc_order_details .promo-earned-col {
  width: 49%;
  background: #fff;
  margin: 0 0 40px 0;
}

.pickup-cart-div .cart-direction:after {
  display: none;
}

.pickup-cart-div .cart-direction img {
  width: 75px;
  margin: 0 auto;
}

.pickup-cart-div .cart-header-first .row {
  display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
  padding: 0 0 10px 0;
}

span.myaccount_separate {
  width: 2px;
  height: 16px;
  display: inline-block;
  background: #fff;
  margin: 0 7px 0 5px;
}

.load_more_div {
  display: block;
  clear: both;
  width: 100%;
  text-align: center;
  padding: 30px 0 0 0;
}

.load_more_div .load_more_data {
  margin: 0 auto;
  min-width: 160px;
}

.make-pay-div h4 {
  font-size: 20px;
}

.pay-cart-header {
  padding: 18px 0px 5px 0px !important;
}

.inner_banner {
  height: 400px;
}

.inner_banner img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.brand_note {
  margin: 0 0 70px;
}

.about_lirow {
  margin: 0 auto 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 900px;
  position: relative;
}

.about_lirow:last-of-type {
  margin-bottom: 20px;
}

.about_liimg {
  width: 50%;
}

.about_liimg img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.about_liinfo {
  width: 50%;
  padding: 50px 50px;
  background: #000000;
}

.diamond_divider {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.diamond_divider span {
  width: 0;
  height: 0;
  border: 40px solid transparent;
  border-bottom-color: #000;
  position: relative;
  top: -22px;
}

.diamond_divider span:after {
  content: "";
  position: absolute;
  left: -40px;
  top: 60px;
  width: 0;
  height: 0;
  border: 40px solid transparent;
  border-top-color: #000;
}

.award_gallery {
  margin: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.award_galcol {
  width: 32%;
  position: relative;
  margin: 0 0 30px;
  z-index: 1;
}

.award_galimg {
  height: 360px;
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 20px;
  border-style: solid;
  border-width: 15px;
  border-top-color: #333333;
  border-right-color: black;
  border-bottom-color: #333333;
  border-left-color: black;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.award_galimg img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.award_label {
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  background: #333333;
  text-align: center;
  margin: 0;
  padding: 10px 12px 5px;
  position: absolute;
  left: -43px;
  top: 25px;
  transform: rotate(-42deg);
  -webkit-transform: rotate(-45deg);
  width: 180px;
  line-height: normal;
  z-index: 1;
  display: none;
}

.award_label span {
  display: inline-block;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.award_galinfo {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 35px;
  right: 35px;
  top: 80px;
  bottom: 35px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 15px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  text-align: center;
  z-index: 1;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
}

.award_galcol:hover .award_galinfo {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hook {
  width: 6px;
  height: 6px;
  background-color: #5c5c5d;
  margin: 0 auto 40px auto;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 0 1px #000000;
  box-shadow: 0 1px 0 1px #000000;
  position: relative;
}

.hook:before {
  content: "";
  width: 100px;
  height: 100px;
  border: 2px solid #a5a1a1;
  position: absolute;
  left: 50%;
  top: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
  margin-left: -50px;
}

.award_galinfo h3 {
  font-size: 18px;
  color: #fff;
}

.award_galinfo h3 a,
.test-popup-link {
  color: #fff;
}

.test-popup-link:hover,
.award_galinfo h3 a:hover {
  color: #98684a;
}

.award_plus {
  display: block;
  font-size: 40px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.award_galcol:hover .award_plus {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.test-popup-link:hover .award_plus {
  border-color: #98684a;
}

.change-pickup-location {
  margin-bottom: 20px !important;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

.contact_wrap {
}

.locate-map {
  margin: 0 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.locate-mapleft {
  width: 360px;
}

.locate-list {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.locate-lirow {
  padding: 15px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}

.locate-lirow:last-child {
  border-bottom: 0;
}

.locate-lirow:hover {
  background: #000000;
  color: #fff;
}

.locate-lirow h4 {
  font-size: 18px;
  margin: 0 0 4px;
}

.locate-lirow:hover h4 {
  color: #fff;
}

.locate-lirow p {
  margin: 0;
}

.locate-lirow.active {
  background: #000000;
  color: #fff;
}

.locate-lirow.active h4 {
  color: #fff;
}

.locate-mapright {
  width: calc(100% - 360px);
}

.locate-mapright iframe {
  vertical-align: top;
  width: 100%;
}

.contact_col {
  float: left;
  width: 50%;
  padding: 0 15px;
}

.contact_form .form-control {
  width: 100%;
  display: block;
}

.contact_form textarea.form-control {
  margin: 0;
  height: 100px;
}

.contact_form .btn_sec .btn {
  margin: 0;
  text-transform: capitalize;
}

.our-policy {
  margin: 30px 0 0 0;
}

.our-policy h6 {
  margin: 0 0 10px 0;
}

.service-makkan {
  margin: 0 auto 30px;
  text-align: center;
}

.service-makkan img {
  margin-top: -110px;
  max-height: 182px;
}

.err_alt {
  border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
  color: #ff1313;
}

ul.outletul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.outletul > li {
  width: 49%;
  margin: 0 2% 30px 0;
  background: #000000;
  position: relative;
}

.outletul > li:nth-child(even) {
  margin-right: 0;
}

.ourrest_img {
  width: 100%;
}

.ourrest_img img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.ourrest_info {
  width: 100%;
  padding: 30px 20px 70px 20px;
  color: #fff;
}

.ourrest_info h4 {
  margin: 0 0 15px;
  color: #fff;
  font-size: 22px;
}

.ourrest_info p {
  margin: 0 0 8px;
}

.ourrest_infolinks {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ourrest_infolinks li {
  display: block;
  width: 50%;
}

.ourrest_infolinks a {
  padding: 14px 5px;
  display: block;
  color: #fff;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  font-weight: bold;
  height: 100%;
}

.media-links-a a {
  background: #333333;
}

.media-links-b a {
  background: #666;
}

.ourrest_infolinks a:hover {
  background: #000000;
}

.ourrest_infolinks a i {
  display: inline-block;
  margin: 0 10px 0 0;
}

.news_list {
  margin: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.news_list > .news_lirow {
  width: 32%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 40px;
  background: #fff;
}

span.date {
  color: #9c9c9c;
  font-size: 16px;
  display: block;
  margin: 0 0 10px 0;
}

.news_liimg {
  width: 100%;
}

.news_liimg img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.news_liinfo {
  width: 100%;
  padding: 20px 0 0 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.news_liinfo h4 {
  margin: 0 0 5px;
  font-size: 19px;
  line-height: 1.3;
}

.news_liinfo h4 a {
  color: #000;
  display: block;
}

.news_liinfo h4 a:hover {
  color: #000000;
}

.news_liinfo p {
  margin: 0 0 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.product-inner-filter {
  display: none;
}
.outlet-gst {
  display: flex;
  justify-content: space-between;
}
.outlet-gst span {
  font-weight: 600;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
  position: sticky;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 999;
  float: right;
  opacity: 0;
  right: 15px;
  /* -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear; */
}

#scrollbutton.show {
  opacity: 1;
  bottom: 0;
  display: inline-block !important;
}
#scrollbutton a {
  background: #353535;
  display: inline-block;
  position: relative;
  color: #fff;
  text-decoration: none;
  line-height: 0;
  padding: 14px 18px;
  width: 48px;
  height: 48px;
  float: right;
  overflow: hidden;
  border-radius: 50%;
  font-size: 20px;
  bottom: 55px;
}

#scrollbutton a:hover {
  background: #FF1B2C;
}

#scrollbutton a i.ars {
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
  -webkit-transform: translateY(-25px) rotateY(360deg);
  transform: translateY(-25px) rotateY(360deg);
  opacity: 0;
  -webkit-transition: all 0.3s linear 0.2s;
  transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
  transform: translate(-50%, -50%) rotateZ(360deg);
  opacity: 0;
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
  bottom: 18%;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0.2s;
  transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
  bottom: 9%;
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transition: all 0.2s linear 0.4s;
  transition: all 0.2s linear 0.4s;
}

.reservation-full {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.reservation-counter,
.choose-outlet,
.choose-datetime-full,
.special-instructions {
  background-color: rgba(210, 190, 155, 0.38);
  border-radius: 10px;
  clear: both;
  overflow: hidden;
  margin-bottom: 7px;
  padding-top: 23px;
  padding-bottom: 23px;
}

.reservation-full h2 {
  font-size: 40px;
}

.reservation-counter h3,
.choose-outlet h3,
.choose-date h3,
.choose-time h3,
.special-instructions h3 {
  font-family: "subway_novellaregular";
  font-size: 26px;
  margin-bottom: 10px;
}

.reservation-counter-left h4 {
  font-family: "Poppins";
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 10px;
  text-transform: uppercase;
  margin-right: 10px;
}

.reservation-counter-left,
.reservation-counter-right {
  float: left;
}

.reservation-counter-full {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.choose-date,
.choose-time {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.special-instructions textarea {
  max-width: 440px;
  height: 130px;
  resize: none;
  margin-bottom: 0px;
  padding: 20px;
}

.choose-outlet .custom_select {
  max-width: 350px;
  margin: 0 auto 0px !important;
}

.choose-outlet .custom_select select {
  margin-bottom: 0px;
}

.choose-date .catering_days_ul li dl dt,
.choose-time .catering_days_ul li dl dt {
  font-size: 12px;
}

.choose-date .catering_days_ul li dl.active,
.choose-time .catering_days_ul li dl.active {
  border: 2px solid #000000;
}

.choose-date p,
.choose-time p {
  font-family: "open_sanslight";
  font-size: 12px;
  text-align: left;
  margin-bottom: 0px;
}

.choose-time {
  margin-top: 30px;
}

.reservation-summary {
  max-width: 425px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 2px solid #cfbfaa;
  padding: 25px;
  overflow: hidden;
}

.reservation-summary ul {
  padding-left: 0px;
  list-style-type: none;
  padding-bottom: 0px;
}

.reservation-summary ul li span {
  font-family: "Poppins";
  font-size: 15px;
  text-transform: uppercase;
  overflow: hidden;
  width: 50%;
  float: left;
  text-align: left;
}

.reservation-summary ul li span:first-child {
  position: relative;
  padding-right: 10px;
}

.reservation-summary ul li span:last-child {
  padding-left: 10px;
}

.reservation-summary ul li span:first-child::after {
  content: ":";
  position: absolute;
  top: 0px;
  right: 0px;
}

.reservation-summary ul li {
  margin-bottom: 15px;
  overflow: hidden;
}

.reservation-summary ul li:last-child {
  margin-bottom: 0px;
}

.reservation-summary ul li span.summary-email {
  text-transform: lowercase;
}

.reservation-summary ul li span.summary-childseat {
  font-style: italic;
  text-transform: lowercase;
  color: #4e4e4e;
  font-size: 14px;
}

.reservation-summary-full {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(210, 190, 155, 0.38);
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 50px;
  overflow: hidden;
}

.reservation-summary-full h3 {
  font-family: "subway_novellaregular";
  font-size: 26px;
  margin-bottom: 20px;
}

.reservation-summary-button {
  max-width: 860px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}

.reservation-summary-button .go-back,
.reservation-summary-button .continue {
  width: 49.5%;
  float: left;
}

.reservation-summary-button .go-back {
  background-color: #000;
}

.reservation-summary-button .continue {
  background-color: #98684a;
}

.reservation-summary-button .go-back a,
.reservation-summary-button .continue a {
  margin-top: 0px;
  margin-bottom: 0px;
  background: none;
}

.reservation-summary-button .go-back a:hover {
  background: #000000;
}

.reservation-summary-button .continue a:hover {
  background: #333;
}

.go-back a {
  width: 100%;
  display: block;
  margin: 0;
  font-size: 20px;
}

.choose-date .catering_days_ul li dl:hover,
.choose-time .catering_days_ul li dl:hover {
  border: 2px solid #a23c2b;
}

.choose-date .catering_days_ul li dl.cl-next-days,
.choose-time .catering_days_ul li dl.cl-next-days {
  border: none;
}

.choose-outlet select {
  font-size: 15px;
  color: #595656;
  padding-left: 15px;
  padding-right: 15px;
}

.reservation_order_details {
  text-align: center;
}

.reservation_outlet,
.reservation_user,
.reservation_Date,
.reservation_Time {
  border-bottom: 1px solid #e4e4e4;
  padding: 0 0 15px 0;
  margin: 0 0 18px 0;
}

.reservation_order_details h4 {
  color: #818181;
}

.reservation_order_details p {
  color: #000;
  font-weight: bold;
  font-size: 19px;
}

a.add-address-more {
  display: inline-block;
  position: relative;
  font-size: 24px;
  margin: 0 0 0 5px;
}

.address_linfo {
  margin-bottom: 15px;
}

.address_linfo .custom_radio span {
  color: #555555;
}

.address-list-cls {
  max-height: 240px;
  overflow-y: auto;
}

.phone-show-mbl {
  display: none;
}

.phone-show-mbl a {
  color: #fff;
}

.disclaimer {
  margin: 30px 0 0 0;
}

.disclaimer h4 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.disclaimer ul {
  padding-left: 20px;
}

.horder_tt {
  padding: 12px 10px 12px 20px;
}

.oreder-row-inv {
  margin: 0;
}

.oreder-row-inv .cart_img {
  padding: 0;
}
.Viewreceipt .oreder_itm_row_inner {
  padding: 5px 20px;
}
.Viewreceipt .outlet-name {
  margin-bottom: 0;
}

.oreder-row-inv .cart_left {
  text-align: left;
}

.month-select-div,
.choose-date-div {
  margin: 0 auto 40px auto;
}

.tarms-error {
  display: none;
  color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
  display: block;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-family: "Poppins";
}

.catering-product-rowlist .price_nor {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.catering-product-rowlist .price_nor sup {
  font-size: 80%;
  font-family: "Poppins";
  text-transform: uppercase;
}

.dotted-line-top {
  border-top: 1px dotted #ccc;
  padding: 20px 0 0 0;
  margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
  padding-left: 30px;
}

.custom-tarms-div span:before {
  width: 20px;
  height: 20px;
  border: 2px solid #FF1B2C;
  margin-top: 2px;
  border-radius: 0;
}

.custom-tarms-div
  .custom_checkbox
  input[type="checkbox"]:checked
  + span:before {
  background: none;
  content: "\F00C";
  font-family: FontAwesome;
  text-rendering: auto;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  background: #FF1B2C;
  border: 2px solid #FF1B2C;
}

.error_div_cls {
  color: red;
  display: none;
}

.single_outlet_info {
  padding-top: 15px;
  text-align: center;
}

.single_outlet_info p {
  margin: 0;
  font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
  font-size: 20px;
  font-style: normal;
}

.isDisabled {
  cursor: default;
  text-decoration: none;
}

.disableDivCls {
  display: none;
}

.event-type-single {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rmv-border-bottom {
  border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
  opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
  opacity: 1 !important;
  visibility: visible !important;
  top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
  cursor: not-allowed;
  pointer-events: all !important;
}

h3.choose-eventtype {
  position: relative;
}

a.go-bck-icon {
  position: absolute;
  left: 0;
  top: 0;
}

a.go-bck-icon:before {
  content: "\f0a8";
  font-family: Fontawesome;
  font-size: 30px;
}

.hall-select-popup .mfp-close {
  color: #fff;
}

.pkg-detail-top-row .pkselected_catgrytext {
  text-align: left;
}

.pkselected_filtersec .pck_selected_ctry {
  font-family: "Poppins";
  font-size: 21px;
  padding: 0;
}

.pkg-detail-top-row .pkselected_package:first-of-type {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.guest_btn {
  text-align: center;
}

.pkglist_itemtable .pkglist_img img {
  max-height: 200px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.controls.single-link {
  text-align: center;
}

.controls.single-link a {
  font-size: 16px;
  display: inline-block;
  color: #272727;
  font-family: "Poppins";
}

.package_info_maindiv .package_info_title {
  color: #FF1B2C;
  text-align: center;
}

.package_info_maindiv .package_info_subtitle {
  color: #bb4d4d;
  text-align: center;
}

.package_info_maindiv .terms_conditions h4 {
  color: #bb4d4d;
  font-size: 16px;
  padding-top: 15px;
}

.back-to-listbtn a {
  width: 100% !important;
}

.package_info_maindiv .text-rgt {
  float: right;
}

.package_info_maindiv .terms_conditions li {
  list-style-type: none;
}

.package_info_maindiv .terms_conditions ul {
  padding-left: 30px;
}

.custom-addon-checkbox .pkgerror_txt {
  background: #ffbaba;
  color: #d8000c;
  padding: 5px 25px;
  font-size: 13px;
  position: relative;
  display: inline-block;
  font-weight: normal;
  z-index: 4;
  width: 100%;
}

.apply-red {
  color: red !important;
}

ul.venue_type_item {
  padding: 20px 0 0 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

ul.venue_type_item li {
  margin: 5px 10px;
}

ul.venue_type_item li a {
  padding: 15px 25px;
  background: #98684a;
  color: #fff;
  border-radius: 5px;
  font-family: "Poppins";
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: block;
}

ul.venue_type_item li.venuetype-active a {
  background: #FF1B2C;
}

.venue_popup {
  max-width: 780px;
}
.pdpa_trmcont_link {
  font-size: 14px;
  color: #000;
  display: block;
  width: 100%;
  text-align: center;
}
.pdpa_trmcont_link a:hover {
  color: #000;
}
.pdpa_trmcont_link span {
  display: inline-block;
}

.delivery_outletpoup .self_popup_hea_row {
  background: #FF1B2C;
}
.delivery_outletpoup .outlet-scooter-img {
  width: 86px;
}

.outleterror {
  color: #FF1B2C;
}
.delete-act-icon {
  position: absolute;
  right: 9px;
  top: 1px;
}
.act-remove-topdiv {
  position: relative;
  padding-right: 20px;
}

.venue_popup .close-icon-but {
  background: #98684a;
  color: #fff;
  font-size: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  top: -15px;
  right: -15px;
  padding: 0;
  margin: 0;
}
.hide_section_cls,
.back_click_mbl {
  display: none;
}
.back_click_mbl {
  display: block;
}
.back_click_mbl a:before {
  content: "\f104";
  font-family: FontAwesome;
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
}
.custom_center_alertcls {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  max-width: 350px;
  margin: auto !important;
  padding: 20px;
  font-size: 17px;
  border-radius: 10px;

  display: inline-block;
  text-align: center;
}
.custom_center_alertcls.alert-danger {
  color: #ca322f;
  background-color: #fdd1d1;
}
.mfp-custom-close {
  position: absolute;
}
img.view-img-span {
  width: 20px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
img.close-img-span {
  width: 19px;
  position: relative;
  top: 1px;
  margin-left: 5px;
  display: none;
}
.chk-payment .form-group.custom-tarms-div {
  padding: 0 20px 20px;
  text-align: left;
}
.text-lbl {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li {
  margin-right: 0;
}
.price_nor dl {
  display: inline-block;
  font-size: 16px;
  vertical-align: text-top;
  font-family: "Poppins";
  text-transform: uppercase;
}

.package_info_desc a {
  color: #000;
}
.back-to-pkglist {
  background: #FF1B2C;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  position: relative;
  border-radius: 5px;
  padding: 8px 15px;
  margin: 0;
  cursor: pointer;
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 5px;
}

.back-list-also {
  -webkit-align-items: flex-start !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.timeslot_info {
  font-size: 13px;
  color: #f20000;
  margin-bottom: 15px;
}
.timeslot_info span {
  display: block;
}
.ordrdatetime_error .error {
  width: 100%;
}
.syd_merge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 2px;
}
.syd_date {
  flex: 1 43%;
  width: 43%;
  min-width: 150px;
  max-width: 43%;
}
.syd_time.delivery_submit_cls {
  flex: 1 43%;
  width: 43%;
  min-width: 150px;
  max-width: 55%;
}
.syd_merge .form-group {
  margin: 0;
}

.checkout-control-group-middle .react-datepicker-wrapper {
  display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
  position: relative;
  display: block;
}
.datetime_selt_sec .react-datepicker-wrapper,
.datetime_selt_sec .react-datepicker__input-container {
  display: block;
}

.membership_status {
  margin-top: -33px;
  position: relative;
}

.membership_status span {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  text-transform: uppercase;
  font-family: "Poppins";
  font-size: 18px;
}

/*myaccount - 13 jan 2020*/
.membership-desc {
  background-color: #FF1B2C;
  border: 2px solid #fff;
  padding: 12px 5px 10px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Poppins";
  margin: 15px auto;
  max-width: 250px;
}
.membership-desc img {
  width: 20px;
  margin-right: 8px;
}
.user-id {
  margin: 10px 0;
}
.user-id span {
  text-transform: uppercase;
  font-weight: bold;
}
.membership-progress {
  background: #4f7873;
  margin: 15px -10px 0;
  padding: 20px 0 15px;
}
.membership-progress p {
  font-weight: bold;
  margin: 9px auto 0;
  max-width: 170px;
  line-height: 1.2;
}
.pg-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 15px;
  border-radius: 25px;
  background-color: #2a2521;
  max-width: 175px;
  margin: 0 auto;
}
.pg-bar span {
  position: absolute;
  height: 100%;
  width: 0%;
  background-color: #fff;
  top: 0;
  left: 0;
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}
.member-discount-desc {
  display: inline-block;
  background: #FF1B2C;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
  margin: 5px 0;
  line-height: 1;
  text-align: center;
}
.member-discount-total {
  color: #98684a;
  font-weight: bold;
  font-size: 14px;
}

.home-outlets-list .slick-slider .slick-next:after,
.home-outlets-list .slick-slider .slick-prev:after,
.addonpro-slider-top .slick-slider .slick-next:after,
.addonpro-slider-top .slick-slider .slick-prev:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 25px;
  height: 18px;
  bottom: 0;
  right: 0;
  margin: auto;
}
.home-outlets-list .slick-slider .slick-next:after,
.addonpro-slider-top .slick-slider .slick-next:after {
  background: url(../images/next-icon.png) 0 0px no-repeat;
  background-size: contain;
}
.home-outlets-list .slick-slider .slick-prev:after,
.addonpro-slider-top .slick-slider .slick-prev:after {
  background: url(../images/previous-icon.png) 0 0px no-repeat;
  background-size: contain;
}
.home-outlets-list .slick-arrow.slick-disabled,
.addonpro-slider-top .slick-arrow.slick-disabled {
  opacity: 0.6;
  cursor: default;
}

.home-outlets-list .slick-slider,
.addonpro-slider-top .slick-slider {
  padding: 0 40px;
}
.slick-slider .slick-dots {
  display: none !important;
}
.outlets-address-line {
  text-transform: capitalize;
}
.outlets-info h4 {
  margin-bottom: 10px;
}
.center-header {
  padding: 0 0 30px 0;
}
.center-header h2 {
  margin: 0;
}
.home-outlets-list .center-header {
  padding: 0 0 5px 0;
}
.featured-products-main {
  text-align: center;
}
.featured-products-main > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
}
.featured-products-main > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 23.5%;
  margin: 0 2% 20px 0;
  -webkit-box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);
  background: #fff;
}
.featured-products-main > ul > li:nth-child(4n) {
  margin-right: 0;
}
.featured-products-main > ul > li .product-title h3,
.productlist-main-div .products-list-ulmain li .product-title h3,
.ems-inner .product-title h3 {
  font-size: calc(14px + (20 - 16) * ((100vw - 767px) / (1600 - 767)));
  margin: 0;
  /*padding: 3px 0px;*/
    /*height: 43px;*/
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-tag-list ul {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-tag-list ul li {
  margin: 0 5px;
  border: 1px solid #000;
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 8px;
  text-transform: capitalize;
  color: #000;
  line-height: 1;
}
.product-title {
  margin: 0 0 5px 0;
}

.products-image-div {
  max-width: 40%;
  width: 100%;
  min-height: 197px;
  height: 100%;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff!important;
}
.product-info-div {
  padding: 5px 10px 10px;
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.product-info-div .product-footer-main {
  flex-grow: 1;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-flow: row wrap;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}
.product-info-div .product-footer-main .product-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-info-div .product-footer-main .addcart_row .btn {
  flex: 1 1 auto;
  min-width: 75px;
  width: 75px;
  margin: 2px;
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 15px;
  color: #fff;
  height: 30px;
  line-height: 1;
  padding: 3px;
  border: none;
  cursor: pointer;
  background: #ff211f;
  text-transform: capitalize;
}
.product-info-div .product-footer .product-price { }
.product-info-div .product-footer .products-ordernow-action {
  text-align: right;
  right: 0; line-height: 0;
}
.product-info-div .product-footer .products-ordernow-action .qty_bx span {
  position: relative;
  width: 20px;
}
.product-info-div
  .product-footer
  .products-ordernow-action
  .addcart_row
  .qty_bx {
    flex: 1 1 auto;
    min-width: 70px;
  position: relative;
  width: inherit;
  vertical-align: middle;
  width: auto;
  height: 30px;
  padding: inherit;
  display: flex;
  justify-content: center;
}
.product-info-div
  .product-footer
  .products-ordernow-action
  .qty_bx
  span.qty_minus {
  left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0;
}
.product-info-div
  .product-footer
  .products-ordernow-action
  .qty_bx
  span.qty_minus:after {
  left: 4px;
  background: url(../images/minus.svg) no-repeat;
  height: 1px;
  margin-top: -1px;
  width: 10px;
}
.product-info-div .product-footer .products-ordernow-action .qty_bx input {
  width: 26px;
  left: 0;
  position: relative;
  border-radius: 0px;
  font-size: 14px;
  padding: 0px;
  margin: 0;
  border: 1px solid #e9e9e9;
  border-left: none;
  border-right: none;
}
.product-info-div
  .product-footer
  .products-ordernow-action
  .qty_bx
  span.qty_plus {
  right: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0;
}
.product-info-div
  .product-footer
  .products-ordernow-action
  .qty_bx
  span.qty_plus:after {
  right: 3px;
  background: url(../images/plus.svg) no-repeat;
  width: 11px;
  margin-top: -5px;
}
.product-info-div .product-footer .addcart_row {
  padding: 0 1px;
}
.product-info-div .product-footer .addcart_row .addcart_row_inner {
  display: flex;
  justify-content: flex-end;
  position: relative;
  flex-flow: row wrap;
  padding: 2px;
  margin-bottom: 5px;
}
.restpage-main-div .productlist-main-div .products-list-ulmain > li .product-info-div {
  display: flex;
  flex: 1 1 60%;
  max-width: 60%;
}
.products-image-div img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.products-image-div img.no_product {
  object-fit: scale-down;
}
.product-title-maindiv {
  /*padding: 0 0 8px 0;*/
  padding: 0 0 0px 0;
}

.product-tag-list {
}
.product-short-description {
  color: #949292;
  font-size: 14px;
  /*padding: 10px 0;*/
  padding: 0px;
}
.product-price h3 {
  margin: 0;
  font-size: calc(16px + (20 - 16) * ((100vw - 767px) / (1600 - 767)));
}
.products-ordernow-action .button {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 12px;
  letter-spacing: 0.35px;
  text-transform: capitalize;
  background: #ff211f;
}
.products-ordernow-action .button:hover {
  background: #000;
}
.products-ordernow-action {
  margin: 0 0 0 0;
  position: relative;
  right: 20px;
}
.product-short-description p {
  margin: 0;
  color: #000000;
  /*max-height: 40px;*/
  font-family: "Poppins", sans-serif;
    /*height: 40px;*/
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.products-single-li {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.featured-products-section h2 {
  text-align: center;
}
.products-ordernow-action .button.disabled,
.products-ordernow-action .button.disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  pointer-events: none;
}

.hmenu-login-section ul {
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.hmenu-login-section
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  display: inherit;
}
.hmenu-login-act .htico_cart li {
  margin: 0 0px;
}
.controller-nav.active::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #FF1B2C;
  transition-delay: 3s;
  top: 32px;
}
.hmenu-login-section ul .mobile-login-list.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}
.hmenu-login-section ul .mobile-login-list {
  padding: 0;
  list-style: none;
  position: absolute;
  top: 40px;
  right: 0;
  background: #FF1B2C;
  border-radius: 6px;
  min-width: 150px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  z-index: 5;
  display: block;
}
.hmenu-login-section ul .mobile-login-list li {
  margin: 0;
}
.hmenu-login-section ul .mobile-login-list li a {
  display: block;
  line-height: 1;
  padding: 10px 20px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}
.doorstep-block-section {
  position: relative;
}

.guest-checkout-popupdiv {
  background: #ededed;
  margin: 0 -55px;
  padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
  margin: 0;
  background: #464646;
  display: block;
  width: 100%;
  padding: 17px 5px;
  font-family: "Poppins";
}
.signup-gender-selct {
  margin-bottom: 0;
}
span.birthday_info_sp {
  font-size: 12px;
  color: #737373;
}

span.birthday_info_sp b {
  font-weight: 500;
  font-style: italic;
}
.product-menu-listing {
  padding: 0px 0 10px 0;
}
/*
.product-menu-listing .container { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
.product-menu-listing .container:before, .product-menu-listing .container:after{ display: none;}
*/
.product-menu-listing .product-brd {
  position: relative;
  background: url("../images/breadcrum-img-1.jpg");
  background-size: cover;
  text-align: center;
  color: #fff;
  display: flex;
  flex-flow: column;
  padding: 20px;
  height: auto;
}
.product-menu-listing .product-brd h1 {
  color: #fff;
  margin-bottom: 5px;
}
.product-menu-listing .product-brd p {
}
.productlist-main-div {
  width: 100%;
}
.menu-section-left {
  width: 300px;
  position: relative;
}
.productlist-main-div .products-list-ulmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-start;
}
.productlist-main-div .products-list-ulmain > li {
  text-align: center;
  min-width: 200px;
  width: 48%;
  margin: 9px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);
  transition: 0.4s linear all;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-height: 198px;
}
.productlist-main-div .products-list-ulmain > li:nth-child(4n) {
  margin-right: 0;
}
.productlist-main-div .products-list-ulmain > li.active,
.productlist-main-div .products-list-ulmain > li:hover,
.featured-products-main > ul > li:hover {
  box-shadow: 0px 3px 28px 0px rgba(0, 0, 0, 0.38);
}

.product-menu .menu-list-intvl a {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
  display: block;
  text-align: center;
  height: 100%;
  background: #ffffff;
}
.product-menu .menu-list-intvl a:hover {
  background: #000000;
  color: #ffffff;
}
.product-menu .menu-list-intvl.active a {
  background: #000000;
}
.product-menu .menu-title-txt {
  color: #0a0b10;
  text-transform: capitalize;
  font-size: 14px;
  line-height: normal;
  display: block;
}
.product-menu .menu-list-intvl a:hover .menu-title-txt,
.product-menu .menu-list-intvl.active .menu-title-txt {
  color: #ffffff;
}
.product-menu .menu-list-intvl.active a:before,
.product-menu .menu-list-intvl a:hover:before,
.more-menu-parent.active:before,
.more-menu-parent:hover:before {
  width: 100%;
  left: 0;
}
.more-menu-parent {
  color: #0a0b10;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  line-height: normal;
  cursor: pointer;
  padding: 10px 0px 10px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-menu-parent:hover,
.more-menu-parent:hover label,
.more-menu-parent.active,
.more-menu-parent.active label {
  color: #FF1B2C;
}
.progress_bar_div {
  margin-top: 10px;
}
.common-inner-banner {
  position: relative;
  padding: 45px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  line-height: 1;
}
.common-inner-banner h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
}
.common-inner-banner p {
  color: #fff;
  margin-top: 10px;
}

.common-inner-blckdiv {
  position: relative;
  background: url("../images/checkout-background.jpg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  will-change: transform;
  min-height: 175px;
}
.sub-nav-closed {
  height: inherit;
}
.common-inner-blckdiv .client-info-tech {
  bottom: 50px;
  top: inherit;
}
.prodet_bansec {
  padding: 40px;
}
.prodet_bansec .inn-product-img-bg {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.prodet_bansec .inn-product-img-bg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.productpage-main-div .prodet_bansec {
  padding: 0px;
}

.prodet_top {
  text-align: center;
}
.prodetailinner-main-div .modal-body {
  padding: 0;
}
.detail-pg-slider .slick-track .slick-slide > div {
  margin: 30px 7px;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
  box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.detail-pg-slider .product-title h3 {
  font-size: 21px;
  margin: 0;
}
.addonpro-slider-top {
  margin: 70px 0 0px 0;
}
.chk-payment-title h3,
.chk-addonpro-title h3 {
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  text-align: center;
}

.chk-payment-title {
      border-bottom: 1px solid #f4f4f4;
    margin-bottom: 30px;

}

.detail-pg-slider .product-tag-list {
  bottom: -8px;
}
.chk-addonpro-title h3 {
  margin-bottom: 0px;
}
.addcart_row:after {
  clear: both;
  content: "";
  display: table;
  overflow: hidden;
}
.birthday-info-div {
  padding: 50px 20px 10px 20px;
  text-align: center;
}
.birthday-info-top h3 {
  font-size: 43px;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-top: 10px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.birthday-info-top h4 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  right: 15px;
  bottom: -5px;
}
.birthday-info-top h3 img {
  margin: 0px 0 0 15px;
  position: relative;
  top: -5px;
  right: -10px;
}
.birthday-info-middle {
  padding: 10px 0 0 0;
  font-size: 17px;
  font-weight: 600;
  color: #000;
}
.birthday-info {
  text-align: center;
  margin-bottom: 20px;
}
.left-input-div,
.right-input-div {
  max-width: 320px;
  width: 100%;
}
.birthday-inpt-act {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 650px;
  margin: 0 auto;
}
.narml_select {
  position: relative;
}
.narml_select:after {
  width: 30px;
  height: 46px;
  content: "";
  position: absolute;
  right: 1px;
  background: #fff;
  top: 1px;
}
.left-input-div,
.right-input-div select.form-control {
  color: #555555;
  font-size: 15px;
  font-weight: 400;
  -webkit-appearance: none;
}
.normal-popup {
  max-width: 680px;
  text-align: center;
}
.normal_popup_cont ul {
  padding: 0;
  list-style: none;
}
.normal-popup button.mfp-close {
  background: #FF1B2C;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  line-height: 1;
}

.promopopup-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#promo-check-popup {
  max-width: 810px;
  background: none;
}
.promopopup-rhs {
  width: 53.08641975308642%;
  border: 1px solid #FF1B2C;
}
.promopopup-lhs {
  width: 46.91358024691358%;
  border-top: 30px solid rgba(0, 0, 0, 0);
  border-bottom: 30px solid rgba(0, 0, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.frm-action-div {
  background: #FF1B2C;
  color: #fff;
  padding: 40px 30px;
}
.promopopup-rhs img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.promopopup-lhs .frm-top-title h3 {
  color: #fff;
  margin-bottom: 0px;
}
.promopopup-lhs .frm-inputbtn-div {
  position: relative;
}
.promopopup-lhs .frm-inputbtn-div .button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  height: 48px;
  background: #000;
  font-size: 15px;
  padding: 10px 14px 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border: 1px solid #fff;
}
.promopopup-lhs .form-group input {
  border-color: #fff;
  padding-right: 110px;
}
.promopopup-lhs .frm-bottom-text {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}
.promopopup-lhs .frm-bottom-text p {
  margin: 0;
}
.promopopup-lhs .form-group {
  margin-bottom: 5px;
}
#promomail-error {
  color: #b70000;
  line-height: 1;
  font-size: 13px;
}
.promo-check-popup button.mfp-close {
  background: #FF1B2C;
  opacity: 1;
  color: #fff;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  line-height: 1;
  top: -15px;
  right: -15px;
}

.prodetailinner-main-div button.close,
.sharegroup-popup button.close {
  width: 30px;
  height: 30px;
  background: #000;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  top: 5px;
  right: 5px;
  text-indent: -9999px;
}
.prodetailinner-main-div button.close:after,
.sharegroup-popup button.close:after {
  background: url(../images/plus-white.svg) no-repeat;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#ProductDetailMdl .modal-content {
  border-radius: 0;
}

@media (max-width: 1300px) {
  .menu-section-innerr {
    max-height: 430px;
  }
  .syd_merge {
    flex-flow: row wrap;
    flex-flow: inherit;
    gap: 5px;
  }
  .syd_date {
    flex: 1 35%;
    width: 35%;
    min-width: 150px;
    max-width: 45%;
  }
  .syd_time.delivery_submit_cls {
    flex: 1 35%;
    width: 35%;
    min-width: 150px;
    max-width: 52%;
  }
}
@media (min-width: 1400px) {
  .menu-section-inner {
    max-height: 530px;
  }
}
@media (min-width: 1601px) {
  .menu-section-inner {
    max-height: 665px;
  }
}
@media (min-width: 1850px) {
  .menu-section-inner {
    max-height: 788px;
  }
}
.addcart_done_maindiv {
  margin-bottom: 5px;
}
.mbl-hiden {
  overflow-y: hidden;
}
.htico_search_mbl {
  display: none;
}

.mobile-menu {
  position: fixed;
  padding: 0;
  overflow: hidden;
  background-color: #FF1B2C;
  height: 100vh;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, transform 0.3s ease-in-out;
  transition: opacity 0.2s linear, transform 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 99999;
  max-width: 520px;
}

.mobile-menu .menu {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
}

.mobile-menu .menu li {
  list-style: none;
  position: relative;
}

.mobile-menu .mobile-menu-body {
  overflow: hidden;
  overflow-y: auto;
  padding: 80px 40px 40px;
  height: 100%;
}

.mobile-menu.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu.active .menu a {
  -webkit-animation: a 0.4s 0.2s backwards;
  animation: a 0.4s 0.2s backwards;
}

.mobile-menu.active .menu li:nth-of-type(2) a {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.mobile-menu.active .menu li:nth-of-type(3) a {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.mobile-menu.active .menu li:nth-of-type(4) a {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.mobile-menu.active .menu li:nth-of-type(5) a {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.mobile-menu.active .menu li:nth-of-type(6) a {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.mobile-menu.active .menu li:nth-of-type(7) a {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.mobile-menu .toggle-submenu-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  display: block;
  height: 60px;
  cursor: pointer;
}

.mobile-menu .toggle-submenu-button:before {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
  background: #000;
  display: block;
  height: 2px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
    -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 14px;
  z-index: 1;
  content: "";
}

.mobile-menu .toggle-submenu-button:after {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
  transform: rotate(1turn);
  -webkit-transform: rotate(1turn);
}

.mobile-menu .mobile-menu-logo {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.mobile-menu .mobile-menu-close {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  order: 2;
}

.mobile-menu .mobile-menu-close span {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 22px;
  position: relative;
}

.mobile-menu .mobile-menu-close span:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
  background: #fff;
  display: block;
  height: 2px;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s,
    -webkit-transform 0.25s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  width: 24px;
  z-index: 2;
  content: "";
}

.mobile-menu .mobile-menu-close span:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mobile-menu .mobile-menu-header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 0.0625rem solid #dbd9d2;
  position: fixed;
  top: 0;
  right: 40px;
  left: 40px;
  height: 80px;
  background: #FF1B2C;
  z-index: 2;
}
.mobile-menu-logo {
  max-width: 240px;
}
.mobile-menu .menu a {
  display: block;
  font-size: 18px;
  color: #fff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-family: "Poppins", sans-serif;
  padding: 1.3rem 0;
  line-height: 1;
  text-transform: capitalize;
}

.mobile-menu .menu a:hover {
  color: #231f20;
}

.mobile-menu .menu .sub-menu {
  display: none;
  padding: 0;
}

.mobile-menu .menu .sub-menu li {
  padding-right: 0;
}

.mobile-menu .menu .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu .menu .sub-menu a {
  font-family: "melbourneregular";
}

.mobile-menu .menu li {
  border-bottom: 0.0625rem solid #dbd9d2;
  padding-right: 40px;
}

@-webkit-keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.pkselect_tabsec .tab-content {
  background: #f9f7f8;
  padding: 0px 25px 20px;
}
.rest-list-cls {
  margin-top: 20px;
}
.li-full-width {
  width: 100% !important;
}
.btn-act-clss {
  color: #fff;
  background: #000;
  border: 1px solid #FF1B2C;
}
#delivery-continue-link,
#takeaway-continue-link {
  display: none;
}

/* omise */
.omise-form-error input {
  -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0),
    0 0 2px 0 rgba(255, 0, 0, 0.5);
  border-color: #ff7076;
  border-top-color: #ff5c61;
}

.pay-omiseconf-popup {
  max-width: 340px;
  border-radius: 10px;
  background: #f5f5f7;
}
span.omise-pay-img {
  display: block;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  margin: 0 auto;
  border: 4px solid #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -39px;
  left: 0;
  right: 0;
}

img.makisan-logo-img {
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: none;
}

.omise_pay_header {
  position: relative;
  padding: 50px 30px 25px;
  text-align: center;
  border-bottom: 1px solid #d2d2d3;
}
.omise_pay_body {
  padding: 30px;
  position: relative;
  border-top: 2px solid #fff;
}
.omisepop_in .form-control {
  padding: 10px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 36px;
}
.expire_row_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.expire_row_inner > div {
  width: 32%;
  position: relative;
}
.omisepop_in .alt_btns .button {
  margin-bottom: 0;
  width: 100%;
  padding: 13px 10px 11px;
  border-radius: 4px;
}
.omisepop_in .form_sec i {
  position: absolute;
  left: 10px;
  color: #888;
  top: 12px;
}
.omisepop_in .form_sec > div {
  position: relative;
}
.omisepop_in .form_sec .uname-box i {
  left: 13px;
}
.omisepop_in .form_sec .card-numbr-div i,
.omisepop_in .form_sec .expire_left i {
  font-size: 16px;
  top: 13px;
}
.omisepop_in .form_sec .expire_right i {
  font-size: 19px;
  top: 12px;
}
.expire_mdl .form-control {
  padding-left: 15px;
}
.card {
  position: absolute;
  display: block;
  right: 10px;
  width: 28px;
  height: 19px;
  background: url(../images/cards/spritecard.png) no-repeat 0;
  top: 10px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  background-position: 0 0;
  overflow: hidden;
}
.visa + .card {
  background-position: 0 -19px;
}
.master + .card {
  background-position: 0 -38px;
}
.discover + .card {
  background-position: 0 -57px;
}
.american + .card {
  background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
  padding: 0;
  width: 21px;
  height: 21px;
  display: block;
  color: #fff !important;
  border-radius: 50%;
  line-height: 21px;
  background: #a5a5a5;
  border: 1px solid #929292;
  top: 10px;
  font-size: 20px;
  right: 10px;
  text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover {
  background: #000;
  border-color: #000;
}
.omisepay-mode-option {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #ffd025;
  line-height: 1;
  padding: 9px 11px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
}
.omise-error-info-div {
  color: #ffffff;
  background-color: #b12f2f;
  border-color: #ebccd1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  line-height: 1;
}
.omise-error-icon {
  font-size: 14px;
}
.omise_alert_close {
  position: absolute;
  right: 0px;
  color: #fff;
  top: 0;
}
.omise-error-msg {
  margin: 0 0 0 11px;
  font-size: 14px;
}
.omise-error-info-div .container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}
.displayDivCls {
  display: none;
}
.white-logo-img {
  padding-bottom: 20px;
}
.white-logo-img img {
  max-width: 300px;
  margin: 0 auto;
}
.white-home {
  padding: 0px;
  position: relative;
  z-index: 2;
}
/*
.home-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
*/

.home-banner .slick-dots {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  bottom: 80px;
  right: 20%;
  text-align: center;
}
.home-banner .slick-dots li button {
  padding: 0;
  margin: 0 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -9999px;
  background: #FF1B2C;
}
.home-banner .slick-dots li {
  display: inline-block;
}
.home-banner .slick-dots li.slick-active button {
  background: #000;
}
.bann-container {
  z-index: 3;
  position: relative;
}
.white-home .slick-slider,
.white-home .slick-list,
.white-home .slick-track,
.white-home .slick-track .slick-slide div {
  height: 100%;
}
.white-home .slick-arrow {
  display: none !important;
}
.wh-lhs {
  max-width: 570px;
}
.wh-lhs-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.wh-lhs-bottom ul li {
  text-align: center;
  width: 48%;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.11);
  background: #fff;
  position: relative;
}
.wh-lhs-bottom ul li:before {
  content: "";
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid #FF1B2C;
  position: absolute;
}
.wh-lhs-bottom ul li:hover:before,
.wh-lhs-bottom ul li.active:before {
  width: 100%;
  height: 100%;
}

.hero-top-img img {
  width: 100%;
}
.hero-top-desc {
  padding: 25px 15px 25px;
}
.hero-top-desc h4 {
  text-transform: uppercase;
  font-size: 25px;
  margin: 0 0 10px 0;
}
.hero-top-desc .button {
  margin: 0 auto;
}
.wh-lhs-top h2 {
  font-family: "melbourneregular";
  letter-spacing: 1px;
}
.abt-home {
  padding: 30px 0 70px 0;
  position: relative;
  z-index: 2;
}
.abt-home:before {
  background: url(../images/abtbg.png) no-repeat center;
  position: absolute;
  left: 0;
  top: -55px;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background-size: cover;
}
.abt-lhs {
  width: 50%;
}
.abt-rhs p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 17px;
}
.abt-rhs {
  width: 50%;
}
.abt-rhs h3 {
  font-family: "melbourneregular";
  font-size: 40px;
}

.abt-home .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.abt-home .container:before,
.abt-home .container:after {
  display: none;
}
.around-time span {
  display: block;
  line-height: 1;
  font-weight: 700;
  padding-top: 3px;
}
.around-time {
  border: 1px solid #f7c30d;
  position: absolute;
  right: 10px;
  top: -32px;
  background: #fafafa;
  padding: 7px 15px;
  line-height: 1;
  border-radius: 25px;
  min-width: 98px;
  text-align: center;
}
.restpage-main-div .productlist-main-div .products-list-ulmain > li {
  text-align: left;
  cursor: pointer;
  height: 235px;
}
.product-title span {
  color: #949292;
  font-size: 14px;
  /*font-style: italic;*/
  font-weight: 300;
  font-family: 'Poppins';
  display: inline-block;
  margin: 0px 3px 5px 0;
}
.distance-merge {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #949292;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 11px;
  padding-top: 10px;
}
.product-info-div .promo span:nth-child(4n) {
  margin-right: 0;
}
.product-info-div .promo {
  display: flex;
  flex-wrap: wrap;
}
.product-info-div .promo span {
  display: block;
  font-weight: 700;
  border: 1px solid #f7c30d;
  border-radius: 5px;
  padding: 7px 2px;
  line-height: 1;
  text-align: center;
  font-size: 14px;
  width: 23.828125%;
  margin: 5px 1.5625% 0 0;
}
.product-info-div .qty_bx input {
  width: 33px;
  left: -5px;
  position: relative;
  border-radius: 0px;
}
.cart-oulet-title {
  margin: 20px 5px 15px;
}
.distance-merge .distance,
.restaurant-other-info .distance span {
  position: relative;
  padding: 0 0 0 30px;
}
.distance-merge .distance:before,
.restaurant-other-info .distance span:before {
  position: absolute;
  left: 0;
  top: -3px;
  width: 22px;
  height: 22px;
  content: "";
  background: url(../images/away-map.png) no-repeat;
  filter: brightness(0) invert(1);
}
.search-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 15px;
}
.search-product > .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.search-product > .container:before,
.search-product > .container:after,
.menu-top-section-inner .container:before,
.menu-top-section-inner .container:after {
  display: none;
}
.search-product .custom_alertcls {
  position: absolute;
  right: 0;
  top: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
}
.search-product .custom_alertcls:after {
  bottom: 100%;
  right: 45%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #f2dede;
  border-width: 6px;
}
.search-product .custom_alertcls p {
  margin: 0;
}
.spm-lhs {
  position: relative;
}
.spm-lhs .search-rest {
  padding-right: 45px;
  border: 0;
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.1);
  height: 52px;
  padding-left: 18px;
  font-size: 18px;
  font-family: "melbourneregular";
  margin-bottom: 0;
}
.spm-lhs img {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 21px;
}
.spm-lhs {
  width: 380px;
}
.spm-rhs {
  width: 162px;
}
.spm-rhs .filter-area {
  padding-right: 45px;
  border: 0;
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.1);
  height: 52px;
  padding-left: 18px;
  font-size: 18px;
  font-family: "melbourneregular";
  padding: 12px 40px 12px 18px;
  position: relative;
  width: 100%;
  background: #fff;
  cursor: pointer;
  cursor: pointer;
}

.spm-rhs {
  width: 162px;
  margin-left: 10px;
}
.spm-lhs .search_result ul {
  padding-bottom: 0;
}
.filter-area img {
  position: absolute;
  right: 15px;
  top: 16px;
  width: 17px;
}
.filter-area img.filter-clear-image {
  width: 12px;
  top: 20px;
}
.search-product-main {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.breadcrumb-sec {
  margin: 10px 0;
}
.breadcrumb-sec ul {
  padding: 0;
  list-style: none;
}
.breadcrumb-sec li {
  display: inline-block;
  margin: 0 5px 0 0;
}
.restaurant-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.restaurant-info-lhs {
  width: 55%;
  min-height: 220px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.restaurant-info-rhs {
  width: 35%;
}
.restaurant-info-lhs h3 {
  font-family: 'Poppins';
    font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.restaurant-info-lhs .restaurant-tag span {
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  display: inline-block;
  margin: 5px 1px 0 0;
}
.social-media a {
  color: #3c3e40;
}
.social-media > a {
  margin: 0 12px 0 0;
  font-size: 20px;
}

.social-media span {
  margin: 0 22px 0 0;
  display: inline-block;
}
.social-media span i {
  margin-right: 3px;
}
.social-media span a {
  border-bottom: 1px solid #c5c5c6;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  vertical-align: text-bottom;
}
.social-media a:hover {
  color: #FF1B2C;
}
.restaurant-info-rhs > img {
  border-radius: 8px;
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
}
.working-hr {
  margin: 5px 0 10px 0;
}
.restaurant-other-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.restaurant-other-info strong {
  display: block;
  font-weight: 600;
  text-align: center;
}
.restaurant-other-info > div {
  text-align: center;
  width: 130px;
  height: 50px;
  background: transparent;
  margin: 0 10px 10px 0;
  line-height: 1.2;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.restaurant-promo span {
  display: inline-block;
  font-weight: 700;
  border: 1px solid #f7c30d;
  border-radius: 5px;
  padding: 7px 2px;
  line-height: 1;
  text-align: center;
  font-size: 14px;
  min-width: 70px;
  margin: 5px 3px 0 0;
}
.restaurant-promo strong {
  margin-right: 10px;
}
.menu-top-section-inner .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-top-section-inner {
  margin: 10px auto 10px;
  position: relative;
  z-index: 9;
  padding: 0;
}
.menu-top-section-inner ul.product-menu {
  display: block;
  list-style: none;
  padding: 0;
  width: 100%;
}
.menu-top-section-inner ul.product-menu li {
  margin: 0 0.5% 0 0;
}
.menu-top-section-inner ul.product-menu li:last-child {
  margin-right: 0;
  margin-bottom: 15px;
}
.do-or-die {
  margin: 0 0 40px 0;
}
.innerproduct_row .category-product-list {
  margin: 0 0 40px 0;
}
.greenwith-add img {
  margin: 0 8px 0 0;
  max-width: 22px;
}
.greenwith-add {
  padding: 10px 0 0 0;
}
label.open_more_category img {
  width: 20px;
  position: relative;
  top: -2px;
}
.innerproduct {
  margin-bottom: 10px;
}

label.open_more_category {
  width: 40px;
  text-align: right;
  position: relative;
  cursor: pointer;
  font-size: 29px;
}
.open_more_category i {
  position: relative;
  top: 2px;
}

.more-menu {
  position: relative;
  width: 170px;
}

ul.more_categor_info {
  padding: 0;
  list-style: none;
  min-width: 200px;
  max-width: 280px;
  background: #fff;
  position: absolute;
  right: 0;
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 3px;
  top: calc(100% + 5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
ul.more_categor_info li a {
  display: block;
  padding: 12px 20px;
  border-bottom: 1px solid #f3f3f3;
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  color: #000;
}
ul.more_categor_info li:last-child a {
  border-bottom: 0;
}

/* 
.syd_merge input[type="text"]{    font-weight: 700; color: #000; text-align: center}
*/

.breadcrumb-sec span {
  display: inline-block;
  margin: 0px 1px 0 6px;
}
.filter-details {
  position: absolute;
  z-index: 9;
  width: 100%;
  left: 0;
  top: 105%;
  display: none;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 20px;
}
.filter-details.active {
  display: block;
}
.common-filter {
  padding-bottom: 20px;
}
.common-filter:last-child {
  padding-bottom: 0;
}
.common-filter ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.common-filter ul li {
  width: 23.828125%;
  margin: 0px 1.5625% 15px 0;
  cursor: pointer;
}
.common-filter ul li:nth-child(4n) {
  margin-right: 0;
}
.common-filter h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 17px;
  margin: 0 0 15px 0;
}
.common-filter.item-offer ul li {
  display: block;
  font-weight: 700;
  border: 2px solid #FF1B2C;
  border-radius: 5px;
  padding: 10px 5px;
  line-height: 1;
  text-align: center;
}
.clear-filter {
  position: absolute;
  right: 20px;
  top: 25px;
  line-height: 1;
  font-size: 12px;
}

.product-lo-top a {
  display: inline-block;
  padding: 13px 20px;
  font-family: "Poppins";
  font-size: 19px;
  color: #b4b3b3;
  line-height: 1;
  text-transform: uppercase;
  background: #e7e6e6;
  min-height: 46px;
  position: relative;
  width: calc(50% - 11px);
  text-align: center;
}
.product-lo-top a:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  float: left;
  padding-right: 1px;
}
.product-lo-top a:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  float: right;
  padding-left: 1px;
}

.product-lo-top a:first-child:after,
.product-lo-top a:last-child:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
}
.product-lo-top a:first-child:after {
  left: 100%;
  border-top: 46px solid #e7e6e6;
  border-right: 23px solid transparent;
}
.product-lo-top a:last-child:before {
  right: 100%;
  border-bottom: 46px solid #e7e6e6;
  border-left: 23px solid transparent;
}
.new-header-first .hcart-txt-line {
  font-weight: 600;
  border-bottom: 1px solid #000;
  color: #000;
  display: inline-block;
  padding-bottom: 0;
  margin-bottom: 15px;
}
.product-lo-top a.active {
  color: #fff;
  background: #FF1B2C;
}
.product-lo-top a.active:last-child:before {
  border-bottom-color: #FF1B2C;
}
.product-lo-top a.active:first-child:after {
  border-top-color: #FF1B2C;
}
.product-lo-top {
  min-width: 230px;
}
.new-header-first {
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #f4f4f4;
}
.new-header-first h4 {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
}
.new-header-second-inner h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
.new-header-second-inner h5 span {
  display: inline;
  border: none;
  width: auto;
}
.hcart_dropdown .chk_only {
    display: block !important;
    padding: 0;
    margin-bottom: 5px;
}
.new-header-first p,
.new-header-second-inner span {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}
.new-header-second-inner span {
  border-bottom: none;
  border: 1px solid #f4f4f4;
  padding: 10px 8px;
}
.new-header-second {
  text-align: center;
  padding-bottom: 0;
}
.new-header-second-inner p {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  padding: 0;
}
.new-header-second-inner p .flex-new-header {
  flex: 1 50%;
  min-width: 50px;
  max-width: 50%;
  padding: 2px;
}
.new-header-second-inner .syd_date {
  flex: 1 1 43%;
  width: 43%;
  min-width: 140px;
  max-width: 50%;
}
.new-header-second-inner .syd_time.delivery_submit_cls {
  flex: 1 1 43%;
  width: 43%;
  min-width: 100px;
  max-width: 50%;
}
.new-header-second-inner span {
  display: block;
  width: 100%;
}
.hib {
  border: 1px solid #efefef;
  padding: 15px 20px;
  line-height: 1;
  font-size: 14px;
  margin-right: 7px;
}
.hib:last-child {
  margin-right: 0;
}
.logo {
  order: 1;
}
.headerinfo-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 40px;
}
.menu-right {
  order: 2;
  min-width: 50%;
  display: flex;
  justify-content: flex-end;
}

.headerinfo-boxes.header-menu ul {
  padding: 0;
  margin: 0;
}

.headerinfo-boxes.header-menu ul li {
  margin: 0 24px;
  list-style-type: none;
  display: inline-block;
}

.headerinfo-boxes.header-menu ul li:last-child {margin-right: 45px;}

.headerinfo-boxes.header-menu ul li a {
  color: #FFF1D8;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.headerinfo-boxes.header-menu ul li a::after {
  position: relative;
  content: "";
  display: block;
  height: 1px;
  background: #FFF1D8;
  bottom: -5px;
  width: 0%;
  transition: all 0.2s linear;
}
.headerinfo-boxes.header-menu ul li a:hover::after {
  position: relative;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #FFF1D8;
  bottom: -5px;
  transition: all 0.2s linear;
}
.headerinfo-boxes.header-menu ul li.active a::after {
  position: relative;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #FFF1D8;
  bottom: -5px;
  transition: all 0.2s linear;
}
.hmenu-login-act li {
  margin: 0 5px;
  width: 100%;
}

.hmenu-login-act li:first-child {
  margin-left: 0;
}

.hmenu-login-act li:last-child {
  position: relative;
}

.hmenu-login-act li:last-child .count {
  position: absolute;
  top: -14px;
  right: -10px;
  background-color: #FF1B2C;
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}

.hmenu-login-act img {
  max-height: 22px;
  width: auto;
}

.hib strong {
  margin-right: 3px;
}
body.cart-items-open .scrolltop {
  display: none !important;
}
#cart-success-msg {
  position: fixed;
  display: inline-table;
  padding: 10px 15px;
  background: #00b894;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  line-height: 1;
  bottom: -34px;
  height: auto;
  top: 80px;
  right: 5px;
  z-index: 9999;
  transition: all 0.1s linear;
  visibility: visible;
}
#cart-success-msg a {
  color: #fff;
  position: relative;
  top: -5px;
  left: -7px;
}
#cart-success-msg.alert_sucess_sticky {
  bottom: -34px;
  height: auto;
  top: 69px;
  z-index: 50;
  transition: all 0.1s linear;
}
.hmenu-login-act .cart_qty_list.hmenu_sucess::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid #00b894;
  left: 0;
  bottom: -28px;
}
/* .htico_cart #cart-success-msg::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 18px solid #00b894;
    top: -12px;
    left: 4px;
} */

.hmenu-login-act #cart-success-msg p {
  margin: 0;
  width: calc(100% - 20px);
  float: right;
  line-height: 16px;
  font-size: 13px;
}
.hmenu-login-act #cart-success-msg a {
  color: #fff;
  position: relative;
  top: 2px;
}
.event-menu-listing {
  padding: 30px 0 0 0;
}
img.pop-bag-img {
  width: 80px;
  margin: 0 0 15px 0;
}
.outlet-product-parent {
  padding: 0 20px 20px 20px;
}
.tnk-delivery .remark_notesec {
  margin: 0px 0 0;
  padding: 0 7px;
}
.tnk-delivery .cart_price p {
  font-size: 17px;
  font-weight: 500;
}
.tnk-delivery .outlet-gst {padding: 5px 20px 15px;}
.order-delivery .head_right h4 {
  color: #fff;
  font-weight: 600;
}
section.restaurant-details {
  padding: 30px 0 20px 0;
  background: #fff;
}
#warning-max-orderpopup h3 {
  font-size: 32px;
  margin-bottom: 15px;
}
#warning-max-orderpopup p {
  color: #000;
}
.promotion-only-show .text-right span {
  padding-right: 20px;
}
.restpage-main-div
  .productlist-main-div
  .products-list-ulmain
  > li.no_restaurants {
  width: 100%;
  display: block;
  box-shadow: none;
  text-align: center;
  margin: 20px 0 10px 0;
  padding: 60px 0 40px;
}

.event-menu-slider {
  padding: 30px 30px;
  background: #fff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
  margin: 10px 0 40px 0;
  border-radius: 10px;
}
.ems-inner .slick-slide > div {
  padding: 0 8px;
}
.ems-inner .slick-slide .products-single-li {
  border: 1px solid #e4e4e4;
  height: 100%;
  cursor: pointer;
}
.ems-inner .slick-slide .products-single-li:hover {
  border: 1px solid #FF1B2C;
}

.ems-inner .slick-next,
.ems-inner .slick-prev {
  font-size: 0;
  border: 0;
  outline: 0;
  position: absolute;
  background: none;
  width: 26px;
  height: 32px;
  margin: 0;
  padding: 0;
  box-shadow: none;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
}

.ems-inner .slick-next {
  right: -22px;
}

.ems-inner .slick-prev {
  left: -22px;
}
.ems-inner .slick-next:hover,
.ems-inner .slick-prev:hover {
  background: #efefef;
}

.ems-inner .slick-prev::after,
.ems-inner .slick-next::after {
  content: "";
  width: 14px;
  height: 24px;
  margin: auto;
  display: block;
}
.ems-inner .slick-prev::after {
  background: url(../images/sliderarrow-left.png) no-repeat;
  background-size: contain;
}
.ems-inner .slick-next::after {
  background: url(../images/sliderarrow-right.png) no-repeat;
  background-size: contain;
}
.event-menu-slider-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 8px 15px 8px;
}
.event-menu-slider-header h3 {
  margin: 0;
  font-size: 24px;
}
.event-menu-slider-header a {
  border-bottom: 1px solid #000000;
  color: #000000;
  font-family: "Poppins";
  line-height: 1;
  display: block;
  text-transform: uppercase;
  font-size: 16px;
}
.event-menu-slider-header a:hover {
  border-bottom: 1px solid #FF1B2C;
  color: #FF1B2C;
}
.backto-rest {
  box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.1);
  background: #fff;
  margin-right: 6px;
  text-align: center;
}
.backto-rest a {
  display: block;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
  transition: 0.3s linear all;
}
.backto-rest a img {
  width: 28px;
}
.backto-rest a:hover {
  background: #d2d2d2;
}
.mobile-login {
  display: none;
}
.mobile-login ul {
  padding: 0;
  list-style: none;
  position: absolute;
  top: 110%;
  left: 0;
  background: #FF1B2C;
  border-radius: 6px;
  min-width: 150px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  z-index: 5;
}
.mobile-login ul.active {
  visibility: visible;
  opacity: 1;

  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
  position: relative;
}

.mobile-login ul li a {
  display: block;
  line-height: 1;
  padding: 10px 20px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}
.product-mobile-menu-nav {
  display: none;
}
a.controller-nav img {
  width: auto;
}
.ems-inner .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.no-product {
  padding: 70px 0 40px;
  text-align: center;
}
.no-product h2 {
  font-size: 32px;
  margin-bottom: 10px;
}
span.for-outline {
  border: 1px solid #FF1B2C;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}
.products-single-li.active span.for-outline,
.restpage-main-div
  .productlist-main-div
  .products-list-ulmain
  > li:hover
  span.for-outline {
  opacity: 1;
}
span.outlet-avilable {
  display: block;
  margin: 0 0 10px 0;
  background: #e60000;
  color: #fff;
  padding: 5px;
  line-height: 1.2;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}
.cdd-details-rhs span.outlet-avilable {
  font-size: 13px;
}
.no-res-pro {
  opacity: 0.7;
}
.rrbutton-merge {
  position: relative;
  display: flex;
  justify-content: center;
}
.resturant-retailbutton ul {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
}

.rrbutton-merge:before {
  background: #d3d3d3;
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.resturant-retailbutton ul li {
  display: inline-block;
}
.eventonly ul {
  box-shadow: none;
  background: none;
  border-radius: 0;
}
.eventonly ul li {
  width: auto;
}
.resturant-retailbutton ul li a {
  background: #ffffff;
  display: block;
  padding: 17px 22px;
  text-align: center;
  color: #000;
  line-height: 1;
  font-family: "Poppins";
  font-size: 18px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  transition: 0.1s linear all;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.04);
}
.eventonly ul li a {
  min-width: 180px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.resturant-retailbutton ul li:first-child a {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.resturant-retailbutton ul li:last-child a {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.eventonly {
  margin-bottom: 40px;
  margin-top: 20px;
}

.resturant-retailbutton ul li.active a,
.resturant-retailbutton ul li a:hover {
  background: #FF1B2C;
  color: #fff;
}

.serving-passionately-main {
  position: relative;
  overflow: hidden;
  padding: 30px;
  border-radius: 20px;
  background: #f2f2f2;
  margin: 40px 0;
}
.serving-passionately-right {
  text-align: center;
  padding: 0 0 0 40px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.serving-passionately-left,
.serving-passionately-right {
  width: 50%;
}

.serving-passionately-left img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.serving-passionately-right {
  text-align: center;
  padding: 0 0 0 40px;
}

.serving-passionately-right p {
  font-size: 16px;
  line-height: 23px;
}
.serving-passionately-right h3 {
  margin-bottom: 15px;
}

.serving-passionately-right a {
  font-size: 17px;
  margin: 5px auto 0;
}
.serving-passionately-left .slick-dots {
  padding: 0;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
}
.serving-passionately-left .slick-dots li {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}
.serving-passionately-left .slick-dots li button {
  padding: 0;
  margin: 0;
  text-indent: -9999px;
  background: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}
.serving-passionately-left .slick-dots li button:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
}
.serving-passionately-left .slick-dots li button::before {
  background: #FF1B2C;
  border: 2px solid #FF1B2C;
}
.serving-passionately-left .slick-dots li.slick-active button:before {
  background: #fff;
  border: 2px solid #FF1B2C;
}
.serving-passionately-main.fullw .serving-passionately-right {
  width: 100%;
  padding: 0 20px;
}

.shop_closed {
  color: #ff2c10;
  font-weight: bold;
}
.footer-top a:first-child {
  margin-right: 10px;
}

.yellow-popup {
  max-width: 820px;
  padding: 0;
  margin: 30px auto;
  position: relative;
  background: #f9ac43;
}

.yellow-bottom-btn {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  max-width: 440px;
  display: flex;
  justify-content: space-between;
}
.yellow-bottom-btn .button {
  background: #264427;
  width: 48%;
  padding: 16px 5px;
  font-size: 19px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.donation-info-amount ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.donation-info {
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}

.donation-info-amount ul li {
  background: #264427;
  color: #ffdb93;
  font-weight: 700;
  padding: 20px 5px;
  border: 3px solid #f9ac43;
  width: 33.33333%;
  font-size: 20px;
  cursor: pointer;
}

.donation-other-amount input[type="text"] {
  background: #ffdb93;
  border-color: #ffdb93;
  text-align: center;
  height: 58px;
  color: #2f4750;
  font-size: 22px;
}
.donation-amount-btn .button {
  background: #264427;
  width: 230px;
  padding: 16px 13px;
  font-size: 19px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
#donate-amount-popup {
  padding-top: 360px;
  padding-bottom: 70px;
  z-index: 2;
}
.donate-bg-full {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-position: top;
}
.donation-other-amount {
  padding: 30px 0 0 0;
  color: #2f4750;
  font-size: 22px;
}
.charity-total-cls {
  background: #f5f5f5;
  margin: 10px 0 0 0;
  padding: 4px 0 !important;
}
/* 
Group Order
*/
.group-link {
  margin: 20px 0 0 0;
}
.group-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 12px;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  border: 2px solid #FF1B2C;
  border-radius: 6px;
  line-height: 1;
  text-align: center;
  transition: 0.3s linear all;
}
.group-link a img {
  width: 28px;
  margin: 0 10px 0 0;
}
.group-link a:hover {
  background: #FF1B2C;
}
.sharegroup-popup .modal-dialog {
  max-width: 630px;
  width: 100%;
  color: #484848;
}
.sharegroup-popup .modal-content {
  border: 0;
  border-radius: 0;
}
.sharegroup-popup .modal-body {
  padding: 0px;
}
.sharegroup-body {
  padding: 40px;
}
.sharegroup-body-info h4 {
  text-transform: uppercase;
  font-size: 26px;
  color: #383838;
  font-size: 32px;
  margin-bottom: 15px;
}
.sharegroup-body-info p {
  font-family: "Poppins";
  font-size: 21px;
  line-height: 1.3;
}
.sharegroup-body-info img {
  margin-bottom: 20px;
}
.sharegroup-form-info {
  margin: 40px 0 0 0;
}
.sharegroup-form-info span {
  font-family: "Poppins";
  font-size: 18px;
  color: #a9a9a9;
  display: block;
  text-transform: uppercase;
}
.sharegroup-form-info .copy-share-text {
  text-align: center;
  max-width: 380px;
  border: 2px solid #d0d0d0;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  padding: 12px 10px;
}
.sharegroup-form-single-btn {
  display: flex;
  justify-content: center;
  max-width: 380px;
  margin: 0 auto;
}
.sharegroup-form-single-btn .button {
  width: 48%;
}
.sharegroup-form-multiple-btn {
  justify-content: space-between;
}
.sharegroup-form-multiple-btn a:first-child {
  background: #000000;
}
.sharegroup-order-info {
  padding: 20px 0 0 0;
  color: #000000;
  font-size: 16px;
}
.sharegroup-order-info span {
  font-family: "Poppins";
  font-size: 18px;
  text-transform: uppercase;
  border-bottom: 2px solid #dddddd;
  margin-bottom: 10px;
  display: inline-block;
}
.sharegroup-order-info ul {
  padding: 0;
  margin: 0;
}
.sharegroup-order-info ul li {
  font-weight: bold;
  margin-bottom: 5px;
  list-style: none;
}
.sharegroup-order-info p {
  font-weight: bold;
}
.group-order-parent {
  background: #020305;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
}
.group-order-blhs-top {
  position: relative;
  padding-left: 45px;
  color: #fff;
}
.group-order-blhs-top img {
  width: 28px;
  position: absolute;
  left: 0;
  top: 2px;
}
.group-order-blhs-top h5 {
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: 0 0 5px 0;
  font-weight: bold;
  font-size: 15px;
}
.group-order-blhs-top p span {
  margin-right: 20px;
}
.group-order-brhs .button {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  min-width: 155px;
}
.group-order-blhs-top p {
  margin-bottom: 0;
}
.group-order-brhs {
  line-height: 0;
}
.group-order-brhs .button.gob-white {
  margin-right: 10px;
  background: #fff;
  color: #000;
}
.group-order-brhs .button:hover {
  background: #e4e4e4;
  color: #000;
}
.only-group .hcart_scrollarea {
  max-height: calc((100% - 100px) - 158px);
}
.only-group.after-send-group .hcart_scrollarea {
  max-height: calc((100% - 100px) - 200px);
}
.only-group .hcart_tt {
  max-height: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  background: #fff;
}
.only-group .hcart_tt img {
  margin-bottom: 10px;
}
.only-group .hcart_tt h5 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  text-transform: inherit;
  font-size: 16px;
}
.only-group .cart_action {
  padding: 10px 0px 0 0px;
}
.sharegroup-form-info span.error {
  color: #d8000c;
  text-transform: none;
  font-size: 13px;
  display: inline-block;
  font-family: "Poppins", sans-serif;
}
.only-group .cartaction_bottom .btn {
  font-size: 19px;
  height: auto;
  padding: 8px 22px;
}
.only-group .cart-btm-text p,
.after-cart-btm-text p {
  font-weight: normal;
  text-align: center;
  margin: 0;
  font-size: 14px;
}
.only-group .cart-btm-text {
  padding: 10px 10px 0 10px;
}
.after-cart-btm-text {
  text-align: center;
  padding: 5px 0 0;
}

.after-cart-btm-text img {
  width: 42px;
}

.after-cart-btm-text h5 {
  text-transform: uppercase;
  margin: 0 0 5px 0;
  padding: 15px 0 0px;
  font-size: 22px;
  line-height: 1.3;
}
.sharegroup-form-info p.jquery-success-msg {
  margin: 0;
  padding: 10px;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
}
.cart-person-name {
  display: inline-block;
  background: #1c1c1c;
  color: #fff;
  line-height: 1;
  padding: 5px 10px;
  margin-bottom: 10px;
}

/*------------------Home Banner--------------------------*/

.inner-home-banner {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.hb-slider-section {
  width: 50%;
}

.hb-form-section {
  width: 50%;
  padding: 60px;
}

.hb-slider-section .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  background-color: #231f20;
  color: #fff;
  z-index: 9;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.hb-slider-section .slick-arrow:hover {
  background-color: #ff211f;
}

.hb-slider-section .slick-arrow.slick-next {
  right: 0;
}

.hb-form-section .banner-logo p {
  margin-top: 15px;
  font-weight: 500;
}

.hb-form-section .banner-logo a {
  display: inline-block;
}
.hb-form-section .banner-logo a img {
  max-width: 210px;
}
.delivery-wrap {
  display: flex;
  flex-flow: row wrap;
  margin-top: 25px;
}

.delivery-wrap .select-delivery {
  width: 30%;
  position: relative;
}

.delivery-wrap .search-outlet {
  width: 70%;
  padding-left: 10px;
}

.hb-form-section .syd_merge .react-datepicker-wrapper {
  width: 100%;
}

.hb-form-section .syd_merge input {
  margin-bottom: 0;
}

.date-btn-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}
.hb-form-section p {
  margin: 10px 0 0 0;
}
.hb-form-section .time-status {
  text-align: center;
  margin: 50px 0 15px;
}

.hb-form-section .time-status p {
  margin-bottom: 5px;
}

.hb-form-section .time-status h6 {
  margin: 0;
  text-transform: inherit;
  text-align: left;
}

.date-btn-wrap > div:not(.btn_sec) {
  width: 70%;
}

.date-btn-wrap > div.btn_sec {
  width: 30%;
}

.btn_sec input {
  margin: 0;
  height: 48px;
  margin-left: 10px;
  background-color: #ff211f;
  width: calc(100% - 15px);
  text-transform: inherit;
}

.search-outlet .focus-out input {
  padding-left: 40px;
  margin-bottom: 0;
}

.select-delivery > div {
  height: 48px;
  border: 1px solid #e4e4e4;
  background-color: #fff;
  padding: 10px;
  padding-left: 45px;
  padding-right: 17px;
  position: relative;
  line-height: 1.8;
  cursor: pointer;
}

.search-outlet .focus-out img,
.select-delivery > div img {
  position: absolute;
  top: 50%;
  width: 25px;
  left: 10px;
  transform: translateY(-50%);
}

.search-outlet .focus-out img,
.select-delivery > div i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
}

.search-outlet .focus-out i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.select-delivery ul {
  background-color: #fff;
  margin: 0;
  border: 1px solid #e4e4e4;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 31;
  padding: 0;
}

.select-delivery ul li {
  list-style-type: none;
  margin-bottom: 0px;
  padding: 10px;
  border-bottom: 1px solid #e4e4e4;
}

.select-delivery ul li a {
  color: #000;
  padding-left: 35px;
  position: relative;
  width: 100%;
  display: inline-block;
}

.select-delivery ul li:last-child {
  margin-bottom: 0;
}

.select-delivery ul li a img {
  width: 22px;
  margin-right: 3px;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.search-outlet .focus-out ul li {
  margin-bottom: 0px;
  list-style-type: none;
  text-align: center;
}

.search-outlet .focus-out ul li a {
  color: #fff;
  font-size: 12px;
}
.search-outlet .focus-out li a:hover {
  background-color: #f7f4e8;
}


.search-outlet .focus-out ul li:last-child {
  margin-bottom: 0;
}

.search-outlet .focus-out > div {
  position: relative;
}

.search-outlet .focus-out > div.success-delivery-addr {
  /*
    padding: 10px;
    background-color: #fff;
    margin: 0;
    border: 1px solid #e4e4e4; 
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    height: auto;
*/
  /*
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
*/
  /*
    font-size: 13px;
    text-align: center;
*/
  padding: 10px;
  margin: 0;
  background-color: #231f20;
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 30;
}

.search-outlet .focus-out > div img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 8;
}

.search-outlet .focus-out .success-delivery-addr i {
  position: static;
  color: #00c46a;
  transform: none;
  margin-right: 5px;
}

.syd_merge .form-group222 {
  position: relative;
  z-index: 9;
}
.syd_merge .form-group222 input[type="text"] {
  padding: 12px 12px;
  cursor: pointer;
}
.inner-home-banner .syd_merge .form-group222 i {
  top: 50%;
}
.syd_merge .form-group222 i {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.search-outlet ul {
      margin: 0;
    padding: 0;
    list-style: none;
    /*background: #fff;*/
    max-height: 160px;
    overflow-y: auto;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
  padding-left:0 ;
}

.search-outlet ul li {
  padding: 10px;
  background-color: #fff;
  margin: 0;
  border: 1px solid #e4e4e4;
  position: relative;
  left: 0;
  width: 100%;
  z-index: 99;
  height: auto;
  text-align: center;
}

.search-outlet ul li p {
  color: #000;
  margin-bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 14px;
}

.search-outlet ul li.no-outlet-found a {
  color: #000 !important;
}

.search-outlet ul li p > span:first-child {
  font-weight: bold;
}

/*------------------Outlet--------------------------*/

.product-menu-listing.home-bg {
  background-color: #f7f6e9;
}

.productlist-main-div .products-list-ulmain.outlet-wrapper {
  padding: 20px 0 0;
  margin: 0 -5px;
}

.productlist-main-div .products-list-ulmain > li.outlet-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: calc(50% - 10px);
  margin: 5px;
  justify-content: center;
  box-shadow: none;
  transition: all 0.2s linear;
}

.productlist-main-div .products-list-ulmain > li.outlet-list:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.2s linear;
}

.productlist-main-div .products-list-ulmain > li.outlet-list.ol-swap {
  flex-flow: row-reverse nowrap;
}

.productlist-main-div .products-list-ulmain > li.outlet-list > div {
  /* padding: 30px; */
  padding: 22px;
  width: 100%;
  min-width: 50%;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.productlist-main-div
  .products-list-ulmain
  > li.outlet-list
  > div.outlet-logo
  h3 {
  margin: 15px 0 0;
  font-size: 22px;
  max-width: 100%;
}

.productlist-main-div
  .products-list-ulmain
  > li.outlet-list
  > div.outlet-logo
  p {
  margin: 5px 0 0;
  font-size: 14px;
}

.outlet-logo img {
  max-height: 70px;
}

.outlet-dish img {
  max-height: 140px;
}

/*------------------Special Promotion--------------------------*/

.special-promotion {
  width: 100%;
  padding: 40px 0;
  margin: 0 auto;
}

.special-promotion .header-section {
  margin-bottom: 10px;
}
.productlist-main-div .header-section {
  margin-top: 20px;
}
.special-promotion .header-section h2,
.productlist-main-div .header-section h2 {
  margin: 0px;
  text-align: left;
  font-size: 25px;
  color: #282828;
}

.special-promotion-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;
}

.special-promotion-wrapper div.kloud-sp-cols-1 {
  width: calc(50% - 20px);
  margin: 10px;
}
.special-promotion-wrapper .slick-slider {
  width: 100%;
}
.special-promotion-wrapper .slick-track .slick-slide {
  padding: 10px;
}
.special-promotion-wrapper .slick-slider .sp-cols-2-caption > div {
  display: flex;
  flex-direction: column-reverse;
}
.special-promotion-wrapper .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #231f20;
  color: #fff;
  z-index: 9;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}
.special-promotion-wrapper .slick-arrow:hover {
  background-color: #ff211f;
}
.special-promotion-wrapper .slick-arrow.slick-prev {
  margin-left: 10px;
}
.special-promotion-wrapper .slick-arrow.slick-next {
  right: 10px;
}
.kloud-sp-cols-1 > div,
.kloud-sp-cols-2 > div {
  position: relative;
}

.kloud-sp-cols-1 > div:before,
.kloud-sp-cols-2 > div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.kloud-sp-cols-1 > div .sp-cols-1-caption {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
  padding: 0 30px;
}

.sp-cols-1-caption h4 {
  color: #fff;
  margin: 0 0 15px;
}

.sp-cols-1-caption p {
  color: #fff;
  margin: -10px 0 25px;
}

.sp-cols-1-caption a.kloud-sp-button {
  background-color: #fff;
  color: #080808;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  min-width: 140px;
  display: inline-block;
  transition: all 0.2s linear;
}

.sp-cols-1-caption a.kloud-sp-button:hover {
  background-color: #080808;
  color: #fff;
  transition: all 0.2s linear;
}

.special-promotion-wrapper > div.kloud-sp-cols-2,
.special-promotion-wrapper .kloud-sp-cols-2 {
  display: flex;
  flex-flow: row wrap;
  width: calc(25% - 20px);
}

.kloud-sp-cols-2 > div .sp-cols-2-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.kloud-sp-cols-2 > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sp-cols-2-caption h4 {
  color: #fff;
  font-size: 16px;
  max-width: 60%;
  margin: 0 auto 15px;
  text-align: center;
  font-weight: 400;
}

.sp-cols-2-caption.txt-left h4 {
  text-align: left;
  margin: 0 0 15px;
}

.sp-cols-2-caption div {
  text-align: right;
}

.sp-cols-2-caption a {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  position: relative;
}

.sp-cols-2-caption a:before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #fff;
  height: 1px;
  width: 85%;
  transition: all 0.2s linear;
}

.sp-cols-2-caption a:hover:before {
  width: 0;
  transition: all 0.2s linear;
}
.restaurant-main div {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  color: #fff;
}
.restaurant-main div .breadcrumb-sec {
  position: relative;
  flex: 1 50%;
  margin: 0;
  width: 100%;
  min-width: 150px;
  height: auto;
  max-width: 100%;
}
.restaurant-main div .breadcrumb-sec ul {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
}
.restaurant-main div .breadcrumb-sec ul li a {
  color: #fff;
}
.restaurant-main div .breadcrumb-sec ul li span {
  color: #fff;
}
.restaurant-main div .breadcrumb-sec img {
  width: 100%;
  object-fit: cover;
}
.restaurant-main div .restaurant-info {
  position: relative;
  flex: 1 50%;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 150px;
  max-width: 100%;
  padding: 10px;
  background-color: #000;
}
.restaurant-main div .restaurant-info img {
  filter: brightness(0) invert(1);
  max-width: 150px;
  margin-bottom: 15px;
  max-height: 120px;
}
.restaurant-main div .restaurant-info .restaurant-info-lhs {
  position: relative;
  display: flex;
  flex-flow: column;
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.restaurant-main div .restaurant-info .restaurant-info-lhs .address p {
  margin-bottom: 0;
}

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06),
    0 20px 30px -10px rgba(15, 72, 179, 0.2);
}

.accordion-item--opened .accordion-item__icon {
  transform: rotate(180deg);
  filter: brightness(0) invert(1);
}
.accordion-item--opened .accordion-item__inner {
  max-height: 100rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height;
}
.accordion-item--opened .accordion-item__content {
  opacity: 1;
  background: #fff;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
}
.accordion-item__line {
  display: block;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e5e4e2;
  border: 2px solid #fff;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.accordion-item__title {
  font-size: 23px;
  margin: 0;
  font-weight: 600;
  color: #121212;
}
.accordion-item__icon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../images/right-arrow-large.png');
  opacity: 1;
}
.accordion-item--opened .accordion-item__line {
  background: #282828;
  border: 2px solid #282828;
}
.accordion-item--opened .accordion-item__title {
  color: #fff;
}
.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}
.accordion-list__item {
  padding: 2px 0;
}
.accordion-list__item .accordion-item {
    box-shadow: 0px 1px 9px #0000002b;
}
.accordion-item__content {
  opacity: 0;
  background: #fff;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;
  padding: 1.2rem;
}
.accordion-item__paragraph {
  margin: 0;
  font-size: 17px;
  color: #282828;
  font-weight: 400;
  line-height: 1.3;
}

.fluid-container {
  position: relative;
  background: url('../images/kitechpods-background.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.fluid-content {
  position: relative;
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}
.fluid-content h1 {
  color: #FFF9EA;
  margin: 0;
  padding: 50px 0 17px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
}
.fluid-content p {
  margin: 0;
  text-align: center;
  color: #FFF9EA;
  font-size: 20px;
}
.fluid-content .fluid-form {
  position: relative;
  padding: 30px 0 60px;
  max-width: 500px;
  margin: auto;
}
.fluid-content .fluid-radio {margin-top: 10px;}
.fluid-content .fluid-radio label.label-head {
  display: block;
  color: #FFF9EA;
  font-size: 15px;
}
.fluid-content .fluid-radio [type="radio"] {
  position: relative;
  display: inline-block;
  opacity: 1;
  width: 18.25px;
  height: 18.25px;
  margin-right: 10px;
}
.fluid-content .fluid-radio [type="radio"]:checked {
  position: relative;
  left: 0;
}
.fluid-content .fluid-radio label {
  display: flex;
  align-items: center;
  color: #FFF9EA;
  margin-top: 10px;
}
.fluid-content p.kk-join {text-align: left;}
.fluid-content .btn-button {text-align: left;}
.fluid-content .btn-button .btn-center {
  text-transform: inherit;
  font-size: 15px;
}
.fluidpods-container {
  position: relative;
  margin: 110px auto 0;
}
.fluidpods-container .fluidpods-head {
  width: 100%;
  text-align: center;
}
.fluidpods-container .fluidpods-head img {margin-bottom: 30px;}
.fluidpods-container .fluidpods-head h2 {
  font-size: 42px;
  font-weight: 400;
}
.fluidpods-container .fluidpods-head h2 span {color: #FF1B2B;font-weight: 600;}
.fluidpods-container .fluidpods-descrip {
  margin-top: 85px;
  text-align: center;
}
.fluidpods-container .fluidpods-descrip .txt-red {
  color: #FF1B2B;
  font-size: 18px;
}
.fluidpods-container .fluidpods-descrip h3 {
  font-size: 42px;
  font-weight: 600;
  color: #231f20;
}
.fluidpods-container .fluidpods-descrip p {font-size: 21px;color: #231f20;}
.fluoidpods-grid {
  position: relative;
  margin: 85px 0;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  text-align: center;
  flex-flow: row wrap;
}
.fluoidpods-grid .fluid-item {
  flex: 1 1 33.33%;
  max-width: 50%;
  min-width: 150px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.fluoidpods-grid .fluid-item .fluid-round {
  position: relative;
  width: 85px;
  height: 85px;
  background: #ebe6da;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fluoidpods-grid .fluid-item h5 {
  color: #231f20;
  font-size: 21px;
}
.fluoidpods-grid .fluid-item p {width: 70%;padding: 0 10px;}
.fluidpods-art {
  position: relative;
  background: #ebe6d9;
  width: 100%;
  text-align: center;
  padding: 160px 140px;
  margin-top: 40px;
}
.fluidpods-art p {
  font-size: 21px;
  color: #231f20;
  margin: 0;
}
.fluidpods-flex-table {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 50px;
}
.fluidpods-flex-table .fludipods-table-item {
  flex: 1 1 30.33%;
  max-width: 30.33%;
  width: 100%;
  padding: 20px;
  min-width: 150px;
  text-align: center;
}
.fluidpods-flex-table .fludipods-table-item.fludipods-table-center {
  flex: 1 1 20%;
  max-width: 20%;
}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table {
  position: relative;
  border: 2px solid #fff;
  background: #fff;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table:hover {
  position: relative;
  border: 2px solid #FF1B2B;
  background: #fff;
}
.fluidpods-flex-table .fludipods-table-item.fludipods-table-center .fluidpods-table {
  border: none;
  background: transparent;
}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-red{background: #fff;}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-red:hover{background: #fce6d8;}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table .fluidpods-head-txt {
  background: #f8f6e9;
  color: #000;
  font-size: 21px;
  font-weight: 700;
  padding: 15px 5px;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black:hover{
  background: #ebe6da;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table:hover .fluidpods-head-txt{
  background: #FF1B2B;
  color: #fff;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black:hover {
  border: 2px solid #00e192;
  /*background: #ebe6da;*/
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black {
  border: 2px solid #ffffff;
  /*background: #ebe6da;*/
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black .fluidpods-head-txt {
  background: #5d5d5d;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black:hover .fluidpods-head-txt {
  background: #282828;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black .fluidpods-head-txt img {max-width: 230px;}
.fluidpods-flex-table .fludipods-table-item .fludipods-table-red {
  color: #FF211F;
  font-size: 28px;
  font-weight: 700;
  padding: 15px 5px;
}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table .fluidpods-inner-table {margin: 15px 0 15px;}
.fluidpods-flex-table .fludipods-table-item .fluidpods-table .fluidpods-inner-table p {
  margin: 0;
  color: #231f20;
  font-size: 18px;
  padding: 9px 0;
  font-weight: 400;
}

.fluidpods-flex-table .fludipods-table-item .fluidpods-table.table-black .fluidpods-inner-table p {
  color: #231f20;
}
.fluidpods-flex-table .fludipods-table-item.fludipods-table-center .fluidpods-table .fluidpods-inner {margin: 15px 0 15px;}
.fluidpods-flex-table .fludipods-table-item.fludipods-table-center .fluidpods-table .fluidpods-inner p {
  margin: 0;
  color: #231f20;
  font-size: 18px;
  padding: 9px 0;
  font-weight: 700;
}
.fludipods-footer {
  position: relative;
  background: #ebe6d9;
  padding: 65px 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 110px;
}
.fludipods-footer .fludipods-footer-item {
  flex: 1 1 60%;
  max-width: 60%;
  min-width: 150px;
  padding: 15px;
}
.fludipods-footer .fludipods-form.fludipods-footer-item {
  flex: 1 1 40%;
  max-width: 40%;
  min-width: 150px;
}
.fludipods-footer .fludipods-form-txt p {
  font-size: 18px;
  color: #808083;
  margin: 0;
}
.fludipods-footer .fludipods-form-txt h5 {
  font-size: 40px;
  color: #ff1b2b;
  margin-top: 5px;
  font-weight: 700;
}
.fludipods-footer .fludipods-form .fludipods-inner-form {
  width: 90%;
  margin: auto;
}
.fludipods-footer .fludipods-form .fludipods-inner-form .btn-button {
  margin: 0;
}
.fludipods-footer .fludipods-form .fludipods-inner-form .btn-button .btn {
  text-transform: capitalize;
  font-size: 16px;
  padding: 12px 10%;
}
.fludipods-footer .fludipods-form .fludipods-inner-form .form-control {
  background: #fbf8ef;
}
.description-tick {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
}
.description-tick span {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 5px 5px;
  line-height: 1;
}
.description-tick span img {
  width: 15px;
  margin-right: 5px;
  vertical-align: baseline;
}

/*------------------Footer--------------------------*/

.kloud-container {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.kloud-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 15px;
}

.kloud-cols {
  width: 100%;
}

footer {
  background-color: #282828;
  color: #ffffff;
  padding: 50px 0;
}
footer.scrollFooter {
  position: sticky;
  bottom: 0;
  z-index: 999;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  opacity: 1;
}

.footer-inner {
  display: flex;
  flex-flow: row wrap;
}

.footer-left {
  width: 40%;
  padding-right: 15px;
  text-align: left;
}

.footer-right {
  width: 60%;
  text-align: right;
}

.footer-inner ul {
  padding: 0;
  margin: 0;
}

.footer-inner ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0 5px;
}
.footer-inner .footer-menu li {
  margin: 0 15px;
}

.footer-inner ul > li:first-child {
  margin-left: 0;
}

.footer-inner ul > li:last-child {
  margin-right: 0;
}

.footer-inner ul li a {
  display: block;
  color: #FFF1D8;
  font-weight: 400;
}
.footer-inner ul li a:first-letter {
  text-transform: uppercase;
}

.footer-addr p{
  color: #FFF1D8;
}

.footer-left .footer-logo {
  margin-bottom: 30px;
}

.footer-left li img {
  width: auto;
  height: 30px;
}

.footer-left p {
  margin-bottom: 10px;
  color: #FFF1D8;
}

.footer-inner ul.footer-menu {
  margin-bottom: 30px;
}

.footer-inner ul.footer-social {
  margin-bottom: 20px;
}

.footer-addr .mt-30 {
  margin-top: 30px;
  margin-bottom: 0;
}

.footer-addr .mt-30 a {
  color: #fff;
  text-decoration: underline;
}

.product-filter.product-mob-filter.product-filter-sec { display: block}
.slider-and-filter, .choose-and-filter{ width: 100%; }
.slider-and-filter{ position: sticky; top:65px; background: #fff;z-index: 91 }
.slider-and-filter .slick-slider{ padding: 0 20px; }
.slider-and-filter button{
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    width: 20px;
    font-size: 0;
    height: 100%;background: #231f20;
}
.slider-and-filter button.slick-prev{left:0}
.slider-and-filter button.slick-next{right:0}
.slider-and-filter button:after {
    font-family: FontAwesome;
    position: absolute;
    left: 6px;
    top: 50%;
    font-size: 24px;
    bottom: 0;
    transform: translateY(-50%);
    line-height: 0.8;
    content: "\f104";
}
.slider-and-filter button.slick-next:after{
      content: "\f105";
}

.choose-and-filter select{margin: 0}
.choose-and-filter .form-group{ margin: 0 0 30px 0 }
.choose-and-filter {margin: 20px 0 0 0; display: none}
.slider-and-filter{ display: none;}
.slider-and-filter .slick-track{ border-bottom: 4px solid #e4e4e4;  }
.choose-and-filter label {
    font-weight: bold;
    color: #000;
    display: block;
    margin: 0 0 10px 0;
    font-size: 16px;
}
.slider-and-filter .slick-track .slick-slide>div {
    width: 100%;
    padding: 0 1px; height: 100%;
}
.slider-and-filter .slick-track .slick-slide a {
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #333;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1.2;
    display: block;
    height: 100%;
    text-align: center;
}

.slider-and-filter .slick-track .slick-slide a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #000;
    height: 4px;
    -webkit-transition-property: left,right;
    transition-property: left,right;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.slider-and-filter .slick-track .slick-slide a.active:before {
    left: 0;
    right: 0;
}
.slider-and-filter .slick-track .slick-slide>div li {
    height: 100%;
}
.slider-and-filter .slick-track .slick-slide{ display: block !important; height: auto }

/***************** MODEL POPUP ARROW *****************/
#ProductDetailMdl .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    padding: 0;
    width: 25px;
    height: 50px;
    font-size: 0;
    background: #ff211f;
    border: 2px solid #ff211f;
    z-index: 1;
}

#ProductDetailMdl .slick-arrow.slick-prev{left: 0px}
#ProductDetailMdl .slick-arrow.slick-next{right: 0px;}
#ProductDetailMdl .slick-arrow:hover{ background: #ff211fcc; border: 2px solid #ff211f;}

#ProductDetailMdl .slick-arrow i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    line-height: 1;
    content: "";
    color: #fff;
}

.prodet_top .slick-slider .slick-list{
  width: 100%;
  height: 100%;
}

.prodet_top .slick-slider .slick-list .slick-track .slick-slide div{
  width: 100%;
  height: 100%;
}

.prodet_top .slick-slider .slick-list .slick-track .slick-slide{
  height: 100%;
}

.prodet_top .slick-slider .slick-list .slick-track{
  height: 100%;
}

#ProductDetailMdl .common-modal-head button{
  position: absolute;
}

.htico_cart > a .count{
  position: absolute;
  top: -14px;
  right: -10px;
  background-color: #FF1B2C;
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}
.product-price .price_disc {
	text-decoration: line-through;
    text-decoration-color: #00000054;
}